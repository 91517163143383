import React from 'react';
import { Heading } from '@ditioas/ui-typography';
import { constants } from '@ditioas/ui-variables';
import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div<{ $smallBottomMargin?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ $smallBottomMargin }) =>
    $smallBottomMargin ? '1rem' : '2rem'};
`;

export const TableWrapper = styled.div`
  overflow: auto;
`;

export const NoResultWrapper = styled.div`
  margin-left: 1rem;
`;

export const NoResultHeading = styled(Heading)`
  margin-bottom: 1rem;
`;

const containerCss = css`
  width: 100%;
  max-width: 54rem;
  margin: 0 auto;
`;

const onlyBottomPaddingCss = css`
  padding: 0 0 ${constants.pageMarginVertical} 0;
`;

const noPaddingCss = css`
  padding: 0;
`;

const smallTopPaddingCss = css`
  padding-top: 1rem;
`;

export const Container = styled.div`
  ${containerCss};
`;

export type PageWrapperProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLElement> & {
    isContainer?: boolean;
    allowScrolling?: boolean;
    noPadding?: boolean;
    onlyBottomPadding?: boolean;
    smallTopPadding?: boolean;
  }
>;

export const PageWrapper = styled(
  React.forwardRef<HTMLElement, PageWrapperProps>(
    (
      {
        isContainer,
        allowScrolling,
        onlyBottomPadding,
        noPadding,
        smallTopPadding,
        ...rest
      },
      ref
    ) => <main ref={ref} id="pageWrapper" {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  overflow: ${({ allowScrolling }) => (allowScrolling ? 'auto' : 'hidden')};

  padding: ${constants.pageMarginVertical} ${constants.pageMarginHorizontal};
  ${({ isContainer }) => isContainer && containerCss};
  ${({ noPadding }) => noPadding && noPaddingCss};
  ${({ onlyBottomPadding }) => onlyBottomPadding && onlyBottomPaddingCss};
  ${({ smallTopPadding }) => smallTopPadding && smallTopPaddingCss};
`;

export const lineClamp = (numLines: number, lineHeight: number) => css`
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: ${numLines};
  -webkit-box-orient: vertical;
  max-height: ${numLines * lineHeight}rem;
`;
