import React from 'react';
import { NavigationMenuButton } from '@ditioas/web-ui';
import twPlugin from '@ditioas/web-ui/plugin';
import { useSetAtom } from 'jotai';
import { Link, useLocation } from 'react-router-dom';
import useMedia from 'use-media';

import config from 'config/site.config';
import type { MenuContent } from 'model/Menu/types';

import { navMenuStateAtom } from '../Layout';
import getLinkIcon from './getLinkIcon';

const { legacyWebBaseUrl } = config;

interface PageLinkProps {
  page: MenuContent[number]['pages'][number];
}

const PageLink = ({ page: { name, link, title, isLegacy } }: PageLinkProps) => {
  const location = useLocation();
  const setMenuOpen = useSetAtom(navMenuStateAtom);
  const isWide = useMedia(
    {
      minWidth: parseInt(
        twPlugin.config.theme.extend.screens.sidebarBreakpoint,
        10
      ),
    },
    true
  );

  const [isSelected, setIsSelected] = React.useState(false);
  React.useEffect(() => {
    setIsSelected(location.pathname.includes(link));
  }, [link, location]);

  const icon = getLinkIcon(name);

  return isLegacy ? (
    <NavigationMenuButton active={false}>
      <a href={`${legacyWebBaseUrl}/${link}`}>
        {icon}
        {title}
      </a>
    </NavigationMenuButton>
  ) : (
    <NavigationMenuButton
      key={link}
      onClick={() => {
        if (!isWide) {
          setMenuOpen(false);
        }
      }}
      active={isSelected}
    >
      <Link to={link}>
        {icon}
        {title}
      </Link>
    </NavigationMenuButton>
  );
};

export default PageLink;
