import React, { useState } from 'react';
import { Catalog } from '@carbon/icons-react';
import { Dialog, DialogContent } from '@ditioas/ui-dialogs';
import { Skeleton } from '@ditioas/ui-loaders';
import type { CompanyOption, LanguageOption } from '@ditioas/web-ui';
import {
  Button,
  NavigationMenu,
  NavigationMenuSection,
  Tag,
  TopBar,
} from '@ditioas/web-ui';
import twPlugin from '@ditioas/web-ui/plugin';
import { atom, useAtom, useSetAtom } from 'jotai';
import mixpanel from 'mixpanel-browser';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import cn from 'src/cn';
import i18n from 'src/i18n';
import { useMedia } from 'use-media';

import config from 'config/site.config';
import {
  useGetLegacyDashboard,
  useGetMenuContent,
  useGetReleaseNotes,
} from 'data/menu';
import useCurrentUserAndSettings, {
  currentUserAndSettingsAtom,
  setUserLanguageAtom,
} from 'hooks/useCurrentUserAndSettings';
import useHandleError from 'hooks/useHandleError';
import useObjectSearchParams from 'hooks/useObjectSearchParams';
import useShowAlert from 'hooks/useShowAlert';
import { getUserManager } from 'libs/auth';
import { parseAndFormatDateTime } from 'libs/dateFunctions';
import { getFileUrl } from 'libs/urlFunctions';
import type { LocaleId } from 'model/locale';
import { findLocaleById, localeList } from 'model/locale';
import { useLayoutTitleContext } from 'providers/LayoutTitleProvider';
import { setCurrentUserLanguage } from 'services/language-service';
import { changeCompanyProfile } from 'services/user-service';

import LayoutContext from './LayoutContext/LayoutContext';
import PageLink from './PageLink/PageLink';

export interface LayoutURLParams {
  presentationMode: 'enabled';
}

export const navMenuStateAtom = atom(true);

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const { presentationMode } = useObjectSearchParams<LayoutURLParams>();
  const isInPresentationMode = presentationMode === 'enabled';

  const titleContext = useLayoutTitleContext();
  const heading = titleContext.title;

  const { t } = useTranslation();
  const { currentUser, oidcUser } = useCurrentUserAndSettings();
  const setUserLanguage = useSetAtom(setUserLanguageAtom);
  const setUserAtom = useSetAtom(currentUserAndSettingsAtom);
  const handleError = useHandleError();
  const { showSuccessMessage } = useShowAlert();

  const { menuItems, mutate } = useGetMenuContent();
  const { data: releaseNotes } = useGetReleaseNotes();
  const { data, isFetching } = useGetLegacyDashboard(
    !currentUser || (!currentUser.sysOp && !currentUser.isImpersonating)
  );
  const devInfo = data?.data;

  const mounted = React.useRef(false);
  React.useEffect(() => {
    if (mounted.current) {
      mutate();
    } else {
      mounted.current = true;
    }
  }, [i18n.language]);

  const [menuOpen, setMenuOpen] = useAtom(navMenuStateAtom);
  const [forcedCollapsible, setForcedCollapsible] = useState(false);
  const [isNotesDialogOpen, setIsNotesDialogOpen] = React.useState(false);

  const isWide = useMedia(
    {
      minWidth: parseInt(
        twPlugin.config.theme.extend.screens.sidebarBreakpoint,
        10
      ),
    },
    true
  );
  React.useEffect(() => {
    if (!isWide && menuOpen) {
      setMenuOpen(false);
    }
  }, [isWide]);

  const [language, setLanguage] = useState(
    findLocaleById(currentUser?.languageId)
  );

  const companies: CompanyOption[] = currentUser?.userCompanyRoles?.map(
    (company) => ({
      companyId: company.companyId,
      companyName: company.companyName,
      connectedCompanyName: company.connectedCompanyName,
      connectedProfileId: company.companyProfileId,
    })
  );

  const handleOnLanguageChange = async (
    newLanguage: LanguageOption
  ): Promise<void> => {
    try {
      const { userId } = currentUser;
      await setCurrentUserLanguage(userId, newLanguage.id as LocaleId);
      setUserLanguage(newLanguage.id);
      setLanguage(findLocaleById(newLanguage.id as LocaleId));
      showSuccessMessage({
        message: t('General.LanguageSettingsUpdated_SuccessMessage'),
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleOnCompanyChange = async (
    company: CompanyOption
  ): Promise<void> => {
    try {
      const response = await changeCompanyProfile(company.connectedProfileId);
      if (response) {
        showSuccessMessage({
          message: t('General.CompanyChanged'),
        });
        const userManager = getUserManager();
        setUserAtom({});
        if (config.mixpanelToken) {
          mixpanel.reset();
        }
        userManager.removeUser();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const contextValue = React.useMemo(
    () => ({ forcedCollapsible, setForcedCollapsible }),
    [forcedCollapsible, setForcedCollapsible]
  );

  React.useEffect(() => {
    if (forcedCollapsible) {
      setMenuOpen(false);
    }
  }, [forcedCollapsible]);

  return (
    <LayoutContext.Provider value={contextValue}>
      <div className="relative flex h-screen w-screen overflow-hidden print:h-fit print:overflow-visible">
        {!isInPresentationMode && (
          <NavigationMenu
            isOpen={menuOpen}
            onClose={() => setMenuOpen(false)}
            logoVariant="core"
            logoHref={config.legacyWebBaseUrl}
          >
            {menuItems?.map((section) => (
              <NavigationMenuSection
                key={section.sectionName}
                heading={section.sectionName}
              >
                {section.pages.map((page) => (
                  <PageLink key={page.name} page={page} />
                ))}
              </NavigationMenuSection>
            )) || (
              <div className="flex flex-col gap-1 px-4">
                <div className="h-[31px] w-[191px] pt-1">
                  <Skeleton width={80} height={15} />
                </div>
                {Array(8)
                  .fill(null)
                  .map((_, index) => (
                    <Skeleton
                      // eslint-disable-next-line react/no-array-index-key
                      key={`nav-skeleton-${index}`}
                      width={191}
                      height={40}
                    />
                  ))}
              </div>
            )}
          </NavigationMenu>
        )}

        <div className="flex h-screen w-full flex-col overflow-hidden print:h-fit print:overflow-visible">
          {!isInPresentationMode && (
            <TopBar
              title={heading}
              isMenuOpen={menuOpen}
              onToggleMenu={setMenuOpen}
              userAvatarSrc={
                currentUser && getFileUrl(currentUser.avatarImageId, 128)
              }
              userName={currentUser?.name}
              company={{
                companyId: currentUser ? currentUser.companyId : '',
                companyName: currentUser ? currentUser.companyName : '',
                connectedCompanyName: currentUser
                  ? currentUser.connectedCompanyName
                  : '',
                connectedProfileId: currentUser
                  ? currentUser.userCompanyProfileId
                  : '',
              }}
              onCompanyChange={handleOnCompanyChange}
              companyOptions={companies}
              language={language}
              onLanguageChange={handleOnLanguageChange}
              languageOptions={localeList}
              logoutButtonText={
                currentUser.isImpersonating
                  ? t('Navigation.ToSysop')
                  : t('Navigation.Logout')
              }
              onLogout={() => {
                if (currentUser.isImpersonating) {
                  window.location.href = `${config.legacyWebBaseUrl}#/ngQrCodeRegister`;
                } else {
                  const userManager = getUserManager();
                  setUserAtom({});
                  if (config.mixpanelToken) {
                    mixpanel.reset();
                  }
                  userManager.signoutRedirect({
                    id_token_hint: oidcUser.id_token,
                  });
                  userManager.removeUser();
                }
              }}
            >
              <div className="flex flex-shrink-[2] flex-grow items-center justify-center gap-4 px-4">
                <div
                  className={cn(
                    'mx-auto flex flex-shrink-[3] items-center gap-2',
                    !currentUser.isImpersonating && 'max-w-52 flex-[1_3_13rem]'
                  )}
                >
                  {(currentUser?.sysOp || currentUser?.isImpersonating) && (
                    <Tag
                      color={
                        import.meta.env.VITE_NODE_ENV === 'production'
                          ? 'orange'
                          : 'azure'
                      }
                      coloredText
                      text={
                        import.meta.env.VITE_NODE_ENV === 'production'
                          ? 'Production'
                          : 'Test'
                      }
                      className="w-full flex-shrink-[3] justify-center"
                    />
                  )}
                  {currentUser?.isImpersonating && (
                    <Tag
                      color="green"
                      coloredText
                      text={`${t('SysOp.Impersonating')}: ${
                        currentUser.companyId
                      } - ${currentUser.companyName}`}
                      textClassName="text-center line-clamp-2"
                    />
                  )}
                </div>

                <div className="flex items-center gap-4">
                  {devInfo?.systemInfo &&
                    (isFetching ? (
                      <Skeleton width={130} height={16} />
                    ) : (
                      <Button asChild variant="link" size="sm">
                        <a
                          href={`${config.legacyWebBaseUrl}/#/AdminUserOnline`}
                        >
                          {`${t('Title.Users')}: ${
                            devInfo.systemInfo.totalUserCount
                          } ${t('Title.Sessions')}: ${
                            devInfo.systemInfo.sessionsToday
                          }`}
                        </a>
                      </Button>
                    ))}
                  <Button
                    variant="clear"
                    size="sm"
                    className="font-normal"
                    onClick={() => setIsNotesDialogOpen(true)}
                  >
                    <Catalog className="flex-shrink-0" />
                    {t('General.ReleaseNotes')}
                  </Button>
                </div>
              </div>

              {releaseNotes && (
                <Dialog
                  open={isNotesDialogOpen}
                  onClose={() => setIsNotesDialogOpen(false)}
                  messages={{
                    closeButton: t('General.Close'),
                  }}
                  aria-label="release-notes-dialog"
                >
                  <DialogContent className="px-6 pb-6 pt-4">
                    <h3 className="heading-s mb-4 leading-6">
                      {t('General.ReleaseNotes')}
                    </h3>
                    <div className="flex max-h-[50vh] flex-col gap-2 overflow-auto">
                      {releaseNotes.map((note) => (
                        <div key={note.id} className="flex items-center gap-4">
                          <p className="w-10 shrink-0 label-xs">
                            {note.versionString}
                          </p>
                          <p className="w-28 shrink-0 body-xxs">
                            {parseAndFormatDateTime({
                              value: note.releaseDateTime,
                              format: 'P - HH:mm',
                              parseAsLocalDate: true,
                            })}
                          </p>
                          <p className="grow bg-sand-300 px-3 py-2.5 body-xs">
                            {note.text}
                          </p>
                        </div>
                      ))}
                    </div>
                  </DialogContent>
                </Dialog>
              )}
            </TopBar>
          )}

          <Helmet>
            <html lang={language.id} />
            <title>{heading} | Ditio</title>
          </Helmet>
          {children}
        </div>
      </div>
    </LayoutContext.Provider>
  );
};

export default Layout;
