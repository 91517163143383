import { Range } from 'react-date-range';

import { AutocompleteOption } from 'components/ReactHookForm/Autocomplete/Autocomplete';

export interface Statistics {
  companies?: AutocompleteOption[];
  projects?: AutocompleteOption[];
  userIds?: string[];
  dateRange: Range;
  granularity: string;
  metrics: string[];
}

export interface StatisticsResponse {
  [key: string]: StatsData[];
}

export interface StatsData {
  label: string;
  value: string;
}

export interface DashboardProps {
  isPublic?: boolean;
}
export interface Project {
  name: string;
  id: string;
  companyId: string;
}

export const Granularity = {
  Day: 0,
  Week: 1,
  Month: 2,
  Quarter: 3,
  Annual: 5,
};

export interface FilterProps {
  filters: Statistics;
  setFilters: React.Dispatch<React.SetStateAction<Statistics>>;
}
