import { createSelector } from 'reselect';

import * as types from './machineTypes';
import { getMachineState } from '../selectors';
import { User } from '../../model/users';
import { Machine } from '../../model/machines';

const initialState = {
  allMachines: [],
  allLoaders: [],
  allDumpers: [],
};

const machineReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_MACHINES_SUCCESS: {
      let l = [];
      if (action.data) {
        l = action.data.map(i => Machine.fromObject(i));
      }
      return { ...state, allMachines: l };
    }

    case types.GET_ALL_LOADERS_SUCCESS: {
      let l = [];
      if (action.data) {
        l = action.data.map(i => Machine.fromObject(i));
      }
      return { ...state, allLoaders: l };
    }

    case types.GET_ALL_DUMPERS_SUCCESS: {
      let l = [];
      if (action.data) {
        l = action.data.map(i => Machine.fromObject(i));
      }
      return { ...state, allDumpers: l };
    }

    default:
      return state;
  }
};

export default machineReducer;

const mSelector = createSelector(
  getMachineState,
  state => (state ? state.allMachines : [])
);
const lSelector = createSelector(
  getMachineState,
  state => (state ? state.allLoaders : [])
);
const dSelector = createSelector(
  getMachineState,
  state => (state ? state.allDumpers : [])
);

export const getAllMachines = createSelector(
  mSelector,
  list => list
);
export const getAllLoaders = createSelector(
  lSelector,
  list => list
);
export const getAllDumpers = createSelector(
  dSelector,
  list => list
);
