import { combineReducers } from 'redux';

import locationReducer from './location/locationReducer';
import machineReducer from './machine/machineReducer';
import mapLayerReducer from './map-layer/mapLayerReducer';
import massTypeReducer from './mass-type/massTypeReducer';
import notificationReducer from './notification/notificationReducer';
import projectReducer from './project/projectReducer';
import reportsReducer from './reports/reportsReducer';
import tripLogReducer from './trip-log/tripLogReducer';

export default combineReducers({
  massType: massTypeReducer,
  location: locationReducer,
  reports: reportsReducer,
  project: projectReducer,
  machine: machineReducer,
  tripLog: tripLogReducer,
  notification: notificationReducer,
  mapLayer: mapLayerReducer,
});
