import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';

import { getUserManager } from '../../libs/auth';
import { LocalStorageManager } from '../../libs/utils';

const userManager = getUserManager();

const Callback = () => {
  const navigate = useNavigate();

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={(user) => {
        const redirectTo = LocalStorageManager.getRedirectUrlOnReturn();
        LocalStorageManager.removeRedirectUrlOnReturn();
        let url = '/';

        // check for enabling of silent renew
        if (user?.profile?.idp?.includes('aad')) {
          localStorage.setItem('aad', true);
        } else {
          localStorage.setItem('aad', false);
        }

        if (redirectTo != null) {
          url = redirectTo;
        }
        navigate(url, { replace: true });
      }}
      errorCallback={() => navigate('/', { replace: true })}
    >
      <div>Redirecting...</div>
    </CallbackComponent>
  );
};

export default Callback;
