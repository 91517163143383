import React from 'react';
import * as Sentry from '@sentry/react';

import ErrorScreen from './error-screen';

interface ErrorBoundaryProps {
  heading: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
  reportSent: boolean;
}

class ErrorBoundary extends React.Component<
  React.PropsWithRef<React.PropsWithChildren<ErrorBoundaryProps>>,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      reportSent: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      hasError: true,
    });

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });

    setTimeout(() => {
      this.setState({ reportSent: true });
    }, 1000);
  }

  render() {
    const { heading } = this.props;

    if (this.state.hasError) {
      return (
        <ErrorScreen heading={heading} reportSent={this.state.reportSent} />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
