import * as React from 'react';
import { Autocomplete, AutocompleteProps } from '@ditioas/ui-selectors';

import useCurrentUserAndSettings from 'hooks/useCurrentUserAndSettings';
import { Company } from 'model/Company/types';
import {
  searchForDitioCompanies,
  useGetCompanyRelations,
} from 'services/company-service';

const formatCompanyOptions = (companies: Company[]) =>
  companies?.map((company) => ({
    ...company,
    id: company.companyId ? company.companyId : company.organizationNumber,
    name: company.companyId
      ? `${company.companyId}: ${company.companyName}`
      : `${company.roaringIntegrationId}: ${company.companyName}`,
  }));

const getCompanyOptions = async (searchString: string) => {
  const companies = await searchForDitioCompanies(searchString);
  const options = formatCompanyOptions(companies);
  return options;
};

const CompanyAutocomplete = ({
  error,
  helperText,
  ...rest
}: Omit<AutocompleteProps, 'options'>) => {
  const { currentUser } = useCurrentUserAndSettings();
  const { data } = useGetCompanyRelations(
    currentUser?.isAdmin ? currentUser?.companyId : null
  );

  return (
    <Autocomplete
      options={
        currentUser?.sysOp
          ? getCompanyOptions
          : (currentUser?.isAdmin &&
              formatCompanyOptions(data?.projectCompanies)) ||
            []
      }
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      filterOptions={(options) => options}
      error={!!error}
      helperText={error ? helperText : ''}
      {...rest}
    />
  );
};

export default CompanyAutocomplete;
