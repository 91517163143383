import React, { lazy, Suspense, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAtomValue, useSetAtom } from 'jotai';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';

import FullscreenLoading from 'components/fullscreen-loading';
import Layout from 'components/layout/Layout';
import StatusCodeErrorHandler from 'components/status-code-errors/status-code-error-handler';
import config from 'config/site.config';
import useCurrentUserAndSettings, {
  currentUserPlaceholderAtom,
  fetchCurrentUserAtom,
  revalidateUserAtom,
} from 'hooks/useCurrentUserAndSettings';
import { enableSilentAuth, getUserManager } from 'libs/auth';
import { LocalStorageManager } from 'libs/utils';
import LayoutTitleProvider from 'providers/LayoutTitleProvider';
import { getIsLoadingUser, getOidcUser } from 'store/oidcSelectors';

const CompanyHandling = lazy(() => import('../CompanyHandling'));

const Masstransport = lazy(() => import('../mass-transport/mass-transport'));
const ResourcesAndMachines = lazy(() => import('../ResourcesAndMachines'));
const ResourcePlanning = lazy(() => import('../ResourcePlanning'));
const UserHandling = lazy(() => import('../user-handling/user-handling'));
const OnlineUsers = lazy(() => import('../OnlineUsers/OnlineUsers'));
const EquipmentTraining = lazy(() => import('../EquipmentTraining'));
const InfoCenterAdmin = lazy(() =>
  import('../InfoCenterAdmin/InfoCenterAdmin')
);

const Alerts = lazy(() => import('../Alerts'));
const CertificatesOverview = lazy(() => import('../CertificatesOverview'));
const SysOp = lazy(() => import('../SysOp'));
const NewImageMap = lazy(() => import('../ImageMap'));
const NotFoundPage = lazy(() => import('../not-found-page'));
const Checklists = lazy(() => import('../Checklists'));
const OverviewDashboard = lazy(() => import('../OverviewDashboard'));
const Health = lazy(() => import('../Health'));

const { legacyWebBaseUrl, modules } = config;

const LoggedInRoot = () => {
  const location = useLocation();
  const oidcUser = useSelector(getOidcUser);
  const isLoadingOidcUser = useSelector(getIsLoadingUser);
  const userManager = getUserManager();
  const { currentUser, currentUserSettings } = useCurrentUserAndSettings();
  // This line prevents current user from being fetched twice
  useAtomValue(oidcUser ? fetchCurrentUserAtom : currentUserPlaceholderAtom);
  const revalidateUser = useSetAtom(revalidateUserAtom);

  const currentUserLoaded = currentUser && currentUserSettings;

  const getReturnUrl = () => {
    let returnTo = null;
    if (location && location.pathname) {
      const originalPath = location.pathname;
      if (originalPath !== '/' && originalPath !== '') {
        returnTo = location.pathname + (location.search ? location.search : '');
      }
    }

    return returnTo;
  };

  useEffect(() => {
    if (!isLoadingOidcUser) {
      if (!oidcUser) {
        LocalStorageManager.setRedirectUrlOnReturn(getReturnUrl());
        if (document.referrer.includes(legacyWebBaseUrl) && enableSilentAuth) {
          userManager
            .signinSilent()
            .then(() => {
              revalidateUser();
            })
            .catch(() => {
              userManager.signinRedirect();
            });
        } else {
          userManager.signinRedirect();
        }
      } else if (!currentUser) {
        revalidateUser();
      }
    }
  }, [isLoadingOidcUser, oidcUser]);

  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({
        id: currentUser.userCompanyProfileId,
        username: currentUser.name,
      });

      if (config.mixpanelToken) {
        mixpanel.identify(currentUser.userCompanyProfileId);
      }
    }
  }, [currentUser]);

  const loadingView = <FullscreenLoading />;

  return !currentUserLoaded ? (
    loadingView
  ) : (
    <LayoutTitleProvider>
      <Layout>
        <Suspense fallback={<div>{loadingView}</div>}>
          <StatusCodeErrorHandler>
            <Routes>
              <Route
                path={`${modules.alerts.baseUrl}/*`}
                element={<Alerts />}
              />
              <Route
                path={`${modules.certificatesOverview.baseUrl}/*`}
                element={<CertificatesOverview />}
              />
              <Route
                path={`${modules.userHandling.baseUrl}/*`}
                element={<UserHandling />}
              />
              <Route
                path={`${modules.onlineUsers.baseUrl}/:filter`}
                element={<OnlineUsers />}
              />
              <Route
                path={`${modules.masstransport.baseUrl}/*`}
                element={<Masstransport />}
              />
              <Route
                path={`${modules.companyHandling.baseUrl}/*`}
                element={<CompanyHandling />}
              />
              <Route
                path={`${modules.equipmentTraining.baseUrl}/*`}
                element={<EquipmentTraining />}
              />
              <Route
                path={`${modules.checklists.baseUrl}/*`}
                element={<Checklists />}
              />
              <Route
                path={`${modules.infoCenterAdmin.baseUrl}/*`}
                element={<InfoCenterAdmin />}
              />
              <Route
                path={`${modules.resourcesAndMachines.baseUrl}/*`}
                element={<ResourcesAndMachines />}
              />
              <Route
                path={`${modules.resourcePlanning.baseUrl}/*`}
                element={<ResourcePlanning />}
              />
              <Route
                path={`${modules.imageMap.baseUrl}/*`}
                element={<NewImageMap />}
              />
              <Route path={`${modules.sysOp.baseUrl}/*`} element={<SysOp />} />
              <Route
                path={`${modules.sysOp.dashboard}/*`}
                element={<OverviewDashboard />}
              />
              <Route
                path={`${modules.health.baseUrl}/*`}
                element={<Health />}
              />
              <Route path="/" element={<NotFoundPage />} />
            </Routes>
          </StatusCodeErrorHandler>
        </Suspense>
      </Layout>
    </LayoutTitleProvider>
  );
};

export default LoggedInRoot;
