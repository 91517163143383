import type { FolderOrItem } from 'components/FolderStructure/types';
import { localeCompare } from 'libs/sortFunctions';

import type { ProjectTask } from './types';

export const MAX_TREE_DEPTH = 10;

export const getSelectedTasks = (
  tasksTree: ProjectTask[],
  maxDepth = MAX_TREE_DEPTH,
  depth = 1
): ProjectTask[] =>
  tasksTree.reduce((selected, task) => {
    const hasChildren = task.childNodes && task.childNodes.length;

    if (hasChildren && depth <= maxDepth) {
      selected.push(...getSelectedTasks(task.childNodes, maxDepth, depth + 1));
    } else if (task.isSelected) {
      selected.push(task);
    }

    return selected;
  }, []);

export const getLocalSelectedTaskIds = (
  tasksTree: ProjectTask[],
  selectedTasks: string[],
  maxDepth = MAX_TREE_DEPTH,
  depth = 1
): string[] =>
  tasksTree.reduce((selected, task) => {
    const hasChildren = task.childNodes && task.childNodes.length;

    if (selectedTasks.includes(task.taskId)) {
      selected.push(task.taskId);
    }

    if (hasChildren && depth <= maxDepth) {
      selected.push(
        ...getLocalSelectedTaskIds(
          task.childNodes,
          selectedTasks,
          maxDepth,
          depth + 1
        )
      );
    }

    return selected;
  }, []);

export const mapSelectedTasks = (
  tasksTree: ProjectTask[],
  selectedTaskIds: string[],
  maxDepth = MAX_TREE_DEPTH,
  depth = 1
): ProjectTask[] =>
  tasksTree.map((task) => {
    const hasChildren = task.childNodes && task.childNodes.length;

    if (hasChildren && depth <= maxDepth) {
      const mappedChildNodes = mapSelectedTasks(
        task.childNodes,
        selectedTaskIds,
        maxDepth,
        depth + 1
      );

      return {
        ...task,
        isSelected: mappedChildNodes.every((childNode) => childNode.isSelected),
        childNodes: mappedChildNodes,
      };
    }

    return selectedTaskIds.includes(task.taskId)
      ? { ...task, isSelected: true }
      : { ...task, isSelected: false };
  });

export const sortByFolderAndName = (tasks: ProjectTask[]) =>
  tasks
    .sort((a, b) =>
      localeCompare(
        a.taskNumberAndName || a.taskName,
        b.taskNumberAndName || b.taskName
      )
    )
    .sort((a, b) =>
      // eslint-disable-next-line no-nested-ternary
      a.isFolder === b.isFolder ? 0 : a.isFolder ? -1 : 1
    );

export const checkForNestedSearch = (task: ProjectTask, query?: string) => {
  let result = false;
  if (!query) return false;
  const lowercasedQuery = query.toLowerCase();

  if (task.taskNumberAndName.toLowerCase().includes(query.toLowerCase())) {
    result = true;
  }

  const recursivelyCheck = (task2: ProjectTask) => {
    if (task2.taskNumberAndName.toLowerCase().includes(lowercasedQuery)) {
      result = true;
    }

    if (task2.childNodes) {
      task2.childNodes.forEach(recursivelyCheck);
    }
  };

  recursivelyCheck(task);

  return result;
};

export const filterAndSortTasks = (
  tasksTree: ProjectTask[],
  filterString: string = null,
  maxDepth = MAX_TREE_DEPTH,
  depth = 1,
  excludedIds: string[] = []
): ProjectTask[] => {
  const lowercasedFilter = filterString ? filterString.toLowerCase() : null;

  return tasksTree.reduce((filtered, task) => {
    if (
      (!lowercasedFilter ||
        (task.taskNumberAndName || task.taskName)
          .toLowerCase()
          .includes(lowercasedFilter)) &&
      !excludedIds.includes(task.taskId)
    ) {
      if (task.childNodes?.length && depth <= maxDepth) {
        const filteredChildren = sortByFolderAndName(
          filterAndSortTasks(
            task.childNodes,
            null,
            maxDepth,
            depth + 1,
            excludedIds
          )
        );

        filtered.push({
          ...task,
          childNodes: filteredChildren,
        });
      } else {
        filtered.push(task);
      }
    } else if (
      task.childNodes &&
      task.childNodes.length &&
      depth <= maxDepth &&
      !excludedIds.includes(task.taskId)
    ) {
      const filteredChildren = sortByFolderAndName(
        filterAndSortTasks(
          task.childNodes,
          filterString,
          maxDepth,
          depth + 1,
          excludedIds
        )
      );

      if (filteredChildren.length) {
        filtered.push({
          ...task,
          childNodes: filteredChildren,
        });
      }
    }

    return sortByFolderAndName(filtered);
  }, []);
};

export const mapLocalSelectedTasks = (
  tasks: ProjectTask[],
  selectedIds: string[]
): ProjectTask[] =>
  tasks.reduce((selected, node) => {
    const hasChildren = node.childNodes?.length > 0;

    if (hasChildren && !selectedIds.includes(node.taskId)) {
      return [
        ...selected,
        ...mapLocalSelectedTasks(node.childNodes, selectedIds),
      ];
    }

    if (selectedIds.includes(node.taskId)) {
      return [...selected, node];
    }

    return selected;
  }, []);

export const filterOutWithNested = (
  node: ProjectTask,
  selected: string[]
): string[] => {
  if (!node.isFolder) {
    return selected.filter((selectedNode) => selectedNode !== node.taskId);
  }

  const nestedSelected = getLocalSelectedTaskIds([node], selected);
  return selected.filter(
    (selectedNode) => !nestedSelected.includes(selectedNode)
  );
};

export const getSelectedChaptersAndTasks = (
  nodes: ProjectTask[] | undefined,
  selectedTaskIds: string[]
): { chapterIds: string[]; taskIds: string[] } => {
  const result = {
    chapterIds: [],
    taskIds: [],
  };

  if (nodes?.length === 0) {
    return result;
  }

  const recursivelyAdd = (node: ProjectTask) => {
    const isSelected = selectedTaskIds.includes(node.taskId);

    if (node.isFolder && isSelected) {
      result.chapterIds.push(node.taskId);
    } else if (node.isFolder && node.childNodes?.length > 0) {
      node.childNodes.forEach((childNode) => {
        recursivelyAdd(childNode);
      });
    } else if (isSelected) {
      result.taskIds.push(node.taskId);
    }
  };

  nodes.forEach((node) => {
    recursivelyAdd(node);
  });

  return result;
};

export const getSelectedNodeIdsFromChaptersAndTasks = (
  nodes: ProjectTask[],
  selectedTaskIds?: string[],
  selectedChapterIds?: string[]
): string[] => {
  const result: string[] = [];

  if (selectedTaskIds?.length === 0 && selectedChapterIds?.length === 0) {
    return result;
  }

  const recursivelyAdd = (node: ProjectTask, addAll = false) => {
    if (addAll) {
      result.push(node.taskId);
      if (node.isFolder && node.childNodes?.length > 0) {
        node.childNodes.forEach((childNode) => {
          recursivelyAdd(childNode, true);
        });
      }
    } else if (node.isFolder) {
      const selectedFolder = selectedChapterIds
        ? selectedChapterIds.includes(node.taskId)
        : false;
      if (selectedFolder) {
        result.push(node.taskId);
      }

      if (node.childNodes?.length > 0) {
        node.childNodes.forEach((childNode) => {
          recursivelyAdd(childNode, selectedFolder);
        });
      }
    } else if (
      selectedTaskIds?.length > 0 &&
      selectedTaskIds.includes(node.taskId)
    ) {
      result.push(node.taskId);
    }
  };

  nodes.forEach((node) => {
    recursivelyAdd(node);
  });

  return result;
};

export const mapTemplatesToProjectTask = (
  children: FolderOrItem[]
): ProjectTask[] =>
  children.map((child: FolderOrItem) => ({
    taskId: child.id,
    taskName: child.name || '',
    isFolder: child.isFolder,
    taskNumberAndName: child.name || '',
    childNodes: mapTemplatesToProjectTask(child.children),
    taskNumber: '',
    parentNodeId: null,
    isSelected: false,
  }));
