import styled from 'styled-components';

const FullscreenMessageWrapper = styled.section`
  text-align: center;
  padding-top: 2.75rem;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default FullscreenMessageWrapper;
