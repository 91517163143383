/* eslint-disable max-classes-per-file */

import i18n from 'src/i18n';

import config from 'config/site.config';

import { getUserManager } from './auth';

const { apiBaseUrl, massTransportApiBaseUrl, reportApiBaseUrl } = config;

const getBasicHeaders = async (customHeaders = null, isMultipart = false) => {
  const user = await getUserManager().getUser();

  const token = `Bearer ${user?.access_token}`;

  const headers = {
    Accept: 'application/json',
    Authorization: token,
    'Accept-Language': i18n.language,
    Case: 'camel-case',
  };

  if (!isMultipart) {
    headers['Content-Type'] = 'application/json';
  }

  return {
    ...headers,
    ...customHeaders,
  };
};

const normalizeUrl = (url) =>
  url.startsWith('http')
    ? url
    : apiBaseUrl + (url.startsWith('/') ? url : `/${url}`);
const normalizeMasstransportUrl = (url) =>
  massTransportApiBaseUrl + (url.startsWith('/') ? url : `/${url}`);
const normalizeReportUrl = (url) =>
  reportApiBaseUrl + (url.startsWith('/') ? url : `/${url}`);

export const checkStatus = (response) => {
  if (response.status >= 200 && response.status <= 204) {
    return response;
  }

  if (response.status === 401) {
    const userManager = getUserManager();
    userManager.removeUser();
    userManager.signinRedirect();
    return null;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

export const checkStatusPublic = (response) => {
  if (response.status >= 200 && response.status <= 204) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

export const get = async (url) => {
  const headers = await getBasicHeaders();
  const reqUrl = normalizeUrl(url);
  return fetch(reqUrl, {
    cache: 'no-store',
    headers,
  }).then(checkStatus);
};

export const getPublic = async (url) => {
  const reqUrl = normalizeUrl(url);

  return checkStatusPublic(
    await fetch(reqUrl, {
      cache: 'no-store',
      Accept: 'application/json',
      Case: 'camel-case',
    })
  );
};

export const post = async (
  url,
  data,
  customHeaders = null,
  isMultipart = false
) => {
  const headers = await getBasicHeaders(customHeaders, isMultipart);
  const reqUrl = normalizeUrl(url);

  return fetch(reqUrl, {
    method: 'post',
    cache: 'no-store',
    headers,
    body: isMultipart ? data : JSON.stringify(data),
  });
};

export const put = async (url, data) => {
  const headers = await getBasicHeaders();
  const reqUrl = normalizeUrl(url);
  return fetch(reqUrl, {
    method: 'put',
    cache: 'no-store',
    headers,
    body: JSON.stringify(data),
  });
};

export const patch = async (url, data) => {
  const headers = await getBasicHeaders();
  const reqUrl = normalizeUrl(url);
  return fetch(reqUrl, {
    method: 'PATCH',
    cache: 'no-store',
    headers,
    body: JSON.stringify(data),
  });
};

export const del = async (url, data) => {
  const headers = await getBasicHeaders();
  const reqUrl = normalizeUrl(url);
  return fetch(reqUrl, {
    method: 'delete',
    cache: 'no-store',
    headers,
    body: JSON.stringify(data),
  });
};

export class MassTransportApi {
  static get = (url) => get(normalizeMasstransportUrl(url)).then(checkStatus);

  static post = (url, data) =>
    post(normalizeMasstransportUrl(url), data).then(checkStatus);

  static put = (url, data) =>
    put(normalizeMasstransportUrl(url), data).then(checkStatus);

  static patch = (url, data) =>
    patch(normalizeMasstransportUrl(url), data).then(checkStatus);

  static del = (url) => del(normalizeMasstransportUrl(url)).then(checkStatus);
}

export class ReportApi {
  static get = (url) => get(normalizeReportUrl(url)).then(checkStatus);

  static post = (url, data) =>
    post(normalizeReportUrl(url), data).then(checkStatus);

  static getFullUrl = (url) => normalizeReportUrl(url);
}
