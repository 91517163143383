import * as React from 'react';
import { ChevronDown } from '@carbon/icons-react';
import { Collapse } from '@ditioas/ui-accordion';
import { Autocomplete } from '@ditioas/ui-selectors';
import { Text } from '@ditioas/ui-typography';
import { colors } from '@ditioas/ui-variables';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CompanyAutocomplete from 'components/CompanyAutocomplete/CompanyAutocomplete';
import AutocompleteOption from 'components/form/AutocompleteOption';
import { AutocompleteOption as AutocompleteOptionType } from 'components/ReactHookForm/Autocomplete/Autocomplete';
import { useGetProjectsWithCompanies } from 'data/project-api';
import useCurrentUserAndSettings from 'hooks/useCurrentUserAndSettings';

import { FilterProps, Project } from '../types';
import { getIds } from '../utils';

const StyledAccordionSummary = styled(AccordionSummary)`
  height: 50px;
  min-height: 40px !important;

  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;

  > .MuiAccordionSummary-content.Mui-expanded {
    min-height: unset;
  }
`;

const CollapseContentWrapper = styled(AccordionDetails)`
  max-height: 50vh;

  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  overflow: hidden auto;
`;

const CollapseWrapper = styled.div`
  margin: 1rem 0 1rem 0;
`;

const StyledFilter = styled.div`
  display: flex;
  gap: 2rem;
  padding: 1.5rem;
  justify-content: flex-end;
`;

const Filters = ({ filters, setFilters }: FilterProps) => {
  const { t } = useTranslation();

  const [openFilters, setOpenFilters] = React.useState(false);
  const { currentUser } = useCurrentUserAndSettings();
  const { data: projectOptions } = useGetProjectsWithCompanies(
    getIds(filters.companies)
  );

  const handleCompanies = (param: AutocompleteOptionType[]) => {
    setFilters((filter) => ({ ...filter, companies: param }));
  };

  const handleProjects = (param: AutocompleteOptionType[]) => {
    setFilters((filter) => ({ ...filter, projects: param }));
  };

  return (
    <CollapseWrapper>
      <Collapse
        elevation={0}
        expanded={openFilters}
        onChange={() => setOpenFilters(!openFilters)}
      >
        <StyledAccordionSummary
          expandIcon={<ChevronDown size={24} color={colors.interaction} />}
        >
          <Text>{t('DataGraph.AdditionalFilters')}</Text>
        </StyledAccordionSummary>

        <CollapseContentWrapper>
          <StyledFilter>
            <CompanyAutocomplete
              multiple
              disabled={!currentUser?.sysOp && !currentUser?.isAdmin}
              value={filters.companies}
              width="fullWidth"
              label={t('DataGraph.Company')}
              onChange={(_, e: AutocompleteOptionType[]) => handleCompanies(e)}
            />
            <Autocomplete
              options={projectOptions as AutocompleteOptionType[]}
              width="fullWidth"
              multiple
              label={t('DataGraph.Project')}
              value={filters.projects}
              onChange={(_, e: AutocompleteOptionType[]) => handleProjects(e)}
              renderOption={(optionProps, project: Project) => (
                <AutocompleteOption
                  {...optionProps}
                  secondary={project.companyId || null}
                >
                  {project.name}
                </AutocompleteOption>
              )}
            />
          </StyledFilter>
        </CollapseContentWrapper>
      </Collapse>
    </CollapseWrapper>
  );
};
export default Filters;
