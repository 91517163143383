export const GET_TRIP_LOGS = 'REPORTS/GET_TRIP_LOGS';
export const GET_FILTERED_TRIP_LOGS = 'REPORTS/GET_FILTERED_TRIP_LOGS';
export const GET_TRIP_LOGS_SUCCESS = 'REPORTS/GET_TRIP_LOGS_SUCCESS';
export const SET_TRIP_LOGS = 'REPORTS/SET_TRIP_LOGS';
export const EXPORT_TRIP_LOGS = 'REPORTS/EXPORT_TRIP_LOGS';
export const EXPORT_TRIP_LOGS_SUCCESS = 'REPORTS/EXPORT_TRIP_LOGS_SUCCESS';

export const GET_TRIP_TRACKING = 'REPORTS/GET_TRIP_TRACKING';
export const GET_TRIP_TRACKING_SUCCESS = 'REPORTS/GET_TRIP_TRACKING_SUCCESS';

export const SET_MAP_READY = 'REPORTS/SET_MAP_READY';

export const REPORTS_UNMOUNT = 'REPORTS/REPORTS_UNMOUNT';

export const TOGGLE_SELECTED_TRIP_LIST_ITEMS =
  'REPORTS/TOGGLE_SELECTED_TRIP_LIST_ITEMS';
export const TOGGLE_SHOW_ONLY_WITHOUT_LOCATION =
  'REPORTS/TOGGLE_SHOW_ONLY_WITHOUT_LOCATION';

export const REPORT_MAP_UPDATED = 'REPORTS/MAP_UPDATED';

export const GET_LIVE_TRIPS = 'REPORTS/GET_LIVE_TRIPS';
export const GET_LIVE_TRIPS_SUCCESS = 'REPORTS/GET_LIVE_TRIPS_SUCCESS';

export const CLEAR_MAP = 'REPORTS/CLEAR_MAP';

export const EXPORT_SCALE = 'REPORTS/EXPORT_SCALE';

export const GET_ERROR = 'REPORTS/GET_ERROR';
