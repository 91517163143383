import * as types from './reportsTypes';

export const getTripLogsAction = (
  companyId,
  fromDate,
  toDate,
  filterGeofence,
  downloadFile
) => ({
  type: types.GET_TRIP_LOGS,
  companyId,
  fromDate,
  toDate,
  filterGeofence,
  downloadFile,
});
export const getFilteredTripLogsAction = (filters) => ({
  type: types.GET_FILTERED_TRIP_LOGS,
  filters,
});
export const getTripLogsSuccessAction = (data) => ({
  type: types.GET_TRIP_LOGS_SUCCESS,
  data,
});
export const setTripLogsAction = (data) => ({
  type: types.SET_TRIP_LOGS,
  data,
});
export const exportTripLogsSuccessAction = (data) => ({
  type: types.EXPORT_TRIP_LOGS_SUCCESS,
  data,
});

export const getTripTrackingAction = (tripId) => ({
  type: types.GET_TRIP_TRACKING,
  tripId,
});
export const getTripTrackingSuccessAction = (data) => ({
  type: types.GET_TRIP_TRACKING_SUCCESS,
  data,
});

export const reportMapReadyAction = () => ({ type: types.SET_MAP_READY });

export const unmountAction = () => ({ type: types.REPORTS_UNMOUNT });

export const toggleSelectedTripListItemsAction = (
  tripIds,
  selected = undefined
) => ({
  type: types.TOGGLE_SELECTED_TRIP_LIST_ITEMS,
  tripIds,
  selected,
});

export const toggleShowOnlyWithoutLocationAction = () => ({
  type: types.TOGGLE_SHOW_ONLY_WITHOUT_LOCATION,
});

export const getLiveTripsAction = (companyId, projectId) => ({
  type: types.GET_LIVE_TRIPS,
  companyId,
  projectId,
});
export const getLiveTripsSuccessAction = (data) => ({
  type: types.GET_LIVE_TRIPS_SUCCESS,
  data,
});

export const clearMapAction = () => ({ type: types.CLEAR_MAP });

export const reportMapUpdatedAction = () => ({
  type: types.REPORT_MAP_UPDATED,
});

export const exportScaleAction = (companyId, fromDate, toDate) => ({
  type: types.EXPORT_SCALE,
  companyId,
  fromDate,
  toDate,
});
