import React from 'react';
import { Button } from '@ditioas/ui-buttons';
import { colors } from '@ditioas/ui-variables';
import {
  addDays,
  addMonths,
  addWeeks,
  getDaysInMonth,
  parseISO,
} from 'date-fns';
import type { Preview } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import type { DateRangeWithLocaleProps } from 'components/DateRangeWithLocale/DateRangeWithLocale';
import DateRangeWithLocale from 'components/DateRangeWithLocale/DateRangeWithLocale';
import {
  getCurrentWeekNumber,
  getFirstDayOfCalendarWeek,
  getLastDayOfCalendarWeek,
} from 'libs/dateFunctions';

interface DateRangeWithPresetsProps extends DateRangeWithLocaleProps {
  setNewDates: (startDate: Date, endDate: Date) => void;
  /** Hex color string */
  presetHighlightColor?: string;
  onCloseButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const PickerAndButtonsContainer = styled.div`
  position: relative;

  display: flex;
  align-items: flex-start;
`;

const ButtonsContainer = styled.div`
  height: 100%;

  margin-bottom: 4rem;
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const PresetButton = styled(Button)`
  justify-content: flex-start;
`;

const CloseButton = styled(Button)`
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
`;

const RangePicker = styled(DateRangeWithLocale)`
  .rdrDayToday .rdrDayNumber span:after {
    background: ${colors.accent};
  }
`;

const DateRangeWithPresets = ({
  setNewDates,
  presetHighlightColor,
  className,
  onCloseButtonClick,
  ...props
}: DateRangeWithPresetsProps) => {
  const { t } = useTranslation();

  const [preview, setPreview] = React.useState<Preview | undefined>(undefined);

  const getThisWeek = () => {
    const firstDayOfWeek = getFirstDayOfCalendarWeek(getCurrentWeekNumber());
    const lastDayOfWeek = getLastDayOfCalendarWeek(getCurrentWeekNumber());
    const startDate = parseISO(firstDayOfWeek.toISOString());
    const endDate = parseISO(lastDayOfWeek.toISOString());

    return { startDate, endDate };
  };

  const getLastWeek = () => {
    const firstDayOfWeek = getFirstDayOfCalendarWeek(getCurrentWeekNumber());
    const lastDayOfWeek = getLastDayOfCalendarWeek(getCurrentWeekNumber());
    const startDate = parseISO(addWeeks(firstDayOfWeek, -1).toISOString());
    const endDate = parseISO(addWeeks(lastDayOfWeek, -1).toISOString());

    return { startDate, endDate };
  };

  const getThisMonth = () => {
    const startDate = new Date();
    startDate.setDate(1);
    const endDate = new Date();
    endDate.setDate(getDaysInMonth(new Date()));

    return { startDate, endDate };
  };

  const getLastMonth = () => {
    const startDate = addMonths(new Date(), -1);
    startDate.setDate(1);
    const endDate = addMonths(new Date(), -1);
    endDate.setDate(getDaysInMonth(endDate));

    return { startDate, endDate };
  };

  const getLast90Days = () => {
    const startDate = addDays(new Date(), -90);
    const endDate = new Date();

    return { startDate, endDate };
  };

  const onPresetClick = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    setNewDates(startDate, endDate);
  };

  return (
    <PickerAndButtonsContainer className={className}>
      <ButtonsContainer>
        <PresetButton
          variant="outlined"
          nonCaps
          onClick={() => onPresetClick(getThisWeek())}
          onMouseEnter={() => {
            setPreview({
              ...getThisWeek(),
              color: presetHighlightColor || colors.accent,
            });
          }}
          onMouseLeave={() => setPreview(undefined)}
        >
          {t('DateRange.ThisWeek')}
        </PresetButton>
        <PresetButton
          variant="outlined"
          nonCaps
          onClick={() => onPresetClick(getThisMonth())}
          onMouseEnter={() => {
            setPreview({
              ...getThisMonth(),
              color: presetHighlightColor || colors.accent,
            });
          }}
          onMouseLeave={() => setPreview(undefined)}
        >
          {t('DateRange.ThisMonth')}
        </PresetButton>
        <PresetButton
          variant="outlined"
          nonCaps
          onClick={() => onPresetClick(getLastWeek())}
          onMouseEnter={() => {
            setPreview({
              ...getLastWeek(),
              color: presetHighlightColor || colors.accent,
            });
          }}
          onMouseLeave={() => setPreview(undefined)}
        >
          {t('DateRange.LastWeek')}
        </PresetButton>
        <PresetButton
          variant="outlined"
          nonCaps
          onClick={() => onPresetClick(getLastMonth())}
          onMouseEnter={() => {
            setPreview({
              ...getLastMonth(),
              color: presetHighlightColor || colors.accent,
            });
          }}
          onMouseLeave={() => setPreview(undefined)}
        >
          {t('DateRange.LastMonth')}
        </PresetButton>
        <PresetButton
          variant="outlined"
          nonCaps
          onClick={() => onPresetClick(getLast90Days())}
          onMouseEnter={() => {
            setPreview({
              ...getLast90Days(),
              color: presetHighlightColor || colors.accent,
            });
          }}
          onMouseLeave={() => setPreview(undefined)}
        >
          {t('DateRange.Last90Days')}
        </PresetButton>
      </ButtonsContainer>

      {!!onCloseButtonClick && (
        <CloseButton variant="text" onClick={onCloseButtonClick}>
          {t('General.Close')}
        </CloseButton>
      )}

      <RangePicker preview={preview} {...props} />
    </PickerAndButtonsContainer>
  );
};

export default DateRangeWithPresets;
