/* globals CustomEvent */
import i18n from 'i18next';
import * as types from './notificationTypes';
// NB! This is not a correct way to use a reducer. The reducer has side effects and no state

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case types.SUCCESS: {
      let msg = action.data;
      if (i18n.exists(msg)) {
        msg = i18n.t(msg);
      }
      const messageEvent = new CustomEvent('NOTIFICATION/SUCCESS', {
        detail: {
          message: msg,
        },
      });
      window.dispatchEvent(messageEvent);
      return {
        ...state,
      };
    }
    case types.ERROR: {
      let msg = action.data;
      if (i18n.exists(msg)) {
        msg = i18n.t(msg);
      }
      const messageEvent = new CustomEvent('NOTIFICATION/ERROR', {
        detail: {
          message: msg,
        },
      });
      window.dispatchEvent(messageEvent);
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
