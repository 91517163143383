class Machine {
  constructor() {}

  set id(value) {
    this._id = value;
  }

  set machineId(value) {
    this._machineId = value;
  }

  set companyName(value) {
    this._companyName = value;
  }

  set companyId(value) {
    this._companyId = value;
  }

  set name(value) {
    this._name = value;
  }

  set resourceNumber(value) {
    this._resourceNumber = value;
  }

  // --------GETTERS
  get id() {
    return this._id;
  }

  get machineId() {
    return this._machineId;
  }

  get companyName() {
    return this._companyName;
  }

  get companyId() {
    return this._companyId;
  }

  get name() {
    return this._name;
  }

  get resourceNumber() {
    return this._resourceNumber;
  }

  static fromObject(inp) {
    const out = new Machine();
    if (inp) {
      out.id = inp.id;
      out.machineId = inp.machineId;
      out.companyName = inp.companyName;
      out.name = inp.name;
      out.resourceNumber = inp.resourceNumber;
      out.companyId = inp.companyId;
    }
    return out;
  }
}

export { Machine };

export const mapMachineToOption = (machine, currentCompanyId) => ({
  id: machine.id,
  resourceNumber: machine.resourceNumber,
  name: `${machine.resourceNumber ? `${machine.resourceNumber} - ` : ''}${
    machine.name
  }${
    machine.companyId !== currentCompanyId ? ` [${machine.companyName}]` : ''
  }`,
  displayName: machine.name,
  companyName:
    machine.companyId !== currentCompanyId ? machine.companyName : '',
});
