import '@brainhubeu/react-carousel/lib/style.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './index.css';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useSetAtom } from 'jotai';
import mixpanel from 'mixpanel-browser';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';

import config from 'config/site.config';
import useCurrentUserAndSettings, {
  currentUserAndSettingsAtom,
  revalidateUserAtom,
} from 'hooks/useCurrentUserAndSettings';
import { usePusherChannel, usePusherEvent } from 'hooks/usePusher';
import useShowAlert from 'hooks/useShowAlert';
import { getUserManager } from 'libs/auth';

import LoggedInRoot from './containers/logged-in-root/logged-in-root';
import Callback from './containers/login/callback';
import LoggedOut from './containers/login/logged-out';
import PublicRoot from './containers/PublicRoot/PublicRoot';

window.dataLayer = window.dataLayer || [];

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  LineElement,
  PointElement,
  Legend
);

const App = ({ locale, onLocaleChange }) => {
  const { currentUser, oidcUser } = useCurrentUserAndSettings();
  const revalidateUser = useSetAtom(revalidateUserAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const { showAlert } = useShowAlert();

  useEffect(() => {
    setTimeout(() => {
      const event = {
        event: 'virtualpageview',
        virtualPath: `${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`,
        virtualTitle: document.title,
      };

      console.log(event, 'tracking page view');
      window.dataLayer.push(event);

      if (config.mixpanelToken) {
        mixpanel.track_pageview();
      }
    }, 150);
  }, [location.pathname]);

  const channel = usePusherChannel(
    currentUser ? `user-broadcast-${currentUser.userId}` : null
  );

  usePusherEvent(
    channel,
    ['company-changed-user-event'],
    useCallback(
      (eventName, { newCompanyId }) => {
        if (currentUser?.companyId !== newCompanyId) {
          const split = location.pathname.split('/');
          if (!split[1]?.length) {
            navigate(split[0] || '');
          } else {
            navigate(split[1]);
          }
          revalidateUser();
          // removing all chached data to avoid accessing data available to a previous user
          mutate(() => true, undefined);
        }
      },
      [currentUser?.companyId]
    )
  );

  const channelSignout = usePusherChannel(currentUser ? 'user-signout' : null);

  const setUserAtom = useSetAtom(currentUserAndSettingsAtom);
  usePusherEvent(channelSignout, ['signout-all-clients'], (eventname, name) => {
    if (currentUser.aspNetId === name) {
      const userManager = getUserManager();
      setUserAtom({});
      userManager.signoutRedirect({
        id_token_hint: oidcUser.id_token,
      });
      userManager.removeUser();
    }
  });

  useEffect(() => {
    if (currentUser && currentUser.languageId !== locale) {
      onLocaleChange(currentUser.languageId);
    }
  }, [currentUser, locale]);

  useEffect(() => {
    let successTimeout = null;
    let errorTimeout = null;
    const successListener = (event) => {
      successTimeout = setTimeout(() => {
        showAlert({
          heading: event.detail.message,
          type: 'success',
        });
      }, 0);
    };

    const errorListener = (event) => {
      errorTimeout = setTimeout(() => {
        showAlert({
          heading: event.detail.message,
          type: 'error',
        });
      }, 0);
    };

    window.addEventListener('NOTIFICATION/SUCCESS', successListener);
    window.addEventListener('NOTIFICATION/ERROR', errorListener);

    return () => {
      if (successTimeout) {
        clearTimeout(successTimeout);
      }
      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      window.removeEventListener('NOTIFICATION/SUCCESS', successListener);
      window.removeEventListener('NOTIFICATION/ERROR', errorListener);
    };
  }, []);

  return (
    <Routes>
      <Route path="/callback" element={<Callback />} />
      <Route path="/logged-out" element={<LoggedOut />} />
      <Route path="/anonymous/*" element={<PublicRoot />} />
      <Route path="/*" element={<LoggedInRoot />} />
    </Routes>
  );
};

App.propTypes = {
  onLocaleChange: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

export default App;
