import { createSelector } from 'reselect';

const getCurrentState = state => state.oidc;

export const getOidcUser = createSelector(
  getCurrentState,
  state => state.user
);
export const getIsLoadingUser = createSelector(
  getCurrentState,
  state => state.isLoadingUser
);
