import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorMessageScreen from 'components/errors/ErrorMessageScreen';

const GeneralServerError = () => {
  const { t } = useTranslation();

  return (
    <ErrorMessageScreen
      heading={t('GeneralServerError.Heading')}
      buttonText={t('GeneralServerError.ActionButtonText')}
      onButtonClick={() => window.location.reload()}
    >
      <p>{t('GeneralServerError.Description')}</p>
    </ErrorMessageScreen>
  );
};

export default GeneralServerError;
