import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

import { defaultLocale } from 'model/locale';

export const initI18n = () =>
  new Promise<void>((resolve, reject) => {
    const localConfig = {
      defaultVersion: process.env.BUILD_GUID,
    };

    const httpConfig = {
      loadPath: `/i18n/{{lng}}.json?v=${process.env.BUILD_GUID}`,
    };

    i18n
      .use(Backend)
      .use(initReactI18next)
      .init(
        {
          lng: defaultLocale,
          fallbackLng: defaultLocale,
          debug: process.env.NODE_ENV === 'development',
          interpolation: {
            escapeValue: false,
          },
          backend: {
            backends:
              import.meta.env.VITE_NODE_ENV === 'production' &&
              import.meta.env.VITE_IS_ON_SERVER !== 'true'
                ? [LocalStorageBackend, HttpApi]
                : [HttpApi],
            backendOptions:
              import.meta.env.VITE_NODE_ENV === 'production' &&
              import.meta.env.VITE_IS_ON_SERVER !== 'true'
                ? [localConfig, httpConfig]
                : [httpConfig],
          },
        },
        (err) => {
          if (err) {
            reject(err);
          }
          resolve();
        }
      );
  });

export default i18n;
