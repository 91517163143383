import { createSelector } from 'reselect';

import Location from '../../model/location';
import { getLocationsState } from '../selectors';
import * as types from './locationTypes';

const initialState = {
  locationsList: null,
  genericError: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_LOCATIONS_LIST_SUCCESS:
      return {
        ...state,
        locationsList: action.data,
      };

    case types.GET_ERROR:
      return {
        ...state,
        genericError: {
          errorMsg: action.error,
          url: action.url,
          type: action.type,
        },
      };
    default:
      return state;
  }
}

const lSelector = createSelector(getLocationsState, state =>
  state ? state.locationsList : []
);
export const getLocationsList = createSelector(lSelector, list => list || []);

export const getGenericError = createSelector(getLocationsState, state =>
  state ? state.genericError : null
);
