import { useEffect, useMemo } from 'react';
import type { SortingState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import useSWRInfinite from 'swr/infinite';

import useHandleError from 'hooks/useHandleError';
import useShowAlert from 'hooks/useShowAlert';
import { checkStatus, del, post } from 'libs/apiClient';
import type {
  CertificateType,
  CompanyCertificateSettings,
  OverviewCertificate,
  UserCertificate,
} from 'model/CertificatesOverview/types';
import { SortingField } from 'model/CertificatesOverview/types';

import { useApi } from './base-api';

const certificateTypeBaseUrl = '/api/certificatetype';
const certificateV2ApiBaseUrl = '/api/v2/certificates';

export const overviewCertificatesUrl = `${certificateV2ApiBaseUrl}/types`;
export const defaultCertificatesPageSize = 30;

export const useGetOverviewCertificates = () =>
  useApi<OverviewCertificate[]>(overviewCertificatesUrl);

const getParams = (
  pageIndex: number,
  previousPageData: {
    more: boolean;
    data: UserCertificate[];
  } | null,
  sortingState: SortingState,
  filters: {
    typeId?: string;
    search?: string;
    onlyExpiredOrNotificationDateDue?: boolean;
  }
) => {
  const params = new URLSearchParams({
    page: '0',
    offset: defaultCertificatesPageSize.toString(),
  });
  if (sortingState[0]) {
    params.append(
      'sortBy',
      // Finding an enum string key that is a part of id (column key) and reading number value stored by that key
      // e.g. 'documents' is included in 'numberofdocuments'
      SortingField[
        Object.keys(SortingField).find((key) =>
          sortingState[0].id.toLowerCase().includes(key.toLowerCase())
        )
      ]
    );
    params.append('descending', String(sortingState[0].desc));
  }
  if (filters.typeId) {
    params.append('typeId', filters.typeId);
  }
  if (filters.search) {
    params.append('search', filters.search);
  }

  if (filters.onlyExpiredOrNotificationDateDue) {
    params.append('onlyExpiredOrNotificationDateDue', 'true');
  }

  if (previousPageData?.data && !previousPageData.more) {
    // no more posts to load
    return null;
  }
  // first page
  if (pageIndex === 0) return `/api/v2/certificates?${params}`;
  // load new page
  params.set('page', pageIndex.toString());
  return `/api/v2/certificates?${params}`;
};

export const useGetPagedUserCertificates = (
  sortingState: SortingState,
  typeId?: string,
  search?: string,
  onlyExpiredOrNotificationDateDue = false,
  shouldSkip = false,
  revalidateOnMount = false
) => {
  const handleError = useHandleError();
  const { data, error, size, ...rest } = useSWRInfinite<{
    more: boolean;
    data: UserCertificate[];
  }>(
    shouldSkip
      ? null
      : (pageIndex, previousPageData) =>
          getParams(pageIndex, previousPageData, sortingState, {
            typeId,
            search,
            onlyExpiredOrNotificationDateDue,
          }),
    {
      revalidateOnFocus: false,
      revalidateAll: false,
      revalidateFirstPage: false,
      revalidateOnMount,
    }
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  const flatCertificates = useMemo(
    () =>
      data ? data.map((certificatesDate) => certificatesDate.data).flat() : [],
    [data]
  );
  const isFetchingCertificates = !data && !error;
  const isLoadingMore =
    isFetchingCertificates ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');

  return {
    certificates: data,
    flatCertificates,
    size,
    error,
    isFetchingCertificates,
    isLoadingMore,
    ...rest,
  };
};

export const useGetCertificteTypeByTypeId = (typeId: string) => {
  const { data, ...rest } = useApi<CertificateType[]>(
    typeId ? `${certificateTypeBaseUrl}?typeId=${typeId}` : null
  );

  const certificateType = useMemo(() => data && data[0], [data]);

  return { certificateType, ...rest };
};

export const useCreateOrUpdateCertificateType = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const { showSuccessMessage } = useShowAlert();

  return async (newCertificateType: CertificateType) => {
    try {
      checkStatus(await post(certificateTypeBaseUrl, newCertificateType));
      showSuccessMessage({
        message: newCertificateType.id
          ? t('CertificatesOverview.CertificateUpdated', {
              typeId: newCertificateType.typeId,
            })
          : t('CertificatesOverview.CertificateCreated', {
              typeId: newCertificateType.typeId,
            }),
      });
    } catch (e) {
      handleError(e);
    }
  };
};

export const useDeleteCertificateType = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const { showSuccessMessage } = useShowAlert();

  return async (id: string, typeId: string) => {
    try {
      checkStatus(await del(`${certificateTypeBaseUrl}/${id}`));
      showSuccessMessage({
        message: t('CertificatesOverview.CertificateDeleted', { typeId }),
      });
    } catch (e) {
      handleError(e);
    }
  };
};

export const downloadCertificatesZip = async (filters: {
  certificateIds?: string[];
  typeId?: string;
  search?: string;
  onlyExpiredOrNotificationDateDue?: boolean;
}): Promise<Blob> => {
  const response = checkStatus(
    await post('/api/v2/certificates/download-attachments', filters)
  );

  return response.blob();
};

export const downloadCertificatesCSV = async (filters: {
  certificateIds?: string[];
  typeId?: string;
  search?: string;
  onlyExpiredOrNotificationDateDue?: boolean;
}): Promise<Blob> => {
  const response = checkStatus(
    await post('/api/v2/certificates/download-csv', filters)
  );

  return response.blob();
};

export const useGetCompanyCertificateSettings = (fetch: boolean) =>
  useApi<CompanyCertificateSettings>(
    fetch ? `${certificateV2ApiBaseUrl}/company-settings` : null
  );

export const useUpdateCompanyCertificateSettings = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const { showSuccessMessage } = useShowAlert();

  return async (settings: CompanyCertificateSettings) => {
    try {
      checkStatus(
        await post(`${certificateV2ApiBaseUrl}/company-settings`, settings)
      );
      showSuccessMessage({
        message: t('CertificatesOverview.CompanyCertificateSettingsSaved'),
      });
    } catch (e) {
      handleError(e);
    }
  };
};
