import React from 'react';
import { Heading, Text } from '@ditioas/ui-typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FullscreenMessageWrapper from 'components/fullscreen-message-wrapper';

const Description = styled(Text)`
  max-width: 40rem;

  margin: 1rem auto 0 auto;
`;

const PublicUnauthorized = () => {
  const { t } = useTranslation();

  return (
    <FullscreenMessageWrapper>
      <Heading>{t('General.Unauthorized')}</Heading>
      <Description>{t('General.UnauthorizedDescription')}</Description>
    </FullscreenMessageWrapper>
  );
};

export default PublicUnauthorized;
