class GeoPolygon {
  constructor() {
    this._coordinates = [];
    this._type = 'Polygon';
    this._properties = new Object();
  }

  set coordinates(value) {
    this._coordinates = value.map(x => x.map(y => y.map(z => parseFloat(z))));
  }

  set properties(value) {
    this._properties = { ...value };
  }

  // ----------GETTERS

  get coordinates() {
    return this._coordinates;
  }

  get type() {
    return this._type;
  }

  get properties() {
    return this._properties;
  }

  toJSON() {
    return {
      coordinates: this._coordinates,
      properties: this._properties,
      type: this._type,
    };
  }

  toGeoJSON() {
    return {
      type: 'Feature',
      properties: { ...this._properties },
      geometry: {
        coordinates: this._coordinates,
        type: this._type,
      },
    };
  }

  static fromObject(obj) {
    const o = new GeoPolygon();
    o.coordinates = obj.coordinates;
    o.properties = obj.properties;
    return o;
  }
}

export default GeoPolygon;
