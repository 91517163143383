import { Channel } from 'pusher-js';
import { useContext, useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { PusherContext } from '../providers/pusher-provider';

export const usePusherChannel = (channelName: string = null) => {
  const pusher = useContext(PusherContext);
  const [channel, setChannel] = useState<Channel>(null);

  useEffect(() => {
    if (channelName) {
      setChannel(pusher.subscribe(channelName));
    }
    return () => {
      if (channelName) {
        pusher.unsubscribe(channelName);
      }
    };
  }, [channelName]);

  return channel;
};

export const usePusherChannels = (channelNames: string[] = null) => {
  const pusher = useContext(PusherContext);
  const [channels, setChannels] = useState<Channel[]>(null);

  useDeepCompareEffect(() => {
    const newChannels: Channel[] = [];
    if (channelNames?.length) {
      channelNames.forEach((channelName) =>
        newChannels.push(pusher.subscribe(channelName))
      );
      setChannels(newChannels);
    }
    return () => {
      if (channelNames?.length) {
        channelNames.forEach((channelName) => pusher.unsubscribe(channelName));
      }
    };
  }, [channelNames]);

  return channels;
};

export const usePusherEvent = <T>(
  channel: Channel,
  events: Array<string>,
  onEvent: (eventName: string, data: T) => void
) => {
  useEffect(() => {
    const callbacks = [];
    if (channel) {
      events.forEach((eventName) => {
        const callback = (data: T) => {
          if (onEvent) {
            onEvent(eventName, data);
          }
        };
        callbacks.push({ eventName, callback });
        channel.bind(eventName, callback);
      });
    }

    return () => {
      if (channel) {
        callbacks.forEach(({ eventName, callback }) =>
          channel.unbind(eventName, callback)
        );
      }
    };
  }, [channel, events, onEvent]);
};

export const usePusherEventForMultipleChannels = <T>(
  channels: Channel[],
  events: Array<string>,
  onEvent: (eventName: string, data: T) => void
) => {
  useEffect(() => {
    const callbacks = [];
    if (channels?.length) {
      events.forEach((eventName) => {
        const callback = (data: T) => {
          if (onEvent) {
            onEvent(eventName, data);
          }
        };
        callbacks.push({ eventName, callback });
        channels.forEach((channel) => channel.bind(eventName, callback));
      });
    }

    return () => {
      if (channels?.length) {
        channels.forEach((channel) =>
          callbacks.forEach(({ eventName, callback }) =>
            channel.unbind(eventName, callback)
          )
        );
      }
    };
  }, [channels, events, onEvent]);
};
