import { isValid, parseISO } from 'date-fns';
import { useLocation, useParams } from 'react-router-dom';
import config from 'src/config/site.config';
import useObjectSearchParams from 'src/hooks/useObjectSearchParams';
import useSetDeleteURLParams from 'src/hooks/useSetDeleteURLParams';
import type { SeverityLevel } from 'src/model/Alerts/types';
import { ProjectsAlertsTabs } from 'src/model/Alerts/types';

const {
  modules: { alerts: alertsUrls, resourcesAndMachines: resourcesUrls },
} = config;

export interface AlertsFilterParams {
  projectIds?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
  assignedToId?: string;
  submittedById?: string;
  machineId?: string;
  needsFollowUp?: 'true';
  risk?: string;
  typeId?: string;
  categoryId?: string;
  relatedToId?: string;
  causeId?: string;
  involvedPartiesId?: string;
}

const useAlertsFilters = () => {
  const { pathname } = useLocation();

  const { resourceId } = useParams<{
    resourceId?: string;
  }>();

  const {
    projectIds,
    status,
    fromDate,
    toDate,
    assignedToId,
    submittedById,
    machineId,
    needsFollowUp,
    risk,
    typeId,
    categoryId,
    relatedToId,
    causeId,
    involvedPartiesId,
  } = useObjectSearchParams<AlertsFilterParams>();

  const {
    setURLParam,
    setMultipleURLParams,
    deleteURLParam,
    deleteMultipleURLParams,
  } = useSetDeleteURLParams<AlertsFilterParams>();

  const fromDateDate = fromDate && parseISO(fromDate);
  const toDateDate = toDate && parseISO(toDate);

  const alertsAcrossCompany = pathname.includes(alertsUrls.companyAlertsUrl);
  const alertsForCurrentUser = pathname.includes(alertsUrls.userAlertsUrl);

  const alertsForMachines =
    !!resourceId || !!machineId || pathname.includes(resourcesUrls.baseUrl);
  const defaultStatus = alertsForCurrentUser
    ? ProjectsAlertsTabs.InProgress
    : ProjectsAlertsTabs.Open;

  return {
    alertsAcrossCompany,
    alertsForCurrentUser,
    alertsForMachines,
    projectIds:
      typeof projectIds === 'string' ? projectIds.split(',') : undefined,
    fromDate: isValid(fromDateDate) ? (fromDateDate as Date) : undefined,
    toDate: isValid(toDateDate) ? (toDateDate as Date) : undefined,
    assignedToId,
    submittedById,
    resourceId,
    machineId,
    needsFollowUp: needsFollowUp === 'true',
    status: !Number.isNaN(Number(status))
      ? (Number(status) as ProjectsAlertsTabs)
      : defaultStatus,
    risk: !Number.isNaN(Number(risk))
      ? (Number(risk) as SeverityLevel)
      : undefined,
    typeId,
    categoryId,
    relatedToId,
    causeId,
    involvedPartiesId,
    setURLParam,
    setMultipleURLParams,
    deleteURLParam,
    deleteMultipleURLParams,
  };
};

export default useAlertsFilters;
