import GeoPolygon from './geo-polygon';

class Location {
  constructor() {
    this._id = null;
    this._projectIds = [];
    this._companyId = null;
    this._geometry = new GeoPolygon();
    this._name = '';
    this._code = '';
    this._description = '';
    this._preferredLoadMassTypes = [];
    this._disabledDumpMassTypes = [];
    this._enabledDumpMassTypes = [];
  }

  set code(value) {
    this._code = value;
  }

  set id(value) {
    this._id = value;
  }

  set name(value) {
    this._name = value == null ? '' : value;
  }

  set companyId(value) {
    this._companyId = value;
  }

  set projectIds(value) {
    this._projectIds = [...value];
  }

  set geometry(value) {
    this._geometry = GeoPolygon.fromObject(value);
  }

  set description(value) {
    this._description = value == null ? '' : value;
  }

  set preferredLoadMassTypes(value) {
    this._preferredLoadMassTypes = value;
  }

  set disabledDumpMassTypes(value) {
    this._disabledDumpMassTypes = value;
  }

  set enabledDumpMassTypes(value) {
    this._enabledDumpMassTypes = value;
  }

  set active(value) {
    this._active = value;
  }

  set forbidden(value) {
    this._forbidden = value;
  }

  set autoDump(value) {
    this._autoDump = value;
  }

  // ----------GETTERS

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get companyId() {
    return this._companyId;
  }

  get projectIds() {
    return this._projectIds;
  }

  get geometry() {
    return this._geometry;
  }

  get description() {
    return this._description;
  }

  get code() {
    return this._code ? this._code : '';
  }

  get preferredLoadMassTypes() {
    return this._preferredLoadMassTypes;
  }

  get disabledDumpMassTypes() {
    return this._disabledDumpMassTypes;
  }

  get enabledDumpMassTypes() {
    return this._enabledDumpMassTypes;
  }

  get active() {
    return this._active;
  }

  get forbidden() {
    return this._forbidden;
  }

  get autoDump() {
    return this._autoDump;
  }

  toJSON(noGeometry = false) {
    return {
      id: this._id ? this._id : null,
      companyId: this._companyId,
      projectIds: this._projectIds,
      name: this._name,
      description: this._description,
      code: this._code,
      geometry: noGeometry ? null : this._geometry.toJSON(),
      preferredLoadMassTypes: this._preferredLoadMassTypes,
      disabledDumpMassTypes: this._disabledDumpMassTypes,
      enabledDumpMassTypes: this._enabledDumpMassTypes,
      active: this._active,
      forbidden: this._forbidden,
      autoDump: this._autoDump,
    };
  }

  toGeoJSON() {
    const o = this._geometry.toGeoJSON();
    o.id = this._id;
    o.properties.parentProps = this.toJSON(true);
    return o;
  }

  static fromObject(obj) {
    const o = new Location();
    o.id = obj.id;
    o.companyId = obj.companyId;
    o.name = obj.name;
    o.projectIds = obj.projectIds;
    o.geometry = obj.geometry;
    o.description = obj.description;
    o.code = obj.code;
    o.preferredLoadMassTypes = obj.preferredLoadMassTypes;
    o.disabledDumpMassTypes = obj.disabledDumpMassTypes;
    o.enabledDumpMassTypes = obj.enabledDumpMassTypes;
    o.active = obj.active;
    o.forbidden = obj.forbidden;
    o.autoDump = obj.autoDump;

    return o;
  }
}

export default Location;
