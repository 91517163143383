import React from 'react';
import { ArrowLeft, Checkmark, Close } from '@carbon/icons-react';
import { Heading, Text } from '@ditioas/ui-typography';
import { colors, constants } from '@ditioas/ui-variables';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { SaveOverlay } from 'components/loaders';
import config from 'config/site.config';
import { useGetAlertById, useGetPublicAlertById } from 'data/alerts';
import useCurrentUserAndSettings from 'hooks/useCurrentUserAndSettings';
import useObjectSearchParams from 'hooks/useObjectSearchParams';
import { parseAndFormatDate } from 'libs/dateFunctions';
import { getFileUrl } from 'libs/urlFunctions';
import {
  AlertStatus,
  CompanyAlertBaseType,
  InjuryType,
} from 'model/Alerts/types';

import * as Styled from './commonStyles';
import InlineInfo from './InlineInfo/InlineInfo';

const {
  mapbox: { accessToken },
  modules: { alerts: alertsUrls },
} = config;

const ReadOnlyAlert = () => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUserAndSettings();
  const { alertId } = useParams<{ alertId?: string }>();
  const { printOnLoad } = useObjectSearchParams<{
    printOnLoad?: boolean;
  }>();
  const { xai, xat } = useObjectSearchParams<{ xai?: string; xat?: string }>();
  const { alert, isFetching: isFetchingAlert } = useGetAlertById(
    alertId,
    !!(xai && xat)
  );
  const { alert: publicAlert, isFetching: isFetchingPublicAlert } =
    useGetPublicAlertById(alertId, xai, xat);
  const alertToView = React.useMemo(
    () => (!xai && !xat ? alert : publicAlert),
    [xai, xat, alert, publicAlert]
  );
  const isFetching = React.useMemo(
    () => (!xai && !xat ? isFetchingAlert : isFetchingPublicAlert),
    [xai, xat, alert, publicAlert]
  );

  const [imagesToLoad, setImagesToLoad] = React.useState<null | number>(null);

  React.useEffect(() => {
    if (printOnLoad && !isFetching) {
      if (!imagesToLoad) {
        const imageElements = Array.from(document.getElementsByTagName('img'));
        setImagesToLoad(imageElements.length);
      }

      if (imagesToLoad === 0) {
        window.print();
      }
    }
  }, [printOnLoad, isFetching, imagesToLoad]);

  return isFetching || !alertToView ? (
    <SaveOverlay />
  ) : (
    <Styled.ReportContainer>
      <Stack>
        {currentUser && (
          <Styled.Row $notPrint>
            <Styled.ToEditingButton
              variant="outlined"
              leftIcon={<ArrowLeft size={24} />}
              component={Link}
              to={`${alertsUrls.projectsUrl}/${alertToView.projectId}/${alertToView.id}`}
            >
              {t('Alerts.ReadOnly.ToAlertPage')}
            </Styled.ToEditingButton>
          </Styled.Row>
        )}
        <Styled.Row $paddingTop>
          <Styled.PageHeading subHeading={alertToView.taskName}>
            {`${
              alertToView.motherCompanyName
                ? `${alertToView.company.name} / `
                : ''
            }${
              alertToView.projectNumber?.length > 0
                ? `${alertToView.projectNumber}  / `
                : ''
            }${alertToView.projectName}`}
          </Styled.PageHeading>
          <Styled.CornerContent>
            <Styled.CompanyLogo
              src={getFileUrl(alertToView.company.logoImageFileRef144, 144)}
              alt={alertToView.company.name}
              onLoad={() =>
                setImagesToLoad((toLoad) =>
                  typeof toLoad === 'number' ? toLoad - 1 : 0
                )
              }
            />
            <Text variant="h2" as="div">
              {alertToView.motherCompanyName || alertToView.company.name}
            </Text>
          </Styled.CornerContent>
        </Styled.Row>
        <Styled.Row>
          <Styled.TitleWrapper>
            <Text variant="h4" as="div" color="secondaryText">
              {alertToView.typeName}
            </Text>
            <Heading level={1} styleLevel={2}>
              {`${alertToView.serialNumberWithPrefix} - ${alertToView.title}`}
            </Heading>
          </Styled.TitleWrapper>
        </Styled.Row>
        <Styled.Section $smallMargin>
          {alertToView.machineName && (
            <Styled.Row $flexStart>
              <InlineInfo
                notRenderSemi
                label={t('Title.Machine')}
                value={
                  alertToView.machineResourceNumber
                    ? `${alertToView.machineResourceNumber} - ${alertToView.machineName}`
                    : alertToView.machineName
                }
              />
            </Styled.Row>
          )}
          <Styled.Row $flexStart>
            <InlineInfo
              label={t('Alerts.ReadOnly.SubmittedAt')}
              value={parseAndFormatDate({
                value: alertToView.createdAt,
                format: 'dd.MM.yyy - HH:mm',
              })}
            />
            <InlineInfo
              notRenderSemi
              label={t('General.By')}
              value={alertToView.createdBy.name}
            />
          </Styled.Row>
          <InlineInfo
            label={t('Alerts.ReadOnly.Status')}
            value={t(`Alerts.AlertStatus.${alertToView.status}`)}
            color={
              // eslint-disable-next-line no-nested-ternary
              alertToView.status === AlertStatus.Open
                ? 'accent'
                : alertToView.status === AlertStatus.InProgress
                  ? 'interaction'
                  : 'text'
            }
          />
          <InlineInfo
            label={t('Alerts.ReadOnly.Category')}
            value={alertToView.categories.map(({ name }) => name).join(', ')}
            optional
          />
          <InlineInfo
            label={t('Alerts.ReadOnly.AssignedTo')}
            value={alertToView.assignedTo?.name || t('General.NotApplicable')}
          />
          <InlineInfo
            label={t('Alerts.ReadOnly.ExternalId')}
            value={alertToView.externalId}
            optional
          />
          {!!alertToView.longitude && !!alertToView.latitude && (
            <InlineInfo
              label={t('Alerts.ReadOnly.Coordinates')}
              value={`${alertToView.latitude},${alertToView.longitude}`}
            />
          )}
        </Styled.Section>
        <Styled.Section>
          <InlineInfo
            label={t('Alerts.ReadOnly.CauseOrDescription')}
            value={alertToView.description}
            multiline
          />
          <InlineInfo
            label={t('Alerts.ReadOnly.MeasuresTakenOrSuggestions')}
            value={alertToView.measures}
            multiline
          />
          <Styled.DetailContainer>
            <Styled.InlineText>
              {t('Alerts.ReadOnly.FixedOnSpot')}:
            </Styled.InlineText>
            {alertToView.resolvedOnLocation ? (
              <Checkmark size={24} color={colors.success} />
            ) : (
              <Close size={24} color={colors.danger} />
            )}
            <Styled.InlineText fontWeight={constants.mediumFontWeight}>
              {t('Alerts.ReadOnly.NeedsFollowUp')}:
            </Styled.InlineText>
            {alertToView.requiresFurtherAction ? (
              <Checkmark size={24} color={colors.success} />
            ) : (
              <Close size={24} color={colors.danger} />
            )}
          </Styled.DetailContainer>
        </Styled.Section>

        {alertToView.commentInternal && (
          <Styled.Section>
            <InlineInfo
              label={t('Alerts.ReadOnly.InternalComment')}
              value={alertToView.commentInternal}
              multiline
              optional
            />
          </Styled.Section>
        )}

        <Styled.Section>
          <Heading level={2} styleLevel={3}>
            {t('General.Images')}
          </Heading>
          <Styled.DetailContainer $vertical>
            <Styled.ImagesContainer>
              {alertToView.imageIds.map((imageId, index) => {
                const coords = [
                  alertToView.imageFileReferences[index]?.geoCoordinate
                    ?.latitude,
                  alertToView.imageFileReferences[index]?.geoCoordinate
                    ?.longitude,
                ];

                return (
                  <Stack key={imageId}>
                    <Styled.Image
                      alt={imageId}
                      src={getFileUrl(imageId, 1200)}
                      onLoad={() =>
                        setImagesToLoad((toLoad) =>
                          typeof toLoad === 'number' ? toLoad - 1 : 0
                        )
                      }
                    />
                    {!!coords[0] && !!coords[1] && (
                      <Styled.ImagesLabel variant="b2">
                        {coords.join(', ')}
                      </Styled.ImagesLabel>
                    )}
                  </Stack>
                );
              })}
            </Styled.ImagesContainer>
          </Styled.DetailContainer>
        </Styled.Section>
        <Styled.Section>
          <Heading level={2} styleLevel={3}>
            {t('Alerts.Report.AdditionalInfo')}
          </Heading>
          {alertToView.showRisk && (
            <InlineInfo
              label={t('Alerts.ReadOnly.SeverityLevel')}
              value={t(`Alerts.Report.SeverityLevels.${alertToView.risk}`)}
            />
          )}

          {alertToView.relatedTo && (
            <InlineInfo
              label={t('Alerts.ReadOnly.WorkConnectedWith')}
              value={alertToView.relatedTo.map(({ name }) => name).join(', ')}
              optional
            />
          )}
          {alertToView.causes && (
            <InlineInfo
              label={t('Alerts.ReadOnly.Cause')}
              value={alertToView.causes.map(({ name }) => name).join(', ')}
              optional
            />
          )}
          {alertToView.involvedParties && (
            <InlineInfo
              label={t('Alerts.ReadOnly.InvolvedParties')}
              value={alertToView.involvedParties
                .map(({ name }) => name)
                .join(', ')}
              optional
            />
          )}
          {!!alertToView.customCategoryPropertyName &&
            !!alertToView.customCategories && (
              <InlineInfo
                label={alertToView.customCategoryPropertyName}
                value={alertToView.customCategories
                  .map(({ name }) => name)
                  .join(', ')}
                optional
              />
            )}
          <InlineInfo
            label={t('Alerts.ReadOnly.InvolvedResources')}
            value={alertToView.involvedResources}
            optional
          />
          <InlineInfo
            label={t('Alerts.ReadOnly.InvolvedCompanies')}
            value={alertToView.involvedCompanies
              ?.map((company) => company.name)
              .join(', ')}
            optional
          />

          {alertToView.baseType !== CompanyAlertBaseType.Other &&
            alertToView.showDamageInfo && (
              <>
                <InlineInfo
                  label={t(
                    `Alerts.ReadOnly.DamageLabel.${alertToView.baseType}` as 'Alerts.ReadOnly.DamageLabel.0'
                  )}
                  value={t(
                    `Alerts.Report.DamageTypes.${alertToView.baseType}.${alertToView.damageType}` as 'Alerts.Report.DamageTypes.0.0'
                  )}
                />
                {alertToView.baseType === CompanyAlertBaseType.HSE &&
                  alertToView.damageType === InjuryType.WithAbsence && (
                    <InlineInfo
                      label={t('Alerts.ReadOnly.NumOfDaysOfAbsence')}
                      value={alertToView.absenceDays}
                    />
                  )}
              </>
            )}
          <InlineInfo
            label={t('Alerts.ReadOnly.EstimatedCost')}
            value={alertToView.costs}
          />
        </Styled.Section>
        <Styled.Section>
          <Styled.DetailContainer $vertical>
            <Styled.ImagesLabel
              variant="b2"
              fontWeight={constants.mediumFontWeight}
            >
              {t('Title.Location')}:
            </Styled.ImagesLabel>
            {alertToView.latitude && alertToView.longitude ? (
              <Styled.MapPreview
                alt={`lat:${alertToView.latitude}_long:${alertToView.longitude}`}
                src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s(${alertToView.longitude},${alertToView.latitude})/${alertToView.longitude},${alertToView.latitude},13,0/450x450?access_token=${accessToken}`}
                onLoad={() =>
                  setImagesToLoad((toLoad) =>
                    typeof toLoad === 'number' ? toLoad - 1 : 0
                  )
                }
              />
            ) : (
              <Styled.InlineText>
                {t('Alerts.ReadOnly.InvalidLocation')}
              </Styled.InlineText>
            )}
          </Styled.DetailContainer>
        </Styled.Section>
      </Stack>
    </Styled.ReportContainer>
  );
};

export default ReadOnlyAlert;
