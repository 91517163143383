import * as types from './massTypeTypes';

export const getMaterialTypesAction = () => ({
  type: types.GET_MATERIAL_TYPES,
});
export const getMaterialTypesSuccessAction = data => ({
  type: types.GET_MATERIAL_TYPES_SUCCESS,
  data,
});

export const getMassTypesAction = companyId => ({
  type: types.GET_MASS_TYPES,
  companyId,
});
export const getMassTypesSuccessAction = data => ({
  type: types.GET_MASS_TYPES_SUCCESS,
  data,
});

export const putMassTypesAction = massType => ({
  type: types.PUT_MASS_TYPE,
  massType,
});
export const postMassTypesAction = massType => ({
  type: types.POST_MASS_TYPE,
  massType,
});
export const deleteMassTypesAction = massType => ({
  type: types.DELETE_MASS_TYPE,
  massType,
});
