import { useEffect, useRef } from 'react';

const usePreviousValue = <T>(value: T, skipEmpty?: boolean): T => {
  const ref = useRef(null);
  useEffect(() => {
    if (skipEmpty) {
      if (value) {
        ref.current = value;
      }
    } else {
      ref.current = value;
    }
  });

  return ref.current;
};

export default usePreviousValue;
