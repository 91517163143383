export const GET_MATERIAL_TYPES = 'MASSTYPE/GET_MATERIAL_TYPES';
export const GET_MATERIAL_TYPES_SUCCESS = 'MASSTYPE/GET_MATERIAL_TYPES_SUCCESS';

export const GET_ERROR = 'MASSTYPE/GET_ERROR';

export const GET_MASS_TYPES = 'MASSTYPE/GET_MASS_TYPES';
export const GET_MASS_TYPES_SUCCESS = 'MASSTYPE/GET_MASS_TYPES_SUCCESS';

export const POST_MASS_TYPE = 'MASSTYPE/POST_MASS_TYPES';
export const POST_MASS_TYPE_SUCCESS = 'MASSTYPE/POST_MASS_TYPES_SUCCESS';
export const PUT_MASS_TYPE = 'MASSTYPE/PUT_MASS_TYPES';
export const PUT_MASS_TYPE_SUCCESS = 'MASSTYPE/PUT_MASS_TYPES_SUCCESS';
export const DELETE_MASS_TYPE = 'MASSTYPE/DELETE_MASS_TYPES';
export const DELETE_MASS_TYPE_SUCCESS = 'MASSTYPE/DELETE_MASS_TYPES_SUCCESS';
