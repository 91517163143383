import * as types from './locationTypes';

export const getLocationsAction = companyId => ({
  type: types.GET_LOCATIONS_LIST,
  companyId,
});
export const getLocationsSuccessAction = data => ({
  type: types.GET_LOCATIONS_LIST_SUCCESS,
  data,
});

export const patchLocationAction = location => ({
  type: types.PATCH_LOCATION,
  location,
});
export const postLocationAction = location => ({
  type: types.POST_LOCATION,
  location,
});
export const deleteLocationAction = location => ({
  type: types.DELETE_LOCATION,
  location,
});
