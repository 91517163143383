import noValidator from 'no-data-validator';
import * as Yup from 'yup';

export const getValueOrDefault = (value, providedDefault = null) => {
  if (typeof value === 'undefined' || value === null) {
    if (providedDefault) {
      return providedDefault;
    }
    return '';
  }
  return value;
};

export const isPhoneNumber = value => {
  const re = /^(\+|[0-9 ])[0-9 ]+$/;
  return re.test(value);
};

export const isNotWhiteSpace = stringValue => {
  const re = /\S/;
  return re.test(stringValue);
};

export const parseAndNormalizeNumber = (numberString, isInt = false) => {
  if (numberString === '') {
    return 0;
  }

  if (typeof numberString === 'number') {
    return numberString;
  }

  if (isInt) {
    return parseInt(numberString.replace(',', '.'), 10);
  }
  return parseFloat(numberString.replace(',', '.'));
};

export const numberWithCommaSupport = Yup.number().transform(
  (value, orginalValue) => parseAndNormalizeNumber(orginalValue)
);

export const addCustomValidationRules = () => {
  // eslint-disable-next-line func-names
  Yup.addMethod(Yup.string, 'isNotWhiteSpace', function(message) {
    // eslint-disable-next-line func-names
    return this.test('isNotWhiteSpace', message, function(value) {
      return value?.length ? isNotWhiteSpace(value) : true;
    });
  });

  // eslint-disable-next-line func-names
  Yup.addMethod(Yup.number, 'orgNumber', function(message) {
    return this.test(
      'orgNumber',
      message,
      // eslint-disable-next-line func-names
      function(value) {
        return noValidator.organizationNumber(value);
      }
    );
  });

  // eslint-disable-next-line func-names
  Yup.addMethod(Yup.string, 'phone', function(message) {
    return this.test(
      'phone',
      message,
      // eslint-disable-next-line func-names
      function(value) {
        return isPhoneNumber(value);
      }
    );
  });

  // eslint-disable-next-line func-names
  Yup.addMethod(Yup.string, 'orgNumberString', function(message) {
    return this.test(
      'orgNumberString',
      message,
      // eslint-disable-next-line func-names
      function(value) {
        if (!value || value.length === 0) {
          return true;
        }

        return /^\d{9,}$/.test(value) || /^\d{6}-\d{4}$/.test(value);
      }
    );
  });
};
