import { useState } from 'react';
import PropTypes from 'prop-types';

import { LocalStorageManager } from 'libs/utils';

export enum LayerType {
  Wms = 'wms',
  Norkart = 'norkart',
  MapBox = 'mapBox',
}

const initalLayersConfig = {
  mapBox: 'mapbox://styles/ditio/cklgoapb46edy17ocanuxjflo',
};

/**
 * List of available MapBox layers
 * @param {*} id
 */
const mapBoxLayers = [
  { title: 'Map', mapScheme: 'mapbox://styles/mapbox/streets-v11' },
  {
    title: 'Satellite',
    mapScheme: 'mapbox://styles/ditio/cklgoapb46edy17ocanuxjflo',
  },
];

/**
 * Availabe types: 'map','map_bw','map_medium','map_lite','photo','hybrid'
 * @param {*} id
 */
const getNorkartLayerById = id => {
  let out = 'standard-vektor';

  switch (id) {
    case 'map':
      out = 'standard-vektor';
      break;
    case 'map_bw':
      out = 'gray-vektor';
      break;
    case 'map_medium':
      out = 'medium-vektor';
      break;
    case 'map_lite':
      out = 'lite-vektor';
      break;
    case 'photo':
      out = 'orto-newup';
      break;
    case 'hybrid':
      out = 'standard-hybrid';
      break;
    default:
      break;
  }
  return out;
};

class MapLayer {
  constructor() {
    this.id = null;
    this.active = true;
    this.sortOrder = 0;
    this.parameters = {};
    this.apiKey = '';
    this.companyId = null;
    this.name = '';
    this.mapType = LayerType.Wms;
  }

  static fromObject(obj) {
    const o = new MapLayer();
    if (obj) {
      o.id = obj.id;
      o.active = obj.active;
      o.sortOrder = obj.sortOrder;
      o.parameters = obj.parameters;
      o.apiKey = obj.apiKey;
      o.mapType = obj.mapType;
      o.name = obj.name;
    }
    return o;
  }

  toJSON() {
    return {
      id: this.id,
      companyId: this.companyId,
      active: this.active,
      mapType: this.mapType,
      parameters: this.parameters,
      sortOrder: this.sortOrder,
      apiKey: this.apiKey,
      name: this.name,
    };
  }
}

const getCurrentLayerConfig = () => {
  let config = LocalStorageManager.getMapLayersConfig();

  if (!config) {
    config = initalLayersConfig;
    LocalStorageManager.setMapLayersConfig(initalLayersConfig);
  }

  return config;
};

let currentConfig = null;

const getCurrentLayerConfigAndCacheToSingleton = () => {
  if (!currentConfig) {
    currentConfig = getCurrentLayerConfig();
  }
  return currentConfig;
};

export const storeLayer = (key, val) => {
  const config = getCurrentLayerConfigAndCacheToSingleton();

  const newConfig = {
    ...config,
    [key]: val,
  };
  currentConfig = newConfig;
  LocalStorageManager.setMapLayersConfig(newConfig);
};

const getConfig = (config, layerType: LayerType, opts) => {
  let layerConfig = null;
  if (layerType === LayerType.Norkart) {
    if (Object.prototype.hasOwnProperty.call(config, LayerType.Norkart)) {
      const layerFromConfig = config[LayerType.Norkart];
      if (opts.indexOf(layerFromConfig) > -1) {
        layerConfig = layerFromConfig;
      } else if (opts.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        layerConfig = opts[0];
      }
    }
  } else if (layerType === LayerType.Wms) {
    if (Object.prototype.hasOwnProperty.call(config, opts)) {
      layerConfig = config[opts];
    } else {
      layerConfig = true;
    }
  } else if (layerType === LayerType.MapBox) {
    const configFromStore = config.mapBox;

    if (mapBoxLayers.some(layer => layer.mapScheme === configFromStore)) {
      layerConfig = configFromStore;
    } else {
      layerConfig = mapBoxLayers.find(layer => layer.title === 'Satellite')
        .mapScheme;
    }
  }

  return layerConfig;
};

export const getLayerConfig = (layerType: LayerType, opts?: any) => {
  const config = getCurrentLayerConfigAndCacheToSingleton();
  return getConfig(config, layerType, opts);
};

export const useLayerConfig = () => {
  const [layerConfig, setLayerConfig] = useState(getCurrentLayerConfig());

  return {
    getLayerConfig: (layerType: LayerType, opts?: any) =>
      getConfig(layerConfig, layerType, opts),
    storeLayerConfig: (layerType: LayerType, value: string) => {
      const newConfig = {
        ...layerConfig,
        [layerType]: value,
      };
      setLayerConfig(newConfig);
      LocalStorageManager.setMapLayersConfig(newConfig);
    },
    layerConfig,
  };
};

export const layerShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  apiKey: PropTypes.string.isRequired,
  mapType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parameters: PropTypes.shape({
    layers: PropTypes.arrayOf(PropTypes.string),
  }),
});

export { MapLayer, getNorkartLayerById, mapBoxLayers, initalLayersConfig };
