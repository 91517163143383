class MassType {
  constructor(
    id = '',
    name = '',
    companyId = '',
    description = '',
    active = true,
    m3ConversionFactor = 1.0,
    materialType = 0
  ) {
    this._id = id;
    this._name = name;
    this._companyId = companyId;
    this._description = description;
    this._active = active;
    this._m3ConversionFactor = m3ConversionFactor;
    this._materialType = materialType;
  }

  set id(value) {
    this._id = value;
  }

  set name(value) {
    this._name = value;
  }

  set companyId(value) {
    this._companyId = value;
  }

  set description(value) {
    this._description = value;
  }

  set active(value) {
    this._active = value;
  }

  set m3ConversionFactor(value) {
    this._m3ConversionFactor = parseFloat(`${value}`);
  }

  set materialType(value) {
    this._materialType = parseInt(`${value}`);
  }

  set code(value) {
    this._code = value;
  }

  // ----------GETTERS

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get companyId() {
    return this._companyId;
  }

  get description() {
    return this._description;
  }

  get active() {
    return this._active;
  }

  get m3ConversionFactor() {
    return this._m3ConversionFactor;
  }

  get materialType() {
    return this._materialType;
  }

  get code() {
    return this._code ? this._code : '';
  }

  toJSON() {
    return {
      id: this._id ? this._id : null,
      name: this._name,
      companyId: this._companyId,
      description: this._description,
      m3ConversionFactor: this._m3ConversionFactor,
      materialType: this._materialType,
      code: this._code,
      active:
        typeof this._active === 'boolean'
          ? this._active
          : this._active.toLowerCase() == 'true',
    };
  }

  static fromObject(obj) {
    const o = new MassType();
    o.id = obj.id;
    o.description = obj.description;
    o.active = obj.active;
    o.companyId = obj.companyId;
    o.name = obj.name;
    o.m3ConversionFactor = obj.m3ConversionFactor;
    o.materialType = obj.materialType;
    o.code = obj.code;
    return o;
  }
}

export default MassType;
