/* eslint-disable import/prefer-default-export */
/* globals FormData */
import React from 'react';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import { useApi, useApiWithDataById } from 'data/base-api';
import useHandleError from 'hooks/useHandleError';
import useShowAlert from 'hooks/useShowAlert';
import { checkStatus, del, get, post, put } from 'libs/apiClient';
import { reduceToObjectById } from 'libs/utils';
import type {
  Company,
  CompanyConfiguration,
  CompanyConfigurationWithStringedTime,
  CompanyOption,
  CompanyRelations,
  NewCompany,
  ProjectWithTerminals,
  TimeZone,
} from 'model/Company/types';

export const companyBaseApiUrl = '/api/v4/companies';

export const useGetRawCompanies = (skip = false) =>
  useApi<Company[]>(skip ? null : companyBaseApiUrl);

export const useGetAllCompanies = () => {
  const { data, dataById, ...rest } = useApiWithDataById<Company[], Company>(
    `${companyBaseApiUrl}`,
    undefined,
    'companyId'
  );

  const dataWithIdAndName: CompanyOption[] = data?.length
    ? data.map((company) => ({
        ...company,
        id: company.companyId,
        name: company.companyName,
      }))
    : null;

  return {
    data: dataWithIdAndName,
    companiesById: dataById,
    ...rest,
  };
};

export const getListOfCompanies = async () => {
  const response = await get(`${companyBaseApiUrl}`).then(checkStatus);

  return response.json();
};

export const searchForNewCompanies = async (
  searchString: string
): Promise<Company[]> => {
  const response = await get(
    `${companyBaseApiUrl}/search/${encodeURIComponent(
      searchString
    )}?excludeProjectCompanies=true`
  ).then(checkStatus);

  return response.json();
};

export const searchForDitioCompanies = async (
  searchString: string
): Promise<Company[]> => {
  if (!searchString.length) {
    return [];
  }

  const response = checkStatus(
    await get(
      `${companyBaseApiUrl}/search/${searchString}?onlyDitioCompanies=true`
    )
  );

  return response.json();
};

export const useGetSearchForDitioCompanies = (searchString: string) => {
  const data = useApi<Company[]>(
    searchString
      ? `${companyBaseApiUrl}/search/${searchString}?onlyDitioCompanies=true`
      : null
  );

  if (!searchString) {
    return {
      mutate: () => searchString,
      isValidating: false,
      data: [] as Company[],
      error: null,
      isFetching: false,
      isEmpty: false,
    };
  }

  return data;
};

export const useGetCompanyById = (companyId: Company['companyId']) =>
  useApi<Company>(companyId ? `${companyBaseApiUrl}/${companyId}` : null);

export const uploadCompanyAvatar = async (
  file: File,
  companyId: Company['companyId'] = null
) => {
  const data = new FormData();
  data.append('file', file);
  let fileHeaders = null;

  if (companyId) {
    fileHeaders = {
      CollectionRef: 'Company',
      IdRef: companyId,
    };
  }

  const response = checkStatus(
    await post(`${companyBaseApiUrl}/upload-avatar`, data, fileHeaders, true)
  );
  const parsed = await response.json();

  return parsed;
};

export const putCompanyById = async (company: Company): Promise<Company> => {
  const response = checkStatus(
    await put(`${companyBaseApiUrl}/${company.companyId}`, company)
  );

  const newCompany: Company = await response.json();

  return newCompany;
};

export const createNewCompany = async (
  company: NewCompany
): Promise<Company> => {
  const response = checkStatus(await post(`${companyBaseApiUrl}`, company));

  const newCompany: Company = await response.json();

  return newCompany;
};

export const createNewProjectCompany = async (
  parentCompanyId: Company['companyId'],
  company: NewCompany
): Promise<Company> => {
  const response = checkStatus(
    await post(
      `${companyBaseApiUrl}/${parentCompanyId}/project-company`,
      company
    )
  );

  const newCompany: Company = await response.json();

  return newCompany;
};

export const createNewSubsidiaryCompany = async (
  parentCompanyId: Company['companyId'],
  company: NewCompany
): Promise<Company> => {
  const response = checkStatus(
    await post(
      `${companyBaseApiUrl}/${parentCompanyId}/subsidiary-company`,
      company
    )
  );

  const newCompany: Company = await response.json();

  return newCompany;
};

export const addCompanyAsTrustedSubcontractor = async (
  parentCompanyId: Company['companyId'],
  subcontractorCompanyId: Company['companyId']
) => {
  const response = await post(
    `${companyBaseApiUrl}/${parentCompanyId}/connect-subcontractor/${subcontractorCompanyId}`
  ).then(checkStatus);

  return response.json();
};

export const deleteCompanyById = async (companyId: Company['companyId']) => {
  const response = await del(`${companyBaseApiUrl}/${companyId}`).then(
    checkStatus
  );

  return response.json();
};

export const useGetCompanyConfiguration = (companyId: Company['companyId']) => {
  const { data, ...rest } = useApi<CompanyConfigurationWithStringedTime>(
    companyId ? `${companyBaseApiUrl}/${companyId}/configuration` : null
  );

  const parsed = React.useMemo(
    () =>
      data && {
        ...data,
        defaultNextCheckInNotificationTime: parse(
          data.defaultNextCheckInNotificationTime as unknown as string,
          'HH:mm:ss',
          new Date()
        ),
      },
    [data]
  );

  return { companyConfiguration: parsed, ...rest };
};

export const putCompanyConfiguration = async (
  companyConfiguration: CompanyConfiguration
): Promise<CompanyConfigurationWithStringedTime> => {
  const response = checkStatus(
    await put(
      `${companyBaseApiUrl}/${companyConfiguration.companyId}/configuration`,
      {
        ...companyConfiguration,
        defaultNextCheckInNotificationTime: format(
          companyConfiguration.defaultNextCheckInNotificationTime,
          'HH:mm:ss'
        ),
      }
    )
  );
  const parsed = response.json();

  return parsed;
};

export const useGetCompanyRelations = (
  companyId?: Company['companyId'] | null
) =>
  useApi<CompanyRelations>(
    companyId ? `${companyBaseApiUrl}/${companyId}/relations` : null
  );

export const searchForCompany = async (
  searchString: string,
  isSubcontractorSearch = false
) => {
  const response = await get(
    `${companyBaseApiUrl}/search/${encodeURIComponent(
      searchString
    )}?onlyBrregCompanies=false&isSubcontractorSearch=${isSubcontractorSearch}`
  );
  const companies: Company[] = await response.json();

  return companies;
};

export const getAvailableTimezones = async (): Promise<TimeZone[]> => {
  const response = await get(`${companyBaseApiUrl}/available-timezones`);
  const timezones = await response.json();

  return timezones;
};

export const useRelatedCompanies = (revalidateOnMount = true) => {
  const { data, ...rest } = useApi<Company[]>(
    `${companyBaseApiUrl}/current/related-companies`,
    { revalidateOnMount }
  );

  const dataWithIdAndName: CompanyOption[] = data?.length
    ? data.map((company) => ({
        ...company,
        id: company.companyId,
        name: company.companyName,
      }))
    : null;

  const dataById = dataWithIdAndName && reduceToObjectById(dataWithIdAndName);

  return {
    data: dataWithIdAndName,
    companiesById: dataById,
    ...rest,
  };
};

export const useGetProjectsWithTerminals = (
  companyId: string,
  search?: string,
  includeWithoutTerminals = false,
  shouldSkip = false
) => {
  const { data, ...rest } = useApi<ProjectWithTerminals[]>(
    shouldSkip
      ? null
      : `/api/projects/list-with-terminals/${companyId}?includeWithoutTerminals=${includeWithoutTerminals}`
  );

  const filteredProjects = React.useMemo(
    () =>
      data?.filter(
        (project) =>
          project.name.toLowerCase().includes(search.toLowerCase()) ||
          project.number.includes(search) ||
          project.terminalIds.includes(search)
      ),
    [data, search]
  );

  return { projects: filteredProjects, ...rest };
};

export const useUpdateProjectTerminals = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const { showSuccessMessage } = useShowAlert();

  return async (projectId: string, terminalIds: string[]) => {
    try {
      checkStatus(
        await put('/api/projects/terminals', {
          id: projectId,
          terminalIds,
        })
      );
      mutate<ProjectWithTerminals[]>(
        (key) =>
          typeof key === 'string' &&
          key.startsWith('/api/projects/list-with-terminals'),
        (cachedData) => {
          const newData = [...cachedData];

          const updatedProjectIndex = newData.findIndex(
            (project) => project.id === projectId
          );
          newData[updatedProjectIndex].terminalIds = terminalIds;

          return newData;
        }
      );

      showSuccessMessage({
        message: t('Company.TerminalSaveSuccess'),
      });
    } catch (e) {
      handleError(e);
    }
  };
};
