import * as Sentry from '@sentry/react';

import * as notifications from './notification/notificationActions';

export const withErrorHandling = promise =>
  promise.catch(e => {
    throw e;
  });

export function* onError(error) {
  if (error.hasOwnProperty('response')) {
    const err = yield error.response.json();
    let outputMessage = err.message ? err.message : error.toString();
    if (err.errors && Array.isArray(err.errors)) {
      outputMessage = `${err.errors[0].category}.${err.errors[0].code}`;
    }

    return notifications.showErrorMessageAction(outputMessage);
  }
  Sentry.withScope(scope => {
    scope.setExtra('originalMessage', error.originalMessage);
    scope.setExtra('action', error.action);
    Sentry.captureException(error);
  });
  return notifications.showErrorMessageAction(
    error.message ? error.message : error.toString()
  );
}

export function runWithTryCatch(executeMe, json, errorMessage, action) {
  try {
    return executeMe.call(null, json);
  } catch (error) {
    const e = new Error(errorMessage);
    e.originalMessage = error.toString();
    e.action = action;
    throw e;
  }
}
