import { atom, useAtomValue } from 'jotai';
import { atomWithDefault, unwrap } from 'jotai/utils';
import { useSelector } from 'react-redux';

import { get } from 'libs/apiClient';
import type { modules } from 'model/modules';
import type { User, UserSettings } from 'model/User/types';
import { ProjectUserRole, UserSecurityLevel } from 'model/User/types';
import { getOidcUser } from 'store/oidcSelectors';

// This atom is used to prevent current user from being fetched before user is authentified
export const currentUserPlaceholderAtom = atom(
  {} as {
    user: User;
    settings: UserSettings;
  }
);
export const fetchCurrentUserAtom = atomWithDefault(async () => {
  const response = await get('api/v3/user/withsettings/current');

  if (response) {
    const parsed = await response.json();

    return parsed as {
      user: User;
      settings: UserSettings;
    };
  }

  return null;
});

export const currentUserAndSettingsAtom = unwrap(fetchCurrentUserAtom);
export const setUserLanguageAtom = atom(
  null,
  (getAtom, setAtom, newLang: string) => {
    const currentAtom = getAtom(currentUserAndSettingsAtom);

    setAtom(currentUserAndSettingsAtom, {
      ...currentAtom,
      // This works but I am not sure what's a good way to fix typing
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      user: { ...currentAtom.user, languageId: newLang },
    });
  }
);
export const revalidateUserAtom = atom(null, async (_, setAtom) => {
  const response = await get('api/v3/user/withsettings/current');

  if (response) {
    const parsed = await response.json();
    setAtom(currentUserAndSettingsAtom, parsed);
  }
});

const useCurrentUserAndSettings = () => {
  const oidcUser = useSelector(getOidcUser);

  const data = useAtomValue(
    oidcUser ? currentUserAndSettingsAtom : currentUserPlaceholderAtom
  );

  const currentUser = data?.user as User;
  const currentUserSettings = data?.settings;

  const hasFeature = (featureName: (typeof modules)[keyof typeof modules]) =>
    currentUserSettings?.modules?.includes(featureName);

  const hasPilotFlag = (flagName: string) =>
    currentUserSettings?.pilotFlags?.includes(flagName);

  const isSuperUserPlus =
    currentUser?.userSecurityLevel >= UserSecurityLevel.Superuser;

  const isForemanPlusInSomeProject = () =>
    currentUserSettings?.projUserSettings?.some(
      (item) => item.userRole >= ProjectUserRole.Foreman
    );

  const isProjectForeman = (projectId: string) =>
    currentUserSettings?.projUserSettings?.some(
      (item) =>
        item.projId === projectId && item.userRole >= ProjectUserRole.Foreman
    );

  const isProjectManager = (projectId: string) =>
    currentUserSettings?.projUserSettings?.some(
      (item) =>
        item.projId === projectId &&
        item.userRole >= ProjectUserRole.PorjectManager
    );

  const hasPermisionToEditTemplate = (projectId: string | undefined) =>
    currentUser.isAdmin ||
    !!(projectId && isProjectManager(projectId) && currentUser.isSuperUser);

  const isSysOpOrCompanyOwner =
    currentUser?.sysOp ||
    currentUser?.isImpersonating ||
    currentUser?.currentCompanyOwner;

  const isNorwegianCustomer =
    currentUser?.companyCountryCode?.toLowerCase() === 'no';

  return {
    currentUser,
    currentUserSettings,
    massTransportEditAccess: currentUserSettings?.massTransportEditAccess,
    hasFeature,
    hasPilotFlag,
    hasPermisionToEditTemplate,
    oidcUser,
    isSuperUserPlus,
    isForemanPlusInSomeProject,
    isProjectForeman,
    isNorwegianCustomer,
    isSysOpOrCompanyOwner,
  };
};

export default useCurrentUserAndSettings;
