import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { MassTransportApi } from '../../libs/apiClient';
import { onError, withErrorHandling, runWithTryCatch } from '../helpers';

import * as types from './locationTypes';
import * as actions from './locationActions';
import * as selectors from './locationReducer';
import * as notifications from '../notification/notificationActions';
import Location from '../../model/location';

const getLocations = companyId => {
  const url = `api/location/company-geoFence/${companyId}`;
  return withErrorHandling(
    MassTransportApi.get(url).then(result => result.json())
  );
};

const parseLocations = data => {
  let locations = [];
  if (data) {
    locations = data.map(i => Location.fromObject(i));
  }
  return locations;
};

function* fetchLocations(params) {
  try {
    const data = yield call(getLocations, params.companyId);
    const locations = runWithTryCatch(
      parseLocations,
      data,
      'Failed to parse response from server',
      types.GET_LOCATIONS_LIST
    );
    yield put(actions.getLocationsSuccessAction(locations));
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const patchLocation = location => {
  const url = `api/location/${location.id}`;
  return withErrorHandling(
    MassTransportApi.put(url, location).then(result => result.json())
  );
};

function* updateLocation(params) {
  try {
    const data = yield call(patchLocation, params.location);
    yield [
      put(notifications.showSuccessMessageAction('Location updated')),
      put(actions.getLocationsAction(params.location.companyId)),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const postLocation = location => {
  const url = '/api/location/';
  return withErrorHandling(
    MassTransportApi.post(url, location).then(result => result.json())
  );
};

function* addLocation(params) {
  try {
    const data = yield call(postLocation, params.location);
    yield [
      put(notifications.showSuccessMessageAction('New location added')),
      put(actions.getLocationsAction(params.location.companyId)),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const deleteLocation = location => {
  const url = `api/location/${location.id}`;
  return withErrorHandling(
    MassTransportApi.del(url).then(result => result.json())
  );
};

function* delLocation(params) {
  try {
    const data = yield call(deleteLocation, params.location);
    yield [
      put(notifications.showSuccessMessageAction('Location deleted')),
      put(actions.getLocationsAction(params.location.companyId)),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

export default function* locationSagas() {
  yield all([
    takeEvery(types.GET_LOCATIONS_LIST, fetchLocations),
    takeEvery(types.PATCH_LOCATION, updateLocation),
    takeEvery(types.POST_LOCATION, addLocation),
    takeEvery(types.DELETE_LOCATION, delLocation),
  ]);
}
