export const GET_LOCATIONS_LIST = 'LOCATION/GET_LOCATIONS_LIST';
export const GET_LOCATIONS_LIST_SUCCESS = 'LOCATION/GET_LOCATIONS_LIST_SUCCESS';

export const GET_ERROR = 'LOCATION/GET_ERROR';

export const POST_LOCATION = 'LOCATION/POST_LOCATION';
export const POST_LOCATION_SUCCESS = 'LOCATION/POST_LOCATION_SUCCESS';
export const PATCH_LOCATION = 'LOCATION/PATCH_LOCATION';
export const PATCH_LOCATION_SUCCESS = 'LOCATION/PATCH_LOCATION_SUCCESS';
export const DELETE_LOCATION = 'LOCATION/DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'LOCATION/DELETE_LOCATION_SUCCESS';
