import React from 'react';
import type { LanguageOption } from '@ditioas/web-ui';
import { LanguageFlag } from '@ditioas/web-ui';

export const defaultLocale = 'nb';

export const locales = {
  EN: 'en',
  NB: defaultLocale,
  SV: 'sv',
} as const;

export type LocaleId = (typeof locales)[keyof typeof locales];

export const localeList: LanguageOption[] = [
  {
    id: locales.NB,
    flagIcon: <LanguageFlag lang="nb" />,
    name: 'Norwegian',
    shortName: 'Nor',
  },
  {
    id: locales.EN,
    flagIcon: <LanguageFlag lang="en" />,
    name: 'English',
    shortName: 'Eng',
  },
  {
    id: locales.SV,
    flagIcon: <LanguageFlag lang="sv" />,
    name: 'Swedish',
    shortName: 'Swe',
  },
];

const defaultLocalObject = localeList.find(
  (item) => item.id === defaultLocale
)!;

export const findLocaleById = (id: LocaleId = defaultLocale) => {
  if (!id) {
    return defaultLocalObject;
  }

  const foundLocale = localeList.find(
    (language) => language.id === id.toLowerCase()
  );

  if (!foundLocale) {
    return defaultLocalObject;
  }

  return foundLocale;
};

export const existsLocale = (id: LocaleId) =>
  !!id && localeList.some((locale) => locale.id === id.toLowerCase());
