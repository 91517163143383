import { createSelector } from 'reselect';

import * as types from './massTypeTypes';
import { getMassTypeState } from '../selectors';

const initialState = {
  massTypesList: null,
  materialTypesList: null,
  genericError: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_MASS_TYPES_SUCCESS:
      return {
        ...state,
        massTypesList: action.data,
      };
    case types.GET_MATERIAL_TYPES_SUCCESS:
      return {
        ...state,
        materialTypesList: action.data,
      };
    case types.GET_ERROR:
      return {
        ...state,
        genericError: {
          errorMsg: action.error,
          url: action.url,
          type: action.type,
        },
      };
    default:
      return state;
  }
}

const materialsListSelector = createSelector(
  getMassTypeState,
  state => (state ? state.materialTypesList : [])
);
const mSelector = createSelector(
  getMassTypeState,
  state => (state && state.massTypesList ? state.massTypesList : [])
);
export const getMassTypesList = createSelector(
  mSelector,
  list =>
    list.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    })
);
export const getMassTypesNames = createSelector(
  mSelector,
  list => list.map(l => l.name)
);
export const getMaterialTypesList = createSelector(
  materialsListSelector,
  list => list
);
export const getGenericError = createSelector(
  getMassTypeState,
  state => (state ? state.genericError : null)
);
