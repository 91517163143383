import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { parseAndFormatDate } from 'libs/dateFunctions';
import { HttpStatusCode } from 'model/types';

import { useStatusCodeErrorContext } from '../providers/status-code-error-provider';
import useShowAlert from './useShowAlert';

const useHandleError = (): ((
  error: any,
  {
    silent,
    showFullscreenErrorMessage,
    defaultErrorMessage,
  }?: {
    showFullscreenErrorMessage?: boolean | number[];
    silent?: boolean;
    defaultErrorMessage?: any;
  },
  callBack?: ({
    message,
    additionalData,
  }: {
    message: string;
    additionalData?: unknown;
  }) => void
) => Promise<void>) => {
  const { t } = useTranslation();
  const { showErrorMessage, showGeneralErrorMessage } = useShowAlert();
  const setStatusCode = useStatusCodeErrorContext();

  return async (
    error,
    { silent, showFullscreenErrorMessage, defaultErrorMessage } = {
      silent: false,
      showFullscreenErrorMessage: false,
      defaultErrorMessage: null,
    },
    callBack = null
  ) => {
    if (!error || error.response?.status === HttpStatusCode.NoContent) {
      return;
    }

    const errorStatusCode = error.response?.status;
    const skipErrorMessage =
      errorStatusCode === HttpStatusCode.Conflict && !!callBack;

    if (
      !silent &&
      (!showFullscreenErrorMessage ||
        (Array.isArray(showFullscreenErrorMessage) &&
          showFullscreenErrorMessage.every((code) => code !== errorStatusCode)))
    ) {
      // no sense to show alert if we hide whole page
      try {
        const errorResponse = await error.response.json();
        console.log(errorResponse, 'errorResponse');

        let errorMessage = null;
        if (errorResponse.error?.message) {
          errorMessage = errorResponse.error.message;
        } else if (errorResponse.message) {
          errorMessage = errorResponse.message;
        } else if (
          errorResponse.errors &&
          Array.isArray(errorResponse.errors) &&
          errorResponse.errors.length
        ) {
          let errorData = errorResponse.errors[0].additionalData;
          if (errorData) {
            errorData = Object.keys(errorData).reduce((result, key) => {
              let mapped = errorData[key];
              if (!Number.isNaN(Date.parse(mapped))) {
                mapped = parseAndFormatDate({
                  value: mapped,
                  parseAsLocalDate: true,
                });
              }
              // eslint-disable-next-line no-param-reassign
              result[key] = mapped;
              return result;
            }, {});
          }

          errorMessage = t(
            `${errorResponse.errors[0].category}.${errorResponse.errors[0].code}`,
            errorData
          );
        } else if (defaultErrorMessage) {
          errorMessage = defaultErrorMessage;
        } else {
          showGeneralErrorMessage();
        }

        if (errorMessage) {
          if (!skipErrorMessage) {
            showErrorMessage({ message: errorMessage });
          }
          if (callBack) {
            callBack({
              message: errorMessage,
              additionalData: errorResponse?.errors?.[0]?.additionalData,
            });
          }
        }
      } catch (e) {
        showGeneralErrorMessage();
      }
    }

    if (!errorStatusCode || errorStatusCode >= 500) {
      Sentry.withScope((scope) => {
        scope.setExtra('originalMessage', error.originalMessage);
        scope.setExtra('action', error.action);
        Sentry.captureException(error);
      });
    }

    if (
      (Array.isArray(showFullscreenErrorMessage) &&
        showFullscreenErrorMessage.includes(errorStatusCode)) ||
      (!Array.isArray(showFullscreenErrorMessage) && showFullscreenErrorMessage)
    ) {
      setStatusCode(errorStatusCode);
    }
  };
};

export default useHandleError;
