/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export interface LayoutContextParams {
  forcedCollapsible: boolean;
  setForcedCollapsible: React.Dispatch<
    React.SetStateAction<LayoutContextParams['forcedCollapsible']>
  >;
}

const LayoutContext = React.createContext<LayoutContextParams>({
  forcedCollapsible: false,
  setForcedCollapsible: () => {},
});

export const useLayoutContext = () => {
  const context = React.useContext(LayoutContext);

  return context;
};

export default LayoutContext;
