import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertsContext } from '../providers/alerts-provider';

type AlertType = 'error' | 'warning' | 'success' | 'notification';
export type StatusMessage = ({
  heading,
  message,
}: {
  heading?: string;
  message: string;
}) => void;

interface ReturnValue {
  showAlert: ({
    type,
    heading,
    message,
    content,
  }: {
    type: AlertType;
    heading: string;
    message?: string;
    content?: React.ReactNode;
  }) => void;
  showGeneralErrorMessage: () => void;
  showSuccessMessage: StatusMessage;
  showErrorMessage: StatusMessage;
}

const useShowAlert = (): ReturnValue => {
  const showAlert = useContext(AlertsContext);
  const { t } = useTranslation();

  const showSuccessMessage = ({ heading, message }) =>
    showAlert({
      type: 'success',
      heading: heading || t('General.SuccessMessageHeading'),
      content: <p>{message}</p>,
    });

  const showErrorMessage = ({ heading, message }) =>
    showAlert({
      type: 'error',
      heading: heading || t('General.ErrorMessageHeading'),
      content: <p>{message}</p>,
    });

  const showGeneralErrorMessage = () =>
    showAlert({
      type: 'error',
      heading: t('General.ErrorMessageHeading'),
      content: t('General.ErrorDescription'),
    });

  return {
    showAlert,
    showSuccessMessage,
    showErrorMessage,
    showGeneralErrorMessage,
  };
};

export default useShowAlert;
