import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

import { Alert } from '@ditioas/ui-system-messages';

export const AlertsContext = createContext(null);

const Wrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 3rem;
  z-index: 2000;

  & > * {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const useAlerts = () => {
  const [alerts, setAlerts] = useState({});

  const addAlert = ({ heading, content, type }) => {
    const id = uuid();

    const newAlert = {
      id,
      created: new Date().getTime(),
      type,
      heading,
      content,
    };

    setAlerts(al => ({
      ...al,
      [id]: newAlert,
    }));

    setTimeout(() => {
      setAlerts(al => {
        const currentAlert = al[id];
        const alertsAsArray = Object.keys(al).map(key => al[key]);

        if (
          alertsAsArray.some(
            a => a.id !== id && currentAlert.created < a.created
          )
        ) {
          return al;
        }

        return {};
      });
    }, 4000);
  };

  return { addAlert, alerts };
};

const AlertsProvider = ({ children }) => {
  const { addAlert, alerts } = useAlerts();

  const sortedAlerts = Object.keys(alerts)
    .map(key => alerts[key])
    .sort((a, b) => a.created < b.created);

  const transitions = useTransition(sortedAlerts, alert => alert.id, {
    from: { opacity: 0, transform: 'translate3d(0, -1rem, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, -1rem, 0)' },
  });

  return (
    <>
      {transitions && transitions.length > 0 && (
        <Wrapper>
          {transitions.map(({ item: alert, props, key }) => (
            <animated.div key={key} style={props}>
              <Alert type={alert.type} heading={alert.heading}>
                {alert.content}
              </Alert>
            </animated.div>
          ))}
        </Wrapper>
      )}

      <AlertsContext.Provider value={addAlert}>
        {children}
      </AlertsContext.Provider>
    </>
  );
};

AlertsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AlertsProvider;
