import {
  addDays,
  addWeeks,
  differenceInCalendarWeeks,
  eachDayOfInterval,
  endOfWeek,
  formatDistanceToNow,
  getDay,
  startOfWeek,
} from 'date-fns';
import format from 'date-fns/format';
import { enUS, nb } from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';
import numeral from 'numeral';
import i18n from 'src/i18n';

import { getGlobalVariable } from 'model/ditioGlobal';
import { locales } from 'model/locale';

export const standardDateFormat = 'dd.MM.yyyy';
export const standardDateTimeFormat = 'dd.MM.yyyy HH:mm';

export const getLocale = () => {
  const locale = getGlobalVariable('locale');

  if (locale === locales.EN) {
    return enUS;
  }

  return nb;
};

export const localeFormat = (date: Date, dateFormat = standardDateFormat) =>
  format(date, dateFormat, { locale: getLocale() });

export const withLocale =
  <T = Date>(
    dateFunc: (date: Date, options?: any) => T
  ): ((date: Date, options?: any) => T) =>
  (date, options) => {
    const resultOptions = {
      ...options,
      locale: getLocale(),
    };

    return dateFunc(date, resultOptions);
  };

export const parseAsLocalDate = (dateString: string) => {
  const parsedDate = parseISO(dateString.replace('Z', ''));
  return parsedDate;
};

export const parseAndFormatDate = ({
  value,
  format: dateFormat = standardDateFormat,
  parseAsLocalDate: shouldParseAsLocalDate = false,
}: {
  value: string;
  format?: string;
  parseAsLocalDate?: boolean;
}) => {
  if (!value) {
    return '';
  }

  if (shouldParseAsLocalDate) {
    return localeFormat(parseAsLocalDate(value), dateFormat);
  }

  return localeFormat(parseISO(value), dateFormat);
};

export const parseAndFormatDateTime = ({
  value,
  format: dateFormat = standardDateTimeFormat,
  parseAsLocalDate: shouldParseAsLocalDate = false,
}: {
  value: string;
  format?: string;
  parseAsLocalDate?: boolean;
}) => {
  if (!value) {
    return '';
  }

  if (shouldParseAsLocalDate) {
    return localeFormat(parseAsLocalDate(value), dateFormat);
  }

  return localeFormat(parseISO(value), dateFormat);
};

export const formatStandard = (
  date: Date,
  dateFormat: string = standardDateFormat
) => localeFormat(date, dateFormat);

export const formatStandardWithTime = (
  date: Date,
  dateFormat: string = standardDateTimeFormat
) => localeFormat(date, dateFormat);

export const formatISOWithoutTime = (date: number | Date) =>
  format(date, 'yyyy-MM-dd');

export const convertFromPercentageToTime = (value: number) => {
  const last = (value % 1) * 60;
  const first = Math.trunc(value);

  return `${first}:${numeral(last).format('00')}`;
};

export const getDaysOfWeek = ({
  startOnMonday,
  sundaySuffix,
}: {
  startOnMonday?: boolean;
  sundaySuffix?: string;
}) => {
  const today = new Date();
  const daysOfWeek = eachDayOfInterval({
    start: startOfWeek(today, { weekStartsOn: startOnMonday ? 1 : 0 }),
    end: endOfWeek(today, { weekStartsOn: startOnMonday ? 1 : 0 }),
  });

  return daysOfWeek.map((day, dayIndex) =>
    sundaySuffix && dayIndex === 6
      ? `${localeFormat(day, 'iiii')} / ${sundaySuffix}`
      : localeFormat(day, 'iiii')
  );
};

export const getTimeAgo = (date: Date | string) => {
  const timePeriod = formatDistanceToNow(
    typeof date === 'string' ? parseISO(date) : date,
    { locale: getLocale() }
  );
  return `${timePeriod} ${i18n.t('General.Ago')}`;
};

export const getFirstWorkDayOfYear = (year?: number) => {
  const jan1 = new Date(year || new Date().getFullYear(), 0, 1);
  if (getDay(jan1) === 0) {
    // if 1st of January is Sunday
    return addDays(jan1, 1);
  }
  if (getDay(jan1) === 6) {
    // if 1st of January is Saturday
    return addDays(jan1, 2);
  }
  // if 1st of January is between Monday and Friday
  return jan1;
};

export const isDefaultDate = (dateString: string) =>
  dateString && dateString.startsWith('0001-01-01');

/** Returns week number of a given date */
export const getMondayWeek = (date: Date, year?: number) =>
  differenceInCalendarWeeks(date, getFirstWorkDayOfYear(year), {
    weekStartsOn: 1,
  }) + 1;

/** Returns week number of a current week */
export const getCurrentWeekNumber = () => getMondayWeek(new Date());

/** Returns a date of monday of a given week number */
export const getFirstDayOfCalendarWeek = (week: number, year?: number) =>
  addWeeks(getFirstWorkDayOfYear(year), week - 1);

/** Returns a date of sunday of a given week number */
export const getLastDayOfCalendarWeek = (week: number, year?: number) =>
  addDays(addWeeks(getFirstWorkDayOfYear(year), week - 1), 6);

export const getNumberOfWeeksInYear = (year?: number) =>
  differenceInCalendarWeeks(
    new Date(year || new Date().getFullYear(), 11, 31),
    new Date(year || new Date().getFullYear(), 0, 1),
    {
      weekStartsOn: 1,
    }
  );
