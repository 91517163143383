import React from 'react';
import { standardLinkCss } from '@ditioas/ui-styles';
import { BodyText, Heading } from '@ditioas/ui-typography';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import illustrationUrl from 'images/illustration.png';

const Root = styled.main`
  min-height: 100vh;
  position: relative;
  padding-top: 5rem;
  padding-bottom: calc(10rem + 1rem);
  text-align: center;
`;

const Illustration = styled.img`
  max-width: 1100px;
  height: 10rem;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledHeading = styled(Heading)`
  margin: 0 0 2rem;
`;

const StyledLink = styled(Link)`
  ${standardLinkCss};
`;

const LoggedOut = () => (
  <Root>
    <StyledHeading>Du er nå utlogget av Ditio</StyledHeading>
    <BodyText>
      <p>
        Gå tilbake til login ved å klikke <StyledLink to="/">her</StyledLink>
      </p>
    </BodyText>
    <Illustration src={illustrationUrl} alt="illustration" />
  </Root>
);

export default LoggedOut;
