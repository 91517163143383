import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { get } from '../../libs/apiClient';

import { activeInactiveAll } from 'model/users';
import * as types from './projectTypes';
import * as actions from './projectActions';
import { Project, ProjectTask } from '../../model/project';
import { onError, withErrorHandling, runWithTryCatch } from '../helpers';

const getProjects = companyId => {
  const url = `/api/v3/project/${companyId}`;
  return withErrorHandling(get(url).then(result => result.json()));
};

const parseProjects = data => {
  let projects = [];
  if (data) {
    projects = data.map(i => Project.fromObject(i));
  }
  return projects;
};

function* fetchProjects(params) {
  try {
    const data = yield call(getProjects, params.companyId);
    const projectList = runWithTryCatch(
      parseProjects,
      data,
      'Failed to parse response from server',
      types.GET_PROJECT_LIST
    );
    yield put(actions.getProjectsSuccessAction(projectList));
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const getTasks = projectId => {
  const url = `/api/v3/project/tasks/${projectId}?activeInactiveAll=${activeInactiveAll.ALL}`;
  return withErrorHandling(get(url).then(result => result.json()));
};

const parseTasks = data => {
  let tasks = [];
  if (data) {
    tasks = data.map(i => ProjectTask.fromObject(i));
  }
  return tasks;
};

function* fetchTasks(params) {
  try {
    const data = yield call(getTasks, params.projectId);
    const taskList = runWithTryCatch(
      parseTasks,
      data,
      'Failed to parse response from server',
      types.GET_TASK_LIST
    );
    yield put(actions.getTasksSuccessAction(taskList));
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

export default function* locationSagas() {
  yield all([
    takeEvery(types.GET_PROJECT_LIST, fetchProjects),
    takeEvery(types.GET_TASK_LIST, fetchTasks),
  ]);
}
