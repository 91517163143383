/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react';

const StatusCodeErrorContext = createContext<
  React.Dispatch<React.SetStateAction<null | number>>
>(() => {});

export const StatusCodeErrorProvider = StatusCodeErrorContext.Provider;

export const useStatusCodeErrorContext = () =>
  useContext(StatusCodeErrorContext);
