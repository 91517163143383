import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import PublicUnauthorized from 'components/PublicUnauthorized/PublicUnauthorized';
import config from 'config/site.config';
import { CODES } from 'model/error-codes';

import { StatusCodeErrorProvider } from '../../providers/status-code-error-provider';
import Forbidden from './forbidden';
import GeneralServerError from './general-server-error';
import NotFound from './not-found';

const { legacyWebBaseUrl } = config;

interface StatusCodeErrorHandlerProps {
  children: React.ReactNode;
}

const StatusCodeErrorHandler = ({ children }: StatusCodeErrorHandlerProps) => {
  const location = useLocation();
  const [statusCode, setStatusCode] = useState(null);

  const { t } = useTranslation();

  useEffect(
    () => () => {
      setStatusCode(null);
    },
    [location]
  );

  const switchByStatusCode = (code: (typeof CODES)[keyof typeof CODES]) => {
    switch (code) {
      case 401:
        return <PublicUnauthorized />;
      case 404:
        return (
          <NotFound
            heading={t('NotFoundPage.Heading')}
            description={t('NotFoundPage.Description')}
            actionButtonText={t('NotFoundPage.ActionButtonText')}
            actionButtonLink={legacyWebBaseUrl}
          />
        );
      case 403:
        return <Forbidden />;
      case 500:
      default:
        // TODO: is it REALLY default?
        return <GeneralServerError />;
    }
  };
  // TODO: heading statusCode.toString()
  return (
    <StatusCodeErrorProvider value={setStatusCode}>
      {statusCode !== null ? <>{switchByStatusCode(statusCode)}</> : children}
    </StatusCodeErrorProvider>
  );
};

export default StatusCodeErrorHandler;
