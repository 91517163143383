import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { MassTransportApi } from '../../libs/apiClient';
import { onError, withErrorHandling, runWithTryCatch } from '../helpers';

import * as types from './mapLayerTypes';
import * as actions from './mapLayerActions';
import * as notifications from '../notification/notificationActions';
import { MapLayer } from '../../model';

const getAllLayers = companyId => {
  const url = `/api/map-layer/company/${companyId}`;
  return withErrorHandling(
    MassTransportApi.get(url).then(result => result.json())
  );
};

const parseLayers = data => {
  let out = null;
  if (data && Array.isArray(data)) {
    out = data.map(l => MapLayer.fromObject(l));
  }
  return out;
};

function* fetchAllLayers(params) {
  try {
    const data = yield call(getAllLayers, params.companyId);
    // const data = [{id:1, active:true, order:20,params:{},type:'wms', api:'https://waapi.webatlas.no/maptiles/tiles/webatlas-standard-vektor/wa_grid/{z}/{x}/{y}.png?apitoken=0ad0e082-edd7-4fbb-8c86-ce7a0332de76'},{id:5, active:false, order:6,params:{},type:'norkart', api:'274678264782634876278346876'}];
    const layers = runWithTryCatch(
      parseLayers,
      data,
      'Failed to parse response from server',
      types.GET_ALL_LAYERS
    );
    yield put(actions.getAllLayersSuccessAction(layers));
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const putLayer = layer => {
  const url = `/api/map-layer/${layer.id}`;
  return withErrorHandling(
    MassTransportApi.put(url, layer).then(result => result.json())
  );
};

function* updateLayer(params) {
  try {
    const a = yield call(putLayer, params.layer);
    yield [
      put(notifications.showSuccessMessageAction('Record updated')),
      put(actions.putLayerSuccessAction(params.layer)),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const postLayer = layer => {
  const url = `/api/map-layer/`;
  return withErrorHandling(
    MassTransportApi.post(url, layer).then(result => result.json())
  );
};

const parseLayer = data => {
  let out = new MapLayer();
  if (data) {
    out = MapLayer.fromObject(data);
  } else {
    throw new Error('Unable to parse Layer');
  }
  return out;
};

function* addLayer(params) {
  try {
    const a = yield call(postLayer, params.layer);
    const layer = runWithTryCatch(
      parseLayer,
      a,
      'Failed to parse response from server',
      types.PUT_LAYER
    );
    yield [
      put(notifications.showSuccessMessageAction('New layer added')),
      put(actions.postLayerSuccessAction(layer)),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const deleteLayer = layerId => {
  const url = `/api/map-layer/${layerId}`;
  return withErrorHandling(
    MassTransportApi.del(url).then(result => result.json())
  );
};

function* delLayer(params) {
  try {
    const a = yield call(deleteLayer, params.layerId);
    yield [
      put(notifications.showSuccessMessageAction('Record deleted')),
      put(actions.deleteLayerSuccessAction(params.layerId)),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

export default function* tripLogSagas() {
  yield all([
    takeEvery(types.GET_ALL_LAYERS, fetchAllLayers),
    takeEvery(types.POST_LAYER, addLayer),
    takeEvery(types.PUT_LAYER, updateLayer),
    takeEvery(types.DELETE_LAYER, delLayer),
  ]);
}
