import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { get } from '../../libs/apiClient';

import * as types from './machineTypes';
import * as actions from './machineActions';

const getAllMachines = companyId => {
  const url = `/api/v3/machine/${companyId}`;
  return get(url).then(result => result.json());
};

function* fetchAllMachines(params) {
  try {
    const data = yield call(getAllMachines, params.companyId);
    yield put(actions.getAllMachinesSuccessAction(data));
  } catch (error) {
    yield put({ type: types.GET_ERROR, error, url: types.GET_ALL_MACHINES });
  }
}

const getAllLoaders = companyId => {
  const url = `/api/v3/machine/loaders/${companyId}`;
  return get(url).then(result => result.json());
};

function* fetchAllLoaders(params) {
  try {
    const data = yield call(getAllLoaders, params.companyId);
    yield put(actions.getAllLoadersSuccessAction(data));
  } catch (error) {
    yield put({ type: types.GET_ERROR, error, url: types.GET_ALL_LOADERS });
  }
}

const getAllDumpers = companyId => {
  const url = `/api/v3/machine/dumpers/${companyId}`;
  return get(url).then(result => result.json());
};

function* fetchAllDumpers(params) {
  try {
    const data = yield call(getAllDumpers, params.companyId);
    yield put(actions.getAllDumpersSuccessAction(data));
  } catch (error) {
    yield put({ type: types.GET_ERROR, error, url: types.GET_ALL_DUMPERS });
  }
}

export default function* nachineSagas() {
  yield all([
    takeEvery(types.GET_ALL_MACHINES, fetchAllMachines),
    takeEvery(types.GET_ALL_LOADERS, fetchAllLoaders),
    takeEvery(types.GET_ALL_DUMPERS, fetchAllDumpers),
  ]);
}
