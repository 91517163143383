const NS = 'MAP_LAYER/';

export const GET_ERROR = `${NS}GET_ERROR`;
export const GET_ALL_LAYERS = `${NS}GET_ALL_LAYERS`;
export const GET_ALL_LAYERS_SUCCESS = `${NS}GET_ALL_LAYERS_SUCCESS`;
export const POST_LAYER = `${NS}POST_LAYER`;
export const POST_LAYER_SUCCESS = `${NS}POST_LAYER_SUCCESS`;
export const PUT_LAYER = `${NS}PUT_LAYER`;
export const PUT_LAYER_SUCCESS = `${NS}PUT_LAYER_SUCCESS`;
export const DELETE_LAYER = `${NS}DELETE_LAYER`;
export const DELETE_LAYER_SUCCESS = `${NS}DELETE_LAYER_SUCCESS`;
