import React from 'react';
import { Button } from '@ditioas/ui-buttons';
import { Heading } from '@ditioas/ui-typography';
import { colors } from '@ditioas/ui-variables';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Wrapper from 'components/fullscreen-message-wrapper';
import notfoundSvgUrl from 'images/notfound.svg';

interface NotFoundProps {
  heading: string;
  description: string;
  actionButtonText: string;
  actionButtonLink: string;
}

const Description = styled.p`
  font-size: 1.25rem;
  margin: 0;
  margin-top: 1rem;
  color: ${colors.secondaryText};
`;

const NotFound = ({
  heading,
  description,
  actionButtonText,
  actionButtonLink,
}: NotFoundProps) => {
  const isExternalLink =
    actionButtonLink.startsWith('https://') ||
    actionButtonLink.startsWith('http://');

  return (
    <Wrapper>
      <Heading>{heading}</Heading>
      <Description>{description}</Description>
      <div>
        <img src={notfoundSvgUrl} role="presentation" alt="404" />
      </div>
      {isExternalLink ? (
        <Button href={actionButtonLink}>{actionButtonText}</Button>
      ) : (
        <Button component={Link} to={actionButtonLink}>
          {actionButtonText}
        </Button>
      )}
    </Wrapper>
  );
};

export default NotFound;
