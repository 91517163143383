import * as types from './projectTypes';

export const getProjectsAction = companyId => ({
  type: types.GET_PROJECT_LIST,
  companyId,
});
export const getProjectsSuccessAction = data => ({
  type: types.GET_PROJECT_LIST_SUCCESS,
  data,
});

export const getTasksAction = projectId => ({
  type: types.GET_TASK_LIST,
  projectId,
});
export const getTasksSuccessAction = data => ({
  type: types.GET_TASK_LIST_SUCCESS,
  data,
});
