import React from 'react';
import { colors } from '@ditioas/ui-variables';
import { DateRange, DateRangeProps, RangeFocus } from 'react-date-range';
import { useTranslation } from 'react-i18next';

import usePreviousValue from 'hooks/usePreviousValue';
import { getLocale } from 'libs/dateFunctions';

export interface DateRangeWithLocaleProps extends DateRangeProps {
  onEndDateSelect?: () => void;
}

const DateRangeWithLocale = ({
  onEndDateSelect,
  ...props
}: DateRangeWithLocaleProps) => {
  const { t } = useTranslation();

  const [rangeFocus, setRangeFocus] = React.useState<RangeFocus>([0, 0]);

  const prevRangeFocus = usePreviousValue(rangeFocus);

  React.useEffect(() => {
    // Resetting focused range on mount
    setRangeFocus([0, 0]);
  }, []);

  React.useEffect(() => {
    if (rangeFocus[1] === 0 && prevRangeFocus?.at(1) === 1) {
      // Happens when user selects endDate
      onEndDateSelect();
    }
  }, [rangeFocus]);

  return (
    <DateRange
      locale={getLocale()}
      rangeColors={[colors.interaction]}
      editableDateInputs
      retainEndDateOnFirstSelection
      dateDisplayFormat="dd.MM.yyyy"
      startDatePlaceholder={t('General.From')}
      endDatePlaceholder={t('General.To')}
      focusedRange={rangeFocus}
      onRangeFocusChange={(newRangeFocus) => setRangeFocus(newRangeFocus)}
      {...props}
    />
  );
};

export default DateRangeWithLocale;
