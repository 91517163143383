/* eslint-disable grouped-accessor-pairs */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable max-classes-per-file */
import intervalToDuration from 'date-fns/intervalToDuration';

import { formatStandard } from 'libs/dateFunctions';

import GeoPoint from './geo-point';

class TripsLogModel {
  constructor() {
    this._pointsMap = new Map();
    this._tripsMap = new Map();
  }

  addPoint(id, point) {
    this._pointsMap.set(id, point);
  }

  get allPoints() {
    return Array.from(this._pointsMap.values());
  }

  get allPointsAsGeoJSON() {
    return this.allPoints.map((p) => p.toGeoJSON());
  }

  get allLoadPoints() {
    return Array.from(this._pointsMap.values()).filter(
      (i) => i.properties._markType === 0
    );
  }

  get allLoadPointsAsGeoJson() {
    return this.allLoadPoints.map((p) => p.toGeoJSON());
  }

  get allDumpPoints() {
    return Array.from(this._pointsMap.values()).filter(
      (i) => i.properties._markType === 1
    );
  }

  get alllDumpPointsAsGeoJson() {
    return this.allDumpPoints.map((p) => p.toGeoJSON());
  }

  getPointById(id) {
    return this._pointsMap.get(id);
  }

  getTripIdByPointId(pointId) {}

  addTrip(trip) {
    this._tripsMap.set(trip.tripId, trip);
  }

  getTripById(tripId) {
    return this._tripsMap.get(tripId);
  }

  static fromListOfLogs(data) {
    const out = new TripsLogModel();
    if (Array.isArray(data)) {
      data.forEach((i) => {
        let loadPoint = null;
        let dumpPoint = null;

        out.addTrip(i);

        if (i.dumpCoordinates && i.loadCoordinates) {
          loadPoint = this.createPoint(
            i.tripId,
            { coordinates: i.loadCoordinates },
            0
          );
          out.addPoint(loadPoint.properties._id, loadPoint);

          dumpPoint = this.createPoint(
            i.tripId,
            { coordinates: i.dumpCoordinates },
            1
          );
          out.addPoint(dumpPoint.properties._id, dumpPoint);
        }
      });
    }
    return out;
  }

  static createPoint(id, geoData, type) {
    const out = GeoPoint.fromObject(geoData);
    if (!out.properties) {
      out.properties = {};
    }

    out.properties._markType = type;
    out.properties._tripId = id;

    if (type === 0) {
      out.properties._id = `l-${id}`;
      out.properties._partnerId = `d-${id}`;
    } else {
      out.properties._id = `d-${id}`;
      out.properties._partnerId = `l-${id}`;
    }
    return out;
  }
}

class TripLogListItem {
  constructor() {
    this._selected = false;
    this._source = null;
    this._loadLocationName = null;
    this._dumpLocationName = null;
    this._comment = null;
    this._distance = null;
  }

  set loadTime(value) {
    this._loadTime = value
      ? new Date(value.includes('Z') ? value : `${value}+02:00`)
      : null;
    this.setDuration();
  }

  set dumpTime(value) {
    this._dumpTime = value
      ? new Date(value.includes('Z') ? value : `${value}+02:00`)
      : null;
    this.setDuration();
  }

  set project(value) {
    this._project = value;
  }

  set projectNumber(value) {
    this._projectNumber = value;
  }

  set forer(value) {
    this._forer = value;
  }

  set workOrder(value) {
    this._workOrder = value;
  }

  set processCode(value) {
    this._processCode = value;
  }

  set masseType(value) {
    this._masseType = value;
  }

  set tonn(value) {
    this._tonn = value;
  }

  set unitOfMeasure(value) {
    this._unitOfMeasure = value;
  }

  set verifiedQuantity(value) {
    this._verifiedQuantity = value;
  }

  set machine(value) {
    this._machine = value;
  }

  set machineName(value) {
    this._machineName = value;
  }

  set machineNumber(value) {
    this._machineNumber = value;
  }

  set lokasjon(value) {
    this._lokasjon = value;
  }

  set tripId(value) {
    this._tripId = value;
  }

  set source(value) {
    this._source = value;
  }

  set selected(value) {
    this._selected = value;
  }

  set taskDescription(value) {
    this._taskDescription = value;
  }

  set loadLocationName(value) {
    this._loadLocationName = value;
  }

  set dumpLocationName(value) {
    this._dumpLocationname = value;
  }

  set m3(value) {
    this._m3 = value;
  }

  set comment(value) {
    this._comment = value;
  }

  set distance(value) {
    this._distance = value;
  }

  set receiptFileReferenceIds(value) {
    this._receiptFileReferenceIds = value;
  }

  set receiptNumber(value) {
    this._receiptNumber = value;
  }

  set trailer(value) {
    this._trailer = value;
  }

  set verified(value) {
    this._verified = value;
  }

  set approved(value) {
    this._approved = value;
  }

  set breaks(value) {
    this._breaks = value;
  }

  set invoiced(value) {
    this._invoiced = value;
  }

  set taskExternalId(value) {
    this._taskExternalId = value;
  }

  set costStartDateTime(value) {
    this._costStartDateTime = value;
  }

  set costEndDateTime(value) {
    this._costEndDateTime = value;
  }

  set totalCycleTimeHours(value) {
    this._totalCycleTimeHours = value;
  }

  set totalCycleTimeSeconds(value) {
    this._totalCycleTimeSeconds = value;
  }

  set tags(value) {
    this._tags = value;
  }

  set utilizationRate(value) {
    this._utilizationRate = value;
  }

  // ------GETTERS

  get loadTime() {
    return this._loadTime;
  }

  get dumpTime() {
    return this._dumpTime;
  }

  get duration() {
    return this._duration;
  }

  get durationAsString() {
    return this._duration;
  }

  get project() {
    return this._project;
  }

  get projectNumber() {
    return this._projectNumber;
  }

  get forer() {
    return this._forer;
  }

  get workOrder() {
    return this._workOrder;
  }

  get processCode() {
    return this._processCode;
  }

  get masseType() {
    return this._masseType;
  }

  get tonn() {
    return this._tonn;
  }

  get unitOfMeasure() {
    return this._unitOfMeasure;
  }

  get verifiedQuantity() {
    return this._verifiedQuantity;
  }

  get machine() {
    return this._machine;
  }

  get machineName() {
    return this._machineName;
  }

  get machineNumber() {
    return this._machineNumber;
  }

  get lokasjon() {
    return this._lokasjon;
  }

  get tripId() {
    return this._tripId;
  }

  get source() {
    return this._source;
  }

  get selected() {
    return this._selected;
  }

  get taskDescription() {
    return this._taskDescription;
  }

  get loadLocationName() {
    return this._loadLocationName;
  }

  get dumpLocationName() {
    return this._dumpLocationname;
  }

  get m3() {
    return this._m3;
  }

  get comment() {
    return this._comment;
  }

  get distance() {
    return this._distance;
  }

  get receiptFileReferenceIds() {
    return this._receiptFileReferenceIds;
  }

  get receiptNumber() {
    return this._receiptNumber;
  }

  get trailer() {
    return this._trailer;
  }

  get invoiced() {
    return this._invoiced;
  }

  get verified() {
    return this._verified;
  }

  get approved() {
    return this._approved;
  }

  get breaks() {
    return this._breaks;
  }

  get taskExternalId() {
    return this._taskExternalId;
  }

  get costStartDateTime() {
    return this._costStartDateTime;
  }

  get costEndDateTime() {
    return this._costEndDateTime;
  }

  get totalCycleTimeHours() {
    return this._totalCycleTimeHours;
  }

  get totalCycleTimeSeconds() {
    return this._totalCycleTimeSeconds;
  }

  get tags() {
    return this._tags;
  }

  get utilizationRate() {
    return this._utilizationRate;
  }

  setDuration() {
    if (this._loadTime && this._dumpTime) {
      const { days, hours, minutes } = intervalToDuration({
        start: this._loadTime,
        end: this._dumpTime,
      });

      const formattedTime = formatStandard(
        new Date(0, 0, 1, hours, minutes),
        'HH:mm'
      );

      if (days) {
        this._duration = `${days}d ${formattedTime}`;
      } else {
        this._duration = formattedTime;
      }
    } else {
      this._duration = '';
    }
  }

  static fromObject(inp) {
    const out = new TripLogListItem();
    if (inp) {
      out.source = { ...inp };
      out.loadTime = out.source.loadDateTime;
      out.dumpTime = out.source.dumpDateTime;
      out.project = out.source.projectName;
      out.projectNumber = out.source.projectNumber;
      out.forer = out.source.dumperDriverName; //--
      out.workOrder = out.source.projectNumber; //---
      out.processCode = out.source.processCode; //--
      out.masseType = out.source.massType;
      out.m3 = out.source.quantityM3; //----
      out.tonn = out.source.quantity;
      out.unitOfMeasure = out.source.unitOfMeasure;
      out.verifiedQuantity = out.source.verifiedQuantity;
      out.machine = out.source.dumperName;
      out.machineName = out.source.dumperName.replace(
        `${out.source.dumperNumber ? out.source.dumperNumber : ''}_`,
        ''
      );
      out.machineNumber = out.source.dumperNumber;
      out.lokasjon = true;
      out.taskDescription = out.source.taskDescription;
      out.loadLocationName = out.source.loadLocationName;
      out.dumpLocationName = out.source.dumpLocationName;
      out.tripId = out.source.tripId;
      out.comment = out.source.comment;
      out.distance = out.source.distance;
      out.receiptFileReferenceIds = out.source.receiptFileReferenceIds;
      out.receiptNumber = out.source.receiptNumber;
      out.trailer = out.source.trailer;
      out.verified = out.source.verified;
      out.approved = out.source.approved;
      out.breaks = out.source.breaks;
      out.invoiced = out.source.invoiced;
      out.taskExternalId = out.source.taskExternalId;
      out.costStartDateTime = out.source.costStartDateTime;
      out.costEndDateTime = out.source.costEndDateTime;
      out.totalCycleTimeHours = out.source.totalCycleTimeHours;
      out.totalCycleTimeSeconds = out.source.totalCycleTimeSeconds;
      out.tags = out.source.tags;
      out.utilizationRate = out.source.utilizationRate;
    }
    return out;
  }
}

class TripLog {
  constructor() {
    this.id = null;
    this.companyId = '';
    this.companyIds = [];
    this.projectCompanyId = '';
    this.projectId = '';
    this.taskId = '';
    this.dumperId = '';
    this.dumperDriverId = '';
    this.loaderId = '';
    this.loaderDriverId = '';
    this.trip = null;
    this.comment = '';
    this.trackingSyncedToBlob = false;
    this.financialProjectId = '';
    this.financialTaskId = '';
    this.origin = 0;
  }

  static fromObject(inp) {
    const out = new TripLog();
    out.id = inp.id;
    Object.entries(inp).forEach(([key, value]) => {
      out[key] = value;
    });
    out.companyIds = inp.companyIds ? inp.companyIds : [];
    out.trip = Trip.fromObject(inp.trip);

    return out;
  }
}

class Trip {
  constructor() {
    this._loadDateTime = null;
    this._dumpDateTime = null;
    this._loadGeoJsonPoint = null;
    this.loadGeoFenceId = '';
    this._dumpGeoJsonPoint = null;
    this.dumpGeoFenceId = '';
    this.distance = 0;
    this.averageSpeedKmh = 0;
    this.massTypeId = '';
    this.quantity = 0;
  }

  set loadDateTime(value) {
    this._loadDateTime = new Date(value);
  }

  set dumpDateTime(value) {
    this._dumpDateTime = new Date(value);
  }

  set loadGeoJsonPoint(value) {
    this._loadGeoJsonPoint = value ? GeoPoint.fromObject(value) : value;
  }

  set dumpGeoJsonPoint(value) {
    this._dumpGeoJsonPoint = value ? GeoPoint.fromObject(value) : value;
  }

  // ------GETTERS

  get loadDateTime() {
    return this._loadDateTime;
  }

  get dumpDateTime() {
    return this._dumpDateTime;
  }

  get loadGeoJsonPoint() {
    return this._loadGeoJsonPoint;
  }

  get dumpGeoJsonPoint() {
    return this._dumpGeoJsonPoint;
  }

  toJSON() {
    return {
      loadDateTime: this._loadDateTime
        ? this._loadDateTime.toISOString()
        : null,
      dumpDateTime: this._dumpDateTime
        ? this._dumpDateTime.toISOString()
        : null,
      loadGeoJsonPoint: this._loadGeoJsonPoint
        ? this._loadGeoJsonPoint.toJSON()
        : null,
      loadGeoFenceId: this.loadGeoFenceId,
      dumpGeoJsonPoint: this._dumpGeoJsonPoint
        ? this._dumpGeoJsonPoint.toJSON()
        : null,
      dumpGeoFenceId: this.dumpGeoFenceId,
      distance: this.distance,
      averageSpeedKmh: this.averageSpeedKmh,
      massTypeId: this.massTypeId,
      quantity: this.quantity,
    };
  }

  static fromObject(inp) {
    const out = new Trip();
    if (inp) {
      Object.entries(inp).forEach(([key, value]) => {
        out[key] = value;
      });
    }
    return out;
  }
}

export default TripsLogModel;
export { TripsLogModel, TripLogListItem, TripLog, Trip };
