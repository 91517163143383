import { isIOS, isSafari } from 'react-device-detect';
import { createUserManager } from 'redux-oidc';

import config from 'config/site.config';

const { oidc } = config;

const baseUrl = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

const isAd = localStorage.getItem('aad');
export const enableSilentAuth = !isIOS && !isSafari && !isAd;

const userManagerConfig = {
  authority: oidc.authority,
  client_id: 'timestyr',
  response_type: 'id_token token',
  scope: 'openid masstransportapiv1 reportingapiv1 ditioapiv3 profile',
  redirect_uri: `${baseUrl}/callback`,
  post_logout_redirect_uri: `${baseUrl}/logged-out`,
  automaticSilentRenew: enableSilentAuth,
  monitorSession: enableSilentAuth,
  silent_redirect_uri: `${baseUrl}/silent`,
};

let instance = null;

export const getUserManager = () => {
  if (instance) return instance;

  instance = createUserManager(userManagerConfig);
  return instance;
};
