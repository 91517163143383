import { all, call, put, takeEvery } from 'redux-saga/effects';

import { MassTransportApi } from '../../libs/apiClient';
import { TripLog } from '../../model/trips-log-model';
import { onError, runWithTryCatch, withErrorHandling } from '../helpers';
import * as notifications from '../notification/notificationActions';
import * as actions from './tripLogActions';
import * as types from './tripLogTypes';

const putTriplog = (tripLog) => {
  const url = `/api/trip-log/${tripLog.id}?web=true`;
  return withErrorHandling(
    MassTransportApi.put(url, tripLog).then((result) => result.json())
  );
};

function* updateTripLog(params) {
  try {
    const a = yield call(putTriplog, params.tripLog);
    yield [
      put(notifications.showSuccessMessageAction('Record updated')),
      put(actions.putTripLogSuccessAction()),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const putTripLocation = (geoPoint) => {
  const tripId = geoPoint.properties._tripId;
  const operation = geoPoint.properties._markType == 0 ? 'Load' : 'Dump';
  const url = `/api/trip-log/update-location/${tripId}/${operation}`;
  return withErrorHandling(
    MassTransportApi.patch(url, geoPoint).then((result) => result.json())
  );
};

function* updateTripLocation(params) {
  try {
    const a = yield call(putTripLocation, params.geoPoint);
    yield put(notifications.showSuccessMessageAction('Location updated'));
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const getTripLogBulk = (tripLogIds) => {
  const url = `api/trip-log/get-log-array`;
  return withErrorHandling(
    MassTransportApi.post(url, tripLogIds).then((result) => result.json())
  );
};

const parseTripLogBulk = (data) => {
  let logs = null;
  if (data) {
    logs = data.map((i) => TripLog.fromObject(i));
  }
  return logs;
};

function* fetchTripLogBulk(params) {
  try {
    const data = yield call(getTripLogBulk, params.tripLogIds);
    const tripLogs = runWithTryCatch(
      parseTripLogBulk,
      data,
      'Failed to parse response from server',
      types.GET_TRIP_LOG_BULK
    );
    yield put(actions.getTripLogBulkSuccessAction(tripLogs));
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const bulkPostTrip = (tripLogs) => {
  const url = `/api/trip-log/log-array?web=true`;
  return withErrorHandling(
    MassTransportApi.post(url, tripLogs).then((result) => result.json())
  );
};

function* bulkUpdateTrip(params) {
  try {
    const a = yield call(bulkPutTrip, params.tripLogs);
    yield [
      put(notifications.showSuccessMessageAction('Records updated')),
      put(actions.bulkPutTripLogSuccessAction()),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const bulkPutTrip = (tripLogs) => {
  const url = `/api/trip-log/log-array?web=true`;
  return withErrorHandling(
    MassTransportApi.put(url, tripLogs).then((result) => result.json())
  );
};

function* bulkCreateTrip(params) {
  try {
    const a = yield call(bulkPostTrip, params.tripLogs);
    yield [
      put(notifications.showSuccessMessageAction('New trip created')),
      put(actions.bulkPostTripLogSuccessAction()),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const bulkDelTrip = (tripIds) => {
  const url = `/api/trip-log/delete-log-array`;
  return withErrorHandling(
    MassTransportApi.post(url, tripIds).then((result) => result.json())
  );
};

function* bulkDeleteTrip(params) {
  try {
    const ids = yield call(bulkDelTrip, params.tripIds);
    yield [
      put(notifications.showSuccessMessageAction('Records deleted')),
      put(actions.bulkDeleteTripLogSuccessAction(params.tripIds)),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

export default function* tripLogSagas() {
  yield all([
    takeEvery(types.PUT_TRIP_LOG, updateTripLog),
    takeEvery(types.UPDATE_TRIP_LOCATION, updateTripLocation),
    takeEvery(types.GET_TRIP_LOG_BULK, fetchTripLogBulk),
    takeEvery(types.POST_TRIP_LOG_BULK, bulkCreateTrip),
    takeEvery(types.PUT_TRIP_LOG_BULK, bulkUpdateTrip),
    takeEvery(types.DELETE_TRIP_BULK, bulkDeleteTrip),
  ]);
}
