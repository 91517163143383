import * as types from './machineTypes';

export const getAllMachinesAction = companyId => ({
  type: types.GET_ALL_MACHINES,
  companyId,
});
export const getAllMachinesSuccessAction = data => ({
  type: types.GET_ALL_MACHINES_SUCCESS,
  data,
});

export const getAllLoadersAction = companyId => ({
  type: types.GET_ALL_LOADERS,
  companyId,
});
export const getAllLoadersSuccessAction = data => ({
  type: types.GET_ALL_LOADERS_SUCCESS,
  data,
});

export const getAllDumpersAction = companyId => ({
  type: types.GET_ALL_DUMPERS,
  companyId,
});
export const getAllDumpersSuccessAction = data => ({
  type: types.GET_ALL_DUMPERS_SUCCESS,
  data,
});
