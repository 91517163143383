import { all } from 'redux-saga/effects';

import locationSagas from './location/locationSagas';
import machineSagas from './machine/machineSagas';
import mapLayerSagas from './map-layer/mapLayerSagas';
import massTypeSagas from './mass-type/massTypeSagas';
import projectSagas from './project/projectSagas';
import reportsSagas from './reports/reportsSagas';
import tripLogSagas from './trip-log/tripLogSagas';

export default function* rootSagas() {
  yield all([
    massTypeSagas(),
    locationSagas(),
    reportsSagas(),
    projectSagas(),
    machineSagas(),
    tripLogSagas(),
    mapLayerSagas(),
  ]);
}
