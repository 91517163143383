import { useEffect } from 'react';

import { useLayoutTitleContext } from 'providers/LayoutTitleProvider';

interface Props {
  children: string;
}

const LayoutTitle = ({ children }: Props) => {
  const titleContext = useLayoutTitleContext();

  useEffect(() => {
    if (titleContext.title !== children) {
      titleContext.setTitle(children);
    }
  }, [titleContext.title, children]);

  return null;
};

export default LayoutTitle;
