import React from 'react';
import { Button as BaseButton } from '@ditioas/ui-buttons';
import { BodyText, Heading } from '@ditioas/ui-typography';
import styled from 'styled-components';

import Wrapper from 'components/fullscreen-message-wrapper';
import LayoutTitle from 'components/layout/LayoutTitle';

const Description = styled(BodyText)`
  max-width: 40rem;
  margin: 1rem auto 0 auto;
`;

const Button = styled(BaseButton)`
  margin-top: 1.5rem;
`;

interface Props {
  title?: string;
  heading: string;
  children: React.ReactNode;
  buttonHref?: string;
  onButtonClick?: () => void;
  buttonText: string;
}

const ErrorMessageScreen: React.FC<Props> = ({
  title,
  heading,
  children,
  buttonHref,
  onButtonClick,
  buttonText,
}) => (
  <Wrapper>
    <LayoutTitle>{title || heading}</LayoutTitle>
    <Heading>{heading}</Heading>
    <Description>{children}</Description>
    {buttonHref ? (
      <Button type="button" href={buttonHref}>
        {buttonText}
      </Button>
    ) : (
      <Button type="button" onClick={onButtonClick}>
        {buttonText}
      </Button>
    )}
  </Wrapper>
);

export default ErrorMessageScreen;
