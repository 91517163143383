import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { Key, SWRConfiguration } from 'swr';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import type {
  SWRInfiniteConfiguration,
  SWRInfiniteKeyLoader,
} from 'swr/infinite';
import useSWRInfinite from 'swr/infinite';

import useHandleError from 'hooks/useHandleError';
import { post } from 'libs/apiClient';
import { reduceToObjectById } from 'libs/utils';
import { CODES } from 'model/error-codes';
import { HttpStatusCode } from 'model/types';
import { useStatusCodeErrorContext } from 'providers/status-code-error-provider';

const defaultConfig = {
  errorRetryCount: 1,
  revalidateOnFocus: false,
};

export const useApi = <T>(
  url: Key,
  config?: SWRConfiguration,
  silentError?: boolean
) => {
  const { pathname } = useLocation();
  const handleError = useHandleError();
  const setStatusCode = useStatusCodeErrorContext();

  const { data, error, isLoading, ...rest } = useSWR<T>(url, {
    ...defaultConfig,
    ...config,
  });

  useEffect(() => {
    if (error) {
      if (pathname.includes('anonymous')) {
        setStatusCode(HttpStatusCode.Unauthorized);
      } else {
        handleError(error, {
          showFullscreenErrorMessage: [CODES.FORBIDDEN],
          silent: !!silentError,
        });
      }
    }
  }, [error]);

  const isFetching = isLoading;
  const isEmpty = data && Array.isArray(data) && data.length === 0;

  return {
    data,
    error,
    isFetching,
    isEmpty,
    ...rest,
  };
};

export const useApiWithDataById = <T, S = any>(
  url: Key,
  config?: SWRConfiguration,
  accessor = 'id'
) => {
  const handleError = useHandleError();

  const { data, error, isLoading, ...rest } = useSWR<T>(url, {
    ...defaultConfig,
    ...config,
  });

  const dataById = React.useMemo(() => {
    if (data && Array.isArray(data)) {
      return reduceToObjectById<S>(data as unknown as S[], accessor as keyof S);
    }

    return null;
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(error, {
        showFullscreenErrorMessage: [CODES.FORBIDDEN],
      });
    }
  }, [error]);

  const isFetching = isLoading;
  const isEmpty = data && Array.isArray(data) && data.length === 0;

  return {
    data,
    error,
    isFetching,
    isEmpty,
    dataById,
    ...rest,
  };
};

export const useApiImmutable = <T>(url: Key) => {
  const handleError = useHandleError();

  const { data, error, isLoading, ...rest } = useSWRImmutable<T>(url);

  useEffect(() => {
    if (error) {
      handleError(error, {
        showFullscreenErrorMessage: [CODES.FORBIDDEN, CODES.NOT_FOUND],
      });
    }
  }, [error]);

  const isFetching = isLoading;
  const isEmpty = data && Array.isArray(data) && data.length === 0;

  return {
    data,
    error,
    isFetching,
    isEmpty,
    ...rest,
  };
};

export const useApiImmutableWithDataById = <T, S = unknown>(
  url: Key,
  accessor = 'id'
) => {
  const handleError = useHandleError();

  const { data, error, isLoading, ...rest } = useSWRImmutable<T>(url);

  const dataById = React.useMemo(() => {
    if (data && Array.isArray(data)) {
      return reduceToObjectById<S>(data as unknown as S[], accessor as keyof S);
    }

    return null;
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(error, {
        showFullscreenErrorMessage: [CODES.FORBIDDEN],
      });
    }
  }, [error]);

  const isFetching = isLoading;
  const isEmpty = data && Array.isArray(data) && data.length === 0;

  return {
    data,
    error,
    isFetching,
    isEmpty,
    dataById,
    ...rest,
  };
};

const baseConfig: SWRConfiguration = {
  revalidateOnMount: true,
  revalidateOnFocus: false,
};

const postFetcher = (args) => post(...args).then((res) => res.json());

function usePostHandleError<T extends { errors: unknown }>(
  data: T,
  error: unknown
) {
  const handleError = useHandleError();

  useEffect(() => {
    if (data?.errors) {
      handleError(data);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);
}

export const usePostSWRFetcher = <T, P = unknown>(
  url: string | null,
  params: P,
  customConfig?: SWRConfiguration
) => {
  const { data, error, isLoading, ...rest } = useSWR<T>(
    url && params ? [url, params] : null,
    postFetcher,
    {
      ...baseConfig,
      ...customConfig,
    }
  );

  usePostHandleError(data as unknown as { errors: unknown }, error);

  const isFetching = isLoading;

  return {
    isFetching,
    data,
    error,
    ...rest,
  };
};

export const usePostSWRInfiniteFetcher = <T>(
  keyLoader: SWRInfiniteKeyLoader,
  customConfig?: SWRInfiniteConfiguration
) => {
  const { data, error, isLoading, ...rest } = useSWRInfinite<T>(
    keyLoader,
    postFetcher,
    {
      revalidateFirstPage: false,
      ...customConfig,
    }
  );

  usePostHandleError(data as unknown as { errors: any }, error);

  const isFetching = isLoading;

  return {
    isFetching,
    data,
    error,
    ...rest,
  };
};
