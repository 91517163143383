import { createSelector } from 'reselect';

import { getTripLogState } from '../selectors';
import * as types from './tripLogTypes';

const initialState = {
  allTripLogs: [],
  genericError: {},
  shouldRefreshTripList: false,
  bulkEditLogs: [],
  dataIsLoading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_TRIP_LOG_BULK:
      return {
        ...state,
        bulkEditLogs: [],
      };
    case types.GET_TRIP_LOG_BULK_SUCCESS:
      return {
        ...state,
        bulkEditLogs: action.data,
        shouldRefreshTripList: false,
      };

    case types.POST_TRIP_LOG_BULK:
    case types.PUT_TRIP_LOG_BULK:
    case types.DELETE_TRIP_BULK:
    case types.PUT_TRIP_LOG:
      return { ...state, dataIsLoading: true, shouldRefreshTripList: false };

    case types.POST_TRIP_LOG_BULK_SUCCESS:
    case types.PUT_TRIP_LOG_BULK_SUCCESS:
    case types.PUT_TRIP_LOG_SUCCESS:
      return { ...state, dataIsLoading: false, shouldRefreshTripList: true };

    case types.DELETE_TRIP_BULK_SUCCESS:
      return {
        ...state,
        dataIsLoading: false,
        shouldRefreshTripList: false,
      };
    case types.GET_ERROR:
      return {
        ...state,
        genericError: {
          errorMsg: action.error,
          url: action.url,
          type: action.type,
        },
      };
    default:
      return state;
  }
}

export const getShouldRefreshTripList = createSelector(
  getTripLogState,
  (state) => state.shouldRefreshTripList
);
export const getGenericError = createSelector(getTripLogState, (state) =>
  state ? state.genericError : null
);
export const getBulkEditLogs = createSelector(
  getTripLogState,
  (state) => state.bulkEditLogs
);
export const getDataIsLoading = createSelector(
  getTripLogState,
  (state) => state.dataIsLoading
);
