import React from 'react';
import { showToast } from '@ditioas/web-ui';
import { formatISO, startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import type { AlertFormShape } from 'src/containers/Alerts/Alert/utils';
import useAlertsFilters from 'src/containers/Alerts/AlertsList/AlertsFilters/useAlertsFilters';

import { useApi, usePostSWRFetcher } from 'data/base-api';
import useHandleError from 'hooks/useHandleError';
import { checkStatus, del, patch, post, put } from 'libs/apiClient';
import type {
  AlertsProjectsResponse,
  AssignedDetails,
  CompanyAlert,
  CompanyAlertType,
  CompanySettings,
  FormCompanyAlertType,
  HMSRegAlertType,
  MachineAlert,
  NewProjectAlertType,
  ProjectAlertType,
  ProjectSettings,
  SeverityLevel,
} from 'model/Alerts/types';
import { ProjectsAlertsTabs } from 'model/Alerts/types';

const baseUrl = '/api/v2/proj-notifications';

export const useGetAlertsProjects = (revalidateOnMount = true) =>
  useApi<AlertsProjectsResponse>(`${baseUrl}/projects`, { revalidateOnMount });

export const useGetCompanyAlertTypes = (
  revalidateOnMount = true,
  excludeMachineTypes = false,
  skip = false
) => {
  const { data: companyAlertTypes, ...rest } = useApi<CompanyAlertType[]>(
    skip
      ? null
      : `${baseUrl}/types-company${excludeMachineTypes ? '?excludeMachine=true' : ''}`,
    { revalidateOnMount }
  );

  return { companyAlertTypes, ...rest };
};

export const useGetMachineAlertTypes = (
  machineId: string,
  revalidateOnMount = true,
  skip = false
) =>
  useApi<ProjectAlertType[]>(
    // eslint-disable-next-line no-nested-ternary
    !skip
      ? machineId
        ? `${baseUrl}/types/machine?machineId=${machineId}`
        : `${baseUrl}/types/machine`
      : null,
    { revalidateOnMount }
  );

export const useGetHMSRegAlertTypes = (
  customerId: string | null,
  skip = false,
  revalidateOnMount = true
) => {
  const { data: hmsRegAlertTypes, ...rest } = usePostSWRFetcher<
    HMSRegAlertType[]
  >(
    !customerId || skip ? null : `${baseUrl}/notifications/external-types`,
    {
      CustomerID: customerId,
    },
    { revalidateOnMount }
  );

  return { hmsRegAlertTypes, ...rest };
};

export const useCreateCompanyAlertType = () => {
  const handleError = useHandleError();

  return async (
    newCompanyAlertType: FormCompanyAlertType
  ): Promise<CompanyAlertType | null> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/types`, newCompanyAlertType)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateCompanyAlertType = () => {
  const handleError = useHandleError();

  return async (
    newCompanyAlertType: FormCompanyAlertType
  ): Promise<CompanyAlertType | null> => {
    try {
      const response = checkStatus(
        await put(`${baseUrl}/types`, newCompanyAlertType)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useDeleteCompanyAlertType = () => {
  const handleError = useHandleError();

  return async (companyTypeId: string): Promise<void> => {
    try {
      checkStatus(await del(`${baseUrl}/types/${companyTypeId}`));
    } catch (e) {
      handleError(e);
      throw new Error();
    }
  };
};

export const useGetCompanySettings = () => {
  const { data: companySettings, ...rest } = useApi<CompanySettings>(
    `${baseUrl}/company-settings`,
    { errorRetryCount: 0 },
    true
  );

  return { companySettings, ...rest };
};

export const useCreateCompanySettings = () => {
  const handleError = useHandleError();

  return async (
    newCompanySettings: Pick<CompanySettings, 'receiversOfAllNotifications'>
  ): Promise<CompanySettings | null> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/company-settings`, newCompanySettings)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateCompanySettings = () => {
  const handleError = useHandleError();

  return async (
    newCompanySettings: CompanySettings
  ): Promise<CompanySettings | null> => {
    try {
      const response = checkStatus(
        await patch(`${baseUrl}/company-settings`, newCompanySettings)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useGetProjectAlertTypes = (
  projectId: string,
  includeInactive = true,
  revalidateOnMount = true,
  skip = false
) => {
  const { data: projectAlertTypes, ...rest } = useApi<ProjectAlertType[]>(
    !skip && projectId
      ? `${baseUrl}/types/${projectId}?includeInactive=${includeInactive}&includeTypesNotAvailableOnApp=true`
      : null,
    { revalidateOnMount },
    true
  );

  return { projectAlertTypes, ...rest };
};

export const useCreateProjectAlertType = () => {
  const handleError = useHandleError();

  return async (
    newProjectAlertType: NewProjectAlertType
  ): Promise<Omit<
    NewProjectAlertType,
    'projectNotificationTypeParentId'
  > | null> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/types-project`, newProjectAlertType)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateProjectAlertType = () => {
  const handleError = useHandleError();

  return async (
    newProjectAlertType: NewProjectAlertType
  ): Promise<Omit<
    NewProjectAlertType,
    'projectNotificationTypeParentId'
  > | null> => {
    try {
      const response = checkStatus(
        await put(`${baseUrl}/types-project`, newProjectAlertType)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

interface Filters {
  projectIds?: string[];
  status?: ProjectsAlertsTabs;
  fromDate?: string;
  toDate?: string;
  risk?: SeverityLevel;
  typeId?: string;
  assignedToId?: string;
  createdById?: string;
  categoryId?: string;
  relatedToId?: string;
  causeId?: string;
  involvedPartiesId?: string;
  requiresFurtherAction?: boolean;
  machineId?: string;
}

export const useGetProjectSettings = (projectId: string) => {
  const { data: projectSettings, ...rest } = useApi<ProjectSettings>(
    projectId ? `${baseUrl}/project-settings/${projectId}` : null,
    { errorRetryCount: 0 },
    true
  );

  return { projectSettings, ...rest };
};

export const useCreateProjectSettings = () => {
  const handleError = useHandleError();

  return async (
    newProjectSettings: Pick<
      ProjectSettings,
      'projectId' | 'receiversOfAllNotifications'
    >
  ): Promise<ProjectSettings | null> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/project-settings`, newProjectSettings)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateProjectSettings = () => {
  const handleError = useHandleError();

  return async (
    newProjectSettings: ProjectSettings
  ): Promise<ProjectSettings | null> => {
    try {
      const response = checkStatus(
        await patch(`${baseUrl}/project-settings`, newProjectSettings)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useGetRelevantAlerts = (onlyAssignedToCurrentUser?: boolean) => {
  const alertFilters = useAlertsFilters();
  const {
    alertsForCurrentUser,
    alertsForMachines,
    projectIds,
    status,
    fromDate,
    toDate,
    assignedToId,
    submittedById,
    resourceId,
    machineId,
    needsFollowUp,
    risk,
    typeId,
    categoryId,
    relatedToId,
    causeId,
    involvedPartiesId,
  } = alertFilters;

  const filters = React.useMemo(() => {
    const newFilters: Filters = {
      projectIds,
      status: status !== ProjectsAlertsTabs.All ? status : undefined,
      fromDate:
        fromDate && toDate ? formatISO(startOfDay(fromDate)) : undefined,
      toDate: fromDate && toDate ? formatISO(startOfDay(toDate)) : undefined,
      assignedToId,
      createdById: submittedById,
      machineId: resourceId || machineId,
      requiresFurtherAction: needsFollowUp,
      risk,
      typeId,
      categoryId,
      relatedToId,
      causeId,
      involvedPartiesId,
      onlyAssignedToCurrentUser,
    };

    return newFilters;
  }, [alertFilters, alertsForCurrentUser, alertsForMachines]);

  return usePostSWRFetcher<CompanyAlert[] | MachineAlert[]>(
    alertsForMachines
      ? `${baseUrl}/notifications/machines`
      : `${baseUrl}/notifications/list`,
    filters
  );
};

export const useCreateAlert = () => {
  const handleError = useHandleError();

  return async (
    newAlert: Partial<AlertFormShape | CompanyAlert>
  ): Promise<CompanyAlert | null> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/notifications`, newAlert)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useUpdateAlert = () => {
  const handleError = useHandleError();

  return async (newAlert: CompanyAlert): Promise<CompanyAlert | null> => {
    try {
      const response = checkStatus(
        await put(`${baseUrl}/notifications`, newAlert)
      );

      const parsed = await response.json();
      return parsed;
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const useDeleteAlert = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();

  return async (alertId: string): Promise<void> => {
    try {
      checkStatus(await del(`${baseUrl}/notification/${alertId}`));

      showToast(t('Alerts.AlertsDeleted', { count: 1 }));
    } catch (e) {
      handleError(e);
    }
  };
};

export const useBulkDeleteAlerts = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();

  return async (notificationIds: string[]): Promise<void> => {
    try {
      checkStatus(
        await del(`${baseUrl}/notifications/bulk-delete`, { notificationIds })
      );

      showToast(t('Alerts.AlertsDeleted', { count: notificationIds.length }));
    } catch (e) {
      handleError(e);
    }
  };
};

export const useGetAlertById = (
  alertId?: string,
  skip = false,
  revalidateOnMount = true
) => {
  const { data, ...rest } = useApi<CompanyAlert>(
    alertId && !skip ? `${baseUrl}/notification/${alertId}` : null,
    { revalidateOnMount }
  );

  return {
    alert: data,
    ...rest,
  };
};

export const useGetPublicAlertById = (
  alertId: string | undefined,
  xai: string | undefined,
  xat: string | undefined
) => {
  const params = new URLSearchParams();
  if (xai) params.set('xai', xai);
  if (xat) params.set('xat', xat);

  const { data, ...rest } = useApi<CompanyAlert>(
    alertId && xai && xat
      ? `${baseUrl}/public-notification/${alertId}?${params}`
      : null
  );

  return {
    alert: data,
    ...rest,
  };
};

export const useAssignAlert = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();

  return async (
    newAssignedTo: AssignedDetails,
    userName: string
  ): Promise<CompanyAlert[]> => {
    try {
      const response = checkStatus(
        await post(`${baseUrl}/notifications/assign`, newAssignedTo)
      );

      showToast(
        t('Alerts.AssignedTo', {
          count: newAssignedTo.notificationIds.length,
          name: userName,
        })
      );

      return await response.json();
    } catch (e) {
      handleError(e);
      return null;
    }
  };
};

export const downloadAlertsCSV = async (
  projectIds: string[],
  notificationIds: string[],
  fromDate?: string | null,
  toDate?: string | null,
  userId?: string
): Promise<Blob> => {
  const response = await post(`${baseUrl}/notifications/download`, {
    notificationIds,
    fromDate: fromDate?.length ? fromDate : undefined,
    toDate: toDate?.length ? toDate : undefined,
    ...(userId ? { assignedToId: userId } : { projectIds }),
  });
  return response.blob();
};

export const useSetReadyForIntegration = () => {
  const handleError = useHandleError();

  return async (
    alertIds: string[],
    hmsRegType: string | null,
    feedbackToUser: string
  ) => {
    try {
      checkStatus(
        await patch(`${baseUrl}/notifications/ready-for-integration`, {
          notificationIds: alertIds,
          hmsRegType,
          feedbackToUser,
        })
      );
    } catch (e) {
      handleError(e);
    }
  };
};

export const useResolveAlert = () => {
  const handleError = useHandleError();

  return async (
    alertId: string,
    feedbackToUser: string
  ): Promise<CompanyAlert[] | null> => {
    try {
      const response = checkStatus(
        await patch(`${baseUrl}/notifications/resolve`, {
          notificationIds: [alertId],
          feedbackToUser,
        })
      );

      return await response.json();
    } catch (e) {
      handleError(e);

      return null;
    }
  };
};
