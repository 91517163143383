import * as React from 'react';
import { Maximize, Minimize } from '@carbon/icons-react';
import { Popover } from '@ditioas/ui-dialogs';
import { Text } from '@ditioas/ui-typography';
import { Button } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageWrapper } from 'components/common-styles';
import DateRangeWithPresets from 'components/DateRangeWithPresets/DateRangeWithPresets';
import { SaveOverlay } from 'components/loaders';
import Select from 'components/Select/Select';
import useCurrentUserAndSettings from 'hooks/useCurrentUserAndSettings';
import useObjectSearchParams from 'hooks/useObjectSearchParams';
import { formatStandard } from 'libs/dateFunctions';

import { useStatistics } from '../apiHooks';
import Filters from '../Filters/Filters';
import type { DashboardProps, Statistics } from '../types';
import { Granularity } from '../types';
import {
  firstLetterCapitalized,
  formatData,
  getOptions,
  plugins,
  useGetDate,
} from '../utils';

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
`;

const StyledGraph = styled.a<{ $expand: boolean }>`
  grid-column: ${({ $expand }) => ($expand ? `1/3` : '')};
  margin-bottom: 4rem;
  height: ${({ $expand }) => ($expand ? `600px` : `300px`)};
  width: 95%;
`;

const StyledGraphs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  justify-items: center;
  position: relative;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
`;

const StyledText = styled(Text)`
  margin-left: auto;
  margin-right: -5rem;
`;
const StyledDateButton = styled(Button)`
  min-height: 2.5rem;
`;

const DateRangeContainer = styled.div<{ $isPublic: boolean }>`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
  min-width: 21rem;
  padding: 2rem 2rem 0 0;
  margin-bottom: ${({ $isPublic }) => ($isPublic ? `2rem` : `0`)};
`;

const dateRangeKey = 'selection';
const intervalOptions = Object.keys(Granularity);

const Dashboard = ({ isPublic }: DashboardProps) => {
  const { startDate, endDate } = useGetDate(isPublic);
  const initialDates = {
    startDate,
    endDate,
    key: dateRangeKey,
  };

  const { t } = useTranslation();
  const { currentUser, isSuperUserPlus } = useCurrentUserAndSettings();

  const rangeInputAnchor = React.useRef(null);

  const [graphData, setGraphData] = React.useState({});
  const [isRangePickerOpen, setIsRangePickerOpen] = React.useState(false);
  const [chart, setChart] = React.useState<string>('');
  const [options, setOptions] = React.useState(getOptions());
  const { xai, xat } = useObjectSearchParams<{ xai?: string; xat?: string }>();
  const [filters, setFilters] = React.useState<Statistics>({
    dateRange: initialDates,
    companies: currentUser?.sysOp
      ? []
      : [{ id: currentUser?.companyId, name: currentUser?.companyName }],
    projects: [],
    granularity: isPublic ? 'Month' : 'Day',
    metrics: ['ActiveUsers', 'RegisteredHours', 'Transactions', 'Images'],
  });

  const { data, isFetching } = useStatistics(filters, isPublic, xat, xai);

  React.useEffect(() => {
    if (!isFetching) {
      setGraphData(formatData(data));

      setOptions(getOptions());
    }
  }, [data, t, isFetching]);

  React.useEffect(() => {
    if (chart !== '')
      document.getElementById(chart).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  }, [chart]);

  const handleExpand = (e: string) => {
    if (chart === e) setChart('');
    else setChart(e);
  };

  const handleDataGranularity = (param: string) => {
    setFilters((filter) => ({ ...filter, granularity: param }));
  };

  return (
    <PageWrapper allowScrolling onlyBottomPadding>
      <DateRangeContainer $isPublic={isPublic || isSuperUserPlus}>
        <Text noMargin>{`${t('General.FilterByDateRange')}:`}</Text>
        <StyledDateButton
          ref={rangeInputAnchor}
          variant="outlined"
          onClick={() => setIsRangePickerOpen(true)}
        >
          {`${formatStandard(filters.dateRange.startDate)} - ${formatStandard(
            filters.dateRange.endDate
          )}`}
        </StyledDateButton>
        <Select
          options={intervalOptions}
          value={filters.granularity}
          onChange={(e) => handleDataGranularity(e.target.value)}
          label={t('DataGraph.Granularity')}
          size="small"
        />
      </DateRangeContainer>
      {!isPublic && isSuperUserPlus && (
        <Filters setFilters={setFilters} filters={filters} />
      )}
      <Popover
        anchorEl={rangeInputAnchor.current}
        open={isRangePickerOpen}
        onClose={() => setIsRangePickerOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          horizontal: 'left',
        }}
      >
        <DateRangeWithPresets
          weekStartsOn={1}
          months={2}
          maxDate={endDate}
          scroll={{ enabled: true }}
          ranges={[filters.dateRange]}
          onChange={(ranges) =>
            setFilters((filter) => ({
              ...filter,
              dateRange: ranges.selection,
            }))
          }
          onEndDateSelect={() => setIsRangePickerOpen(false)}
          setNewDates={(newStartDate, newEndDate) => {
            setFilters((filter) => ({
              ...filter,
              dateRange: {
                startDate: newStartDate,
                endDate: newEndDate,
                key: dateRangeKey,
              },
            }));
          }}
        />
      </Popover>
      <StyledGraphs>
        {isFetching && <SaveOverlay $alignToTop />}
        {Object.keys(graphData).map((key) => (
          <StyledGraph id={key} key={key} $expand={key === chart}>
            <StyledTitle>
              <StyledText noMargin>
                {t(
                  `DataGraph.${firstLetterCapitalized(
                    key
                  )}` as 'DataGraph.ActiveCompanies'
                )}
              </StyledText>
              <StyledButton onClick={() => handleExpand(key)}>
                {key === chart ? (
                  <Minimize size={20} />
                ) : (
                  <Maximize size={20} />
                )}
              </StyledButton>
            </StyledTitle>
            <Line options={options} data={graphData[key]} plugins={plugins} />
          </StyledGraph>
        ))}
      </StyledGraphs>
    </PageWrapper>
  );
};

export default Dashboard;
