export interface UserToNotify {
  id: string;
  name: string;
  email: string;
}

export interface OverviewCertificate {
  description: string;
  /** UTC Z */
  expiryDate: string;
  expireWarning: number;
  id?: string;
  mandatory: boolean;
  numberOfUsers: number;
  typeId: string;
  usersToNotify: string[];
  usersToNotifyList: UserToNotify[];
}

export interface CompanyCertificateSettings {
  companyId: string;
  usersToNotify: string[];
  usersToNotifyList: UserToNotify[];
}

export interface CertificateType {
  id?: string;
  companyId?: string;
  recVersion?: number;
  companyIds?: string[];
  typeId: string;
  description: string;
  mandatory: boolean;
  issuer?: string;
  issuerWebSite?: string;
  priority?: number;
  expireWarning: number;
  usersToNotify: string[];
  usersToNotifyList: UserToNotify[];
}

export interface UserCertificate {
  id: string;
  user: {
    companyProfileId: string;
    id: string;
    name: string;
  };
  typeGuid: string;
  typeId: string;
  /** UTC Z */
  issued: string;
  notes: string;
  /** UTC Z */
  validTo: string;
  numberOfDocuments: number;
  /** UTC Z */
  expiryNotificationDate: string;
  isEditable: boolean;
}

export enum SortingField {
  Name,
  Type,
  Issued,
  ValidTo,
  Documents,
  Expiry,
  Notes,
}
