import { AutocompleteOption } from 'components/ReactHookForm/Autocomplete/Autocomplete';

import { StatisticsResponse } from './types';

export const firstLetterCapitalized = (param: string) =>
  param.charAt(0).toUpperCase() + param.slice(1);

export const formatData = (param: StatisticsResponse) => {
  const obj = {};
  let labels = [];
  let data = [];

  Object.keys(param)?.forEach((key) => {
    param[key]?.forEach((value) => {
      labels.push(value.label);
      data.push(value.value);
    });

    obj[key] = {
      labels,
      datasets: [
        {
          data,
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };

    labels = [];
    data = [];
  });

  return obj;
};

export const getIds = (param: AutocompleteOption[]) =>
  param.map((item) => item.id);

export const useGetDate = (isPublic = false) => {
  if (isPublic)
    return {
      startDate: new Date(2020, 0, 1),
      endDate: new Date(2023, 6, 31),
    };

  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 60);
  return {
    startDate,
    endDate: new Date(),
  };
};

export const getOptions = () => ({
  radius: 0,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxTicksLimit: 20,
      },
    },
    y: {
      min: 0,
      ticks: {
        precision: 0,
      },
    },
  },
  interaction: {
    intersect: false,
    mode: 'index' as const,
  },
});

export const plugins = [
  {
    id: '',
    afterDraw: (chart) => {
      // eslint-disable-next-line no-underscore-dangle
      if (chart?.tooltip?._active && chart.tooltip._active.length) {
        // eslint-disable-next-line no-underscore-dangle
        const activePoint = chart.tooltip._active[0];
        const { ctx } = chart;
        const { x } = activePoint.element;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#1C2128';
        ctx.stroke();
        ctx.restore();
      }
    },
  },
];
