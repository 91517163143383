import React from 'react';
import { Button } from '@ditioas/ui-buttons';
import { BodyText, Heading } from '@ditioas/ui-typography';
import { colors } from '@ditioas/ui-variables';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';

interface ErrorScreenProps {
  heading?: string;
  description?: string;
  reportSent?: boolean;
  fullscreen?: boolean;
}

const fullscreenCss = css`
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
`;

const fullscreenContentCss = css`
  background: ${colors.white};
  padding: 4rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.24);
`;

const Wrapper = styled.section<{ $fullscreen: boolean }>`
  padding: 3rem 2rem;
  text-align: center;

  ${({ $fullscreen }) => $fullscreen && fullscreenCss};
`;

const Content = styled.div<{ $fullscreen: boolean }>`
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;

  ${({ $fullscreen }) => $fullscreen && fullscreenContentCss};
`;

const fadeButton = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  100% {
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 1rem;
`;

const exitCss = css`
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-name: ${fadeButton};
`;

const StyledButton = styled(Button)<{ $exitable: boolean }>`
  display: ${(props) => (props.hidden ? 'none' : undefined)};

  ${({ $exitable }) => $exitable && exitCss};
`;

const ErrorScreen = ({
  heading,
  description,
  reportSent = false,
  fullscreen = false,
}: ErrorScreenProps) => {
  const { t } = useTranslation();
  let descriptionView = null;

  if (!description) {
    descriptionView = (
      <>
        <p>
          {!reportSent
            ? t('T.Sending_report')
            : t('T.Report_sent_Please_try_to_reload')}
        </p>
        <StyledButton
          hidden={!reportSent}
          $exitable={reportSent}
          onClick={() => {
            window.location.reload();
          }}
        >
          {t('Btn.Restart_app')}
        </StyledButton>
      </>
    );
  } else {
    descriptionView = <p>{description}</p>;
  }

  return (
    <Wrapper $fullscreen={fullscreen}>
      <Content $fullscreen={fullscreen}>
        <StyledHeading styleLevel={2}>
          {heading !== null ? heading : t('Title.Module_crashed')}
        </StyledHeading>
        <BodyText>{descriptionView}</BodyText>
      </Content>
    </Wrapper>
  );
};

export default ErrorScreen;
