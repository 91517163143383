import * as types from './tripLogTypes';

export const putTripLogAction = tripLog => ({
  type: types.PUT_TRIP_LOG,
  tripLog,
});
export const putTripLogSuccessAction = () => ({
  type: types.PUT_TRIP_LOG_SUCCESS,
});

export const updateTripLocationAction = geoPoint => ({
  type: types.UPDATE_TRIP_LOCATION,
  geoPoint,
});

export const getTripLogBulkAction = tripLogIds => ({
  type: types.GET_TRIP_LOG_BULK,
  tripLogIds,
});
export const getTripLogBulkSuccessAction = data => ({
  type: types.GET_TRIP_LOG_BULK_SUCCESS,
  data,
});

export const bulkPostTripLogAction = tripLogs => ({
  type: types.POST_TRIP_LOG_BULK,
  tripLogs,
});
export const bulkPostTripLogSuccessAction = tripLogIds => ({
  type: types.POST_TRIP_LOG_BULK_SUCCESS,
});

export const bulkPutTripLogAction = tripLogs => ({
  type: types.PUT_TRIP_LOG_BULK,
  tripLogs,
});
export const bulkPutTripLogSuccessAction = tripLogIds => ({
  type: types.PUT_TRIP_LOG_BULK_SUCCESS,
});

export const bulkDeleteTripLogAction = tripIds => ({
  type: types.DELETE_TRIP_BULK,
  tripIds,
});
export const bulkDeleteTripLogSuccessAction = tripLogIds => ({
  type: types.DELETE_TRIP_BULK_SUCCESS,
  tripLogIds,
});
