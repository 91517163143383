export const getTypeOptions = (type, displayFunction) =>
  Object.keys(type).map((key) => {
    const value = type[key];
    const display = displayFunction(value);

    return {
      value,
      display,
    };
  });
