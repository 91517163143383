class GeoPoint {
  constructor() {
    this._coordinates = [];
    this._accuracy = 0;
    this._type = 'Point';
    this._properties = new Object();
  }

  set coordinates(value) {
    this._coordinates = value.map(v => parseFloat(v));
  }

  set accuracy(value) {
    this._accuracy = value || 0;
  }

  set properties(value) {
    this._properties = { ...value };
  }

  // ----------GETTERS

  get coordinates() {
    return this._coordinates;
  }

  get accuracy() {
    return this._accuracy;
  }

  get type() {
    return this._type;
  }

  get properties() {
    return this._properties;
  }

  toJSON() {
    return {
      coordinates: this._coordinates,
      accuracy: this._accuracy,
      type: this._type,
    };
  }

  toGeoJSON() {
    return {
      type: 'Feature',
      properties: this._properties,
      geometry: {
        coordinates: this._coordinates,
        type: this._type,
      },
    };
  }

  static fromObject(obj) {
    const o = new GeoPoint();
    o.coordinates = obj.coordinates;
    o.accuracy = obj.accuracy;
    o.properties = obj.properties;
    return o;
  }
}

export default GeoPoint;
