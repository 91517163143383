import 'whatwg-fetch';
import React from 'react';
import { StylesProvider } from '@ditioas/ui-styles';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';

import config from 'config/site.config';

import AppWrapper from './appWrapper';
import configureStore from './configureStore';
import { initI18n } from './i18n';
import { addCustomValidationRules } from './libs/formFunctions';
import sagas from './store/sagas';

addCustomValidationRules();
/*
  Init Sentry
 */
if (import.meta.env.VITE_IS_ON_SERVER === 'true') {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: import.meta.env.VITE_NODE_ENV,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

const tagManagerArgs = {
  gtmId: 'GTM-K2BKZ55',
};

if (
  import.meta.env.VITE_NODE_ENV === 'production' &&
  import.meta.env.VITE_IS_ON_SERVER !== 'true'
) {
  tagManagerArgs.auth = 'RrseJifR84Opks_fwjm6YA';
  tagManagerArgs.preview = 'env-6';
} else {
  tagManagerArgs.auth = 'pNlh_OlDpT2YEOU0mbAEGQ';
  tagManagerArgs.preview = 'env-1';
}

if (config.mixpanelToken) {
  mixpanel.init(config.mixpanelToken, {
    debug: false,
    track_pageview: false,
    persistence: 'localStorage',
  });
}

TagManager.initialize(tagManagerArgs);

/*
  Load and init translations
*/
(async () => {
  await initI18n();

  /*
    redux/ store init
  */
  const store = configureStore();
  store.runSaga(sagas);

  createRoot(document.getElementById('root')).render(
    <Provider store={store}>
      <StylesProvider>
        <AppWrapper store={store} />
      </StylesProvider>
    </Provider>
  );
})();
