import React from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useSetDeleteURLParams<T>(dontReplace = false) {
  const [_, setSearchParams] = useSearchParams();

  const setURLParam = React.useCallback(
    (name: keyof T, value: string) => {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);
          newParams.set(name as string, value);

          return newParams;
        },
        { replace: !dontReplace }
      );
    },
    [setSearchParams]
  );

  const deleteURLParam = React.useCallback(
    (name: keyof T) => {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);
          newParams.delete(name as string);

          return newParams;
        },
        { replace: !dontReplace }
      );
    },
    [setSearchParams]
  );

  const setMultipleURLParams = React.useCallback(
    (
      paramsObject: { [Property in keyof T]: string | null },
      replace = true
    ) => {
      setSearchParams(
        (params) => {
          const newParams = replace
            ? new URLSearchParams()
            : new URLSearchParams(params);
          Object.entries<string | null>(paramsObject).forEach(
            ([name, value]) => {
              if (value) {
                newParams.set(name as string, value);
              } else {
                newParams.delete(name as string);
              }
            }
          );

          return newParams;
        },
        { replace: !dontReplace }
      );
    },
    [setSearchParams]
  );

  const deleteMultipleURLParams = React.useCallback(
    (paramsToDelete: (keyof T)[]) => {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);
          paramsToDelete.forEach((paramToDelete) =>
            newParams.delete(paramToDelete as string)
          );

          return newParams;
        },
        { replace: !dontReplace }
      );
    },
    [setSearchParams]
  );

  const setAndDeleteURLParams = React.useCallback(
    ({
      paramsToDelete,
      paramsObject,
    }: {
      paramsToDelete: (keyof T)[];
      paramsObject: { [Property in keyof T]: string };
    }) => {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);
          paramsToDelete.forEach((paramToDelete) =>
            newParams.delete(paramToDelete as string)
          );
          Object.entries<string>(paramsObject).forEach(([name, value]) =>
            newParams.set(name, value)
          );

          return newParams;
        },
        { replace: !dontReplace }
      );
    },
    [setSearchParams]
  );

  return {
    setURLParam,
    deleteURLParam,
    setMultipleURLParams,
    deleteMultipleURLParams,
    setAndDeleteURLParams,
  };
}
