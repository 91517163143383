import React from 'react';
import { colors, constants } from '@ditioas/ui-variables';
import styled from 'styled-components';

const OptionWrapper = styled.div`
  padding: 0 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
`;

const OptionName = styled.span`
  display: block;
`;

const SecondaryContent = styled.span`
  display: block;
  color: ${colors.secondaryText};
  font-size: ${constants.bodySmallTextFontSize};
`;

interface Props {
  children: string;
  secondary?: string;
  tertiary?: string;
}

const AutocompleteOption = ({
  children,
  secondary,
  tertiary,
  ...rest
}: Props) => (
  <OptionWrapper {...rest}>
    <OptionName>{children}</OptionName>
    {secondary && <SecondaryContent>{secondary}</SecondaryContent>}
    {tertiary && <SecondaryContent>{tertiary}</SecondaryContent>}
  </OptionWrapper>
);

export default AutocompleteOption;
