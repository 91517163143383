class Project {
  constructor() {
    this._id = null;
    this._name = null;
    this._projNumber = null;
  }

  set id(value) {
    this._id = value;
  }

  set name(value) {
    this._name = value;
  }

  set projNumber(value) {
    this._projNumber = value;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get projNumber() {
    return this._projNumber;
  }

  static fromObject(obj) {
    const o = new Project();
    o.id = obj.id;
    o.name = obj.name;
    o.projNumber = obj.projNumber;

    return o;
  }
}

//---------------------------------------------

class ProjectTask {
  constructor() {
    this._id = null;
    this._name = null;
    this._chapterId = null;
    this._externalId = null;
    this._breadcrumb = null;
    this._descriptionWithNumber = null;
  }

  set id(value) {
    this._id = value;
  }

  set name(value) {
    this._name = value;
  }

  set chapterId(value) {
    this._chapterId = value;
  }

  set externalId(value) {
    this._externalId = value;
  }

  set breadcrumb(value) {
    this._breadcrumb = value;
  }

  set descriptionWithNumber(value) {
    this._descriptionWithNumber = value;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get chapterId() {
    return this._chapterId;
  }

  get externalId() {
    return this._externalId;
  }

  get breadcrumb() {
    return this._breadcrumb;
  }

  get descriptionWithNumber() {
    return this._descriptionWithNumber;
  }

  static fromObject(obj) {
    const o = new ProjectTask();
    o.id = obj.id;
    o.name = obj.name;
    o.chapterId = obj.chapterId;
    o.externalId = obj.externalId;
    o.breadcrumb = obj.breadcrumb;
    o.descriptionWithNumber = obj.descriptionWithNumber;

    return o;
  }
}

export default Project;
export { Project, ProjectTask };
