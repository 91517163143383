import isSameDay from 'date-fns/isSameDay';
import parseISO from 'date-fns/parseISO';
import { saveAs } from 'file-saver';
import { createSelector } from 'reselect';

import { formatStandard } from 'libs/dateFunctions';

import { TripLogListItem } from '../../model/trips-log-model';
import { getReportsState } from '../selectors';
import * as typesTripLog from '../trip-log/tripLogTypes';
import * as types from './reportsTypes';

const initialState = {
  tripLogs: [],
  tripTracking: [],
  liveTrips: {},
  mapReady: false,
  dataIsloading: false,
  genericError: {},
  showOnlyWithoutLocation: false,
  redrawMap: false,
};

const loadDateComparator = (a, b) => {
  if (a.loadTime < b.loadTime) {
    return -1;
  }
  if (a.loadTime > b.loadTime) {
    return 1;
  }
  return 0;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.REPORTS_UNMOUNT:
      return initialState;
    case types.SET_MAP_READY:
      return {
        ...state,
        tripLogs: [],
        tripTracking: [],
        mapReady: true,
      };
    case types.GET_TRIP_LOGS:
      if (action.data.downloadFile) {
        return state;
      }
      return {
        ...state,
        dataIsloading: true,
        tripLogs: [],
        tripTracking: [],
      };

    case types.GET_TRIP_LOGS_SUCCESS:
      return {
        ...state,
        tripLogs: action.data,
        tripTracking: null,
        dataIsloading: false,
        redrawMap: true,
      };

    case types.SET_TRIP_LOGS:
      return {
        ...state,
        tripLogs: action.data,
        tripTracking: null,
        dataIsloading: false,
        redrawMap: true,
      };

    case typesTripLog.UPDATE_TRIP_LOCATION:
      const { geoPoint } = action;
      const l = state.tripLogs.map((t) => {
        if (t.tripId == geoPoint.properties._tripId) {
          t = TripLogListItem.fromObject(t.source);
          if (geoPoint.properties._markType == 0) {
            t._source.loadCoordinates = geoPoint.coordinates;
          } else {
            t._source.dumpCoordinates = geoPoint.coordinates;
          }
        }

        return t;
      });
      return {
        ...state,
        tripLogs: l,
        redrawMap: true,
      };

    case types.GET_TRIP_TRACKING:
      return {
        ...state,
        tripTracking: [],
      };

    case types.GET_TRIP_TRACKING_SUCCESS:
      return {
        ...state,
        tripTracking: action.data,
        dataIsloading: false,
        redrawMap: false,
      };

    case types.GET_LIVE_TRIPS_SUCCESS:
      return {
        ...state,
        liveTrips: action.data,
        dataIsloading: false,
        redrawMap: false,
      };

    case types.CLEAR_MAP:
      return {
        ...state,
        liveTrips: {},
        tripLogs: [],
        tripTracking: [],
        dataIsloading: false,
        redrawMap: true,
      };

    case types.GET_FILTERED_TRIP_LOGS:
      if (action.filters.downloadFile) {
        return {
          ...state,
          dataIsloading: true,
        };
      }
      return {
        ...state,
        dataIsloading: true,
        tripLogs: [],
        tripTracking: [],
      };

    case types.TOGGLE_SELECTED_TRIP_LIST_ITEMS:
      const { tripIds, selected } = action;

      if (tripIds.length) {
        const toggled = state.tripLogs.map((i) => {
          const pos = tripIds.indexOf(i.tripId);
          if (pos > -1) {
            const o = TripLogListItem.fromObject(i.source);
            if (selected !== undefined) {
              o.selected = selected;
            } else {
              o.selected = !i.selected;
            }
            tripIds.splice(pos, 1);
            return o;
          }
          return i;
        });

        return {
          ...state,
          tripLogs: toggled,
          redrawMap: false,
        };
      }

      return {
        ...state,
        tripLogs: state.tripLogs.map((log) => {
          const updatedLog = TripLogListItem.fromObject(log.source);
          updatedLog.selected = selected;

          return updatedLog;
        }),
        redrawMap: false,
      };
    case types.EXPORT_TRIP_LOGS:
    case types.EXPORT_SCALE:
      return {
        ...state,
        dataIsloading: true,
      };
    case types.EXPORT_TRIP_LOGS_SUCCESS:
      const dateFormat = 'yyyy-MM-dd';
      let fileName = action.data.fileName
        ? action.data.fileName
        : 'MassTransport_ExportData_';

      const fromDate = parseISO(action.data.fromDate);
      const toDate = parseISO(action.data.toDate);

      fileName += formatStandard(fromDate, dateFormat);

      if (!isSameDay(fromDate, toDate)) {
        fileName = `${fileName} - ${formatStandard(toDate, dateFormat)}`;
      }

      fileName += '.csv';

      try {
        const isFileSaverSupported = !!new Blob();
        saveAs(action.data.data, fileName);
      } catch (e) {
        alert('This feature is bot supported by your browser.');
      }
      return {
        ...state,
        dataIsloading: false,
      };
    case typesTripLog.DELETE_TRIP_BULK_SUCCESS:
      const deletedIds = action.tripLogIds;
      const newList = [];
      if (Array.isArray(deletedIds)) {
        state.tripLogs.forEach((trip) => {
          const p = deletedIds.indexOf(trip.tripId);
          if (p > -1) {
            deletedIds.splice(p, 1);
          } else {
            newList.push(trip);
          }
        });
      }
      return { ...state, tripLogs: newList, redrawMap: true };

    case types.TOGGLE_SHOW_ONLY_WITHOUT_LOCATION:
      return {
        ...state,
        showOnlyWithoutLocation: !state.showOnlyWithoutLocation,
      };

    case types.REPORT_MAP_UPDATED:
      return {
        ...state,
        redrawMap: false,
      };
    case types.GET_ERROR:
      return {
        ...state,
        genericError: {
          errorMsg: action.error,
          url: action.url,
          type: action.type,
          dataIsloading: false,
          redrawMap: false,
        },
      };
    default:
      return state;
  }
}

const tSelector = createSelector(getReportsState, (state) =>
  state ? state.tripLogs : []
);
const ttSelector = createSelector(getReportsState, (state) =>
  state ? state.tripTracking : []
);
const ltSelector = createSelector(getReportsState, (state) =>
  state ? state.liveTrips : {}
);
export const showOnlyWithoutLocation = createSelector(
  getReportsState,
  (state) => state.showOnlyWithoutLocation
);

const filteredTriplogs = createSelector(
  tSelector,
  showOnlyWithoutLocation,
  (list, filter) => {
    if (filter) {
      return list.filter(
        (t) => !(t.loadLocationName != null && t.dumpLocationName != null)
      );
    }
    return list;
  }
);

export const getTripLogs = createSelector(filteredTriplogs, (list) =>
  list.map((l) => l.source)
);
export const getTripLogsExt = createSelector(filteredTriplogs, (list) =>
  list.sort(loadDateComparator)
);

export const getSelectedTripLogs = createSelector(filteredTriplogs, (list) =>
  list.filter((l) => l.selected).map((i) => i.tripId)
);

export const getAllTripsSelected = createSelector(
  [getSelectedTripLogs, filteredTriplogs],
  (selectedTripLogs, allLogs) => selectedTripLogs.length === allLogs.length
);

export const getSelectedTripLogsCount = createSelector(
  getSelectedTripLogs,
  (list) => list.length
);
export const getTripLogsCount = createSelector(
  filteredTriplogs,
  (list) => list.length
);
export const getTripTracking = createSelector(ttSelector, (list) => list);
export const getMapIsReady = createSelector(
  getReportsState,
  (state) => state.mapReady
);
export const getDataIsLoading = createSelector(
  getReportsState,
  (state) => state.dataIsloading
);
export const getRedrawMap = createSelector(
  getReportsState,
  (state) => state.redrawMap
);
export const getLiveTrips = createSelector(ltSelector, (list) => list);
export const getGenericError = createSelector(getReportsState, (state) =>
  state ? state.genericError : null
);
