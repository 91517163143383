import { createSelector } from 'reselect';

import * as types from './mapLayerTypes';
import { getMapLayerState } from '../selectors';

const initialState = {
  layersList: [],
};

const layersSorter = (a, b) => {
  let out = 0;
  if (a.sortOrder < b.sortOrder) {
    out = -1;
  } else if (a.sortOrder > b.sortOrder) {
    out = 1;
  }
  return out;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_ALL_LAYERS_SUCCESS:
      return {
        ...state,
        layersList: action.data,
      };
    case types.POST_LAYER_SUCCESS:
      return {
        ...state,
        layersList: [...state.layersList, action.layer],
      };
    case types.DELETE_LAYER_SUCCESS:
      return {
        ...state,
        layersList: state.layersList.filter(i => i.id !== action.layerId),
      };
    case types.PUT_LAYER_SUCCESS:
      return {
        ...state,
        layersList: [
          ...state.layersList.filter(i => i.id !== action.layer.id),
          action.layer,
        ],
      };

    default:
      return state;
  }
}

export const getLayersList = createSelector(
  getMapLayerState,
  state => (state ? state.layersList.concat().sort(layersSorter) : [])
);
