import * as React from 'react';
import { Input, InputProps, SelectOption } from '@ditioas/ui-forms';

interface SelectProps extends Omit<InputProps, 'select'> {
  options: (string | { value: string; name: string })[];
  value?: string;
  label?: string;
  size?: 'small' | 'medium';
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const Select = ({ options, ...rest }: SelectProps) => (
  <Input select {...rest}>
    {options.map((option) => {
      const isString = typeof option === 'string';

      return (
        <SelectOption
          key={isString ? option : option.value}
          value={isString ? option : option.value}
        >
          {isString ? option : option.name}
        </SelectOption>
      );
    })}
  </Input>
);

export default Select;
