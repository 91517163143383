import { patch, checkStatus } from 'libs/apiClient';
import { LocaleId } from 'model/locale';
const baseUrl = '/api/v3/user';

export const setCurrentUserLanguage = async (userId: string, localeId: LocaleId) => {
  const response = checkStatus(
    await patch(`${baseUrl}/${userId}/setlanguage/${localeId}`)
  );
  return response.json();
};
