import config from 'config/site.config';

const { apiBaseUrl } = config;

export const getCurrentSlug = (
  matchUrl // url from react router match
) => window.location.pathname.replace(matchUrl, '');

export const createSubUrlFactory =
  (prefix: string) =>
  (path = '') => {
    const strippedPath = path === '/' ? '' : path;
    return strippedPath.length
      ? `${prefix}${strippedPath.startsWith('/') ? '' : '/'}${strippedPath}`
      : prefix;
  };

export const getFileUrl = (fileReferenceId: string, size = 64) =>
  `${apiBaseUrl}/api/file/${fileReferenceId}${
    size ? `?MaxDimension=${size}` : ''
  }`;

export const getPreviuosPath = (path: string, length = 1) => {
  const arr = path.split('/');

  if (length === 0) {
    return path;
  }

  if (length > arr.length - 1 || typeof length !== 'number') {
    return '/';
  }

  return arr.slice(0, arr.length - length).join('/');
};

export const normalizeUrl = (url: string) => {
  if (!url || url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  return `https://${url}`;
};
