import React, { memo } from 'react';
import styled from 'styled-components';

import Spinner from 'components/loaders';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5rem;
`;

const FullscreenLoading = () => (
  <Wrapper>
    <Spinner />
  </Wrapper>
);

export default memo(FullscreenLoading);
