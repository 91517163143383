import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { useSetAtom } from 'jotai';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { SWRConfig } from 'swr';

import FullscreenLoading from 'components/fullscreen-loading';
import StatusCodeErrorHandler from 'components/status-code-errors/status-code-error-handler';
import config from 'config/site.config';
import Dashboard from 'containers/OverviewDashboard/Dashboard/Dashboard';
import useCurrentUserAndSettings, {
  revalidateUserAtom,
} from 'hooks/useCurrentUserAndSettings';
import { getPublic } from 'libs/apiClient';
import { enableSilentAuth, getUserManager } from 'libs/auth';
import { LocalStorageManager } from 'libs/utils';
import { getIsLoadingUser, getOidcUser } from 'store/oidcSelectors';

import ReadOnlyAlert from '../Alerts/ReadOnlyAlert/ReadOnlyAlert';

const { legacyWebBaseUrl } = config;

const PublicRoot = () => {
  const location = useLocation();
  const revalidateUser = useSetAtom(revalidateUserAtom);
  const oidcUser = useSelector(getOidcUser);
  const isLoadingOidcUser = useSelector(getIsLoadingUser);
  const userManager = getUserManager();
  const { currentUser } = useCurrentUserAndSettings();

  const getReturnUrl = () => {
    let returnTo = null;
    if (location && location.pathname) {
      const originalPath = location.pathname;
      if (originalPath !== '/' && originalPath !== '') {
        returnTo = location.pathname + (location.search ? location.search : '');
      }
    }

    return returnTo;
  };

  React.useEffect(() => {
    if (!isLoadingOidcUser) {
      if (!oidcUser) {
        LocalStorageManager.setRedirectUrlOnReturn(getReturnUrl());
        if (document.referrer.includes(legacyWebBaseUrl) && enableSilentAuth) {
          userManager.signinSilent().then(() => {
            revalidateUser();
          });
        }
      } else {
        revalidateUser();
      }
    }
  }, [isLoadingOidcUser, oidcUser]);

  React.useEffect(() => {
    if (currentUser) {
      Sentry.setUser({
        id: currentUser.userCompanyProfileId,
        username: currentUser.name,
      });
    }
  }, [currentUser]);

  return (
    <SWRConfig
      value={{
        fetcher: (...args) => getPublic(...args).then((res) => res.json()),
      }}
    >
      <Suspense fallback={<FullscreenLoading />}>
        <StatusCodeErrorHandler>
          <Routes>
            <Route path="/alerts/:alertId" element={<ReadOnlyAlert />} />
            <Route path="/dashboard" element={<Dashboard isPublic />} />
          </Routes>
        </StatusCodeErrorHandler>
      </Suspense>
    </SWRConfig>
  );
};

export default PublicRoot;
