import PropTypes from 'prop-types';
import { addDays, isValid } from 'date-fns';
import sortBy from 'lodash.sortby';
import i18n from 'src/i18n';
import * as Yup from 'yup';

import config from 'config/site.config';
import { createSubUrlFactory } from 'libs/urlFunctions';

import { getTypeOptions } from './select-type-options';

const { modules } = config;

class User {
  constructor() {
    this.id = null;
  }

  set name(value) {
    this._name = value;
  }

  get name() {
    return this._name;
  }

  static fromObject(inp) {
    const out = new User();
    if (inp) {
      out.name = inp.name;
      out.id = inp.id;
    }
    return out;
  }
}

export const employmentTypes = {
  SUB_CONTRACTOR: 0,
  SUB_EMPLOYEE: 1,
  EMPLOYEE: 2,
};

export const EMPLOYEE_OR_SUB_EMPLOYEE = -1;

export const employmentTypesPropType = PropTypes.oneOf(
  Object.keys(employmentTypes).map((key) => employmentTypes[key])
);

export const getEmploymentTypeDisplayText = (
  employmentType,
  includeSubEmployee = false,
  isFutureEmployment
) => {
  const upcomingLabel = isFutureEmployment
    ? `${i18n.t('Employment.Upcoming')} `
    : '';
  if (employmentType === employmentTypes.SUB_CONTRACTOR) {
    return upcomingLabel + i18n.t('EmploymentType.Subcontractor');
  }

  if (includeSubEmployee && employmentType === employmentTypes.SUB_EMPLOYEE) {
    return upcomingLabel + i18n.t('EmploymentType.SubEmployee');
  }

  return upcomingLabel + i18n.t('EmploymentType.Employee');
};

export const getEmploymentTypeOptions = () =>
  getTypeOptions(
    [employmentTypes.EMPLOYEE, employmentTypes.SUB_CONTRACTOR],
    getEmploymentTypeDisplayText
  );

export const payrollTypes = {
  NO: 0,
  YES: 1,
};

export const getPayrollTypeDisplayText = (payroll) => {
  if (payroll === payrollTypes.YES) {
    return i18n.t('PayrollType.Yes');
  }

  return i18n.t('PayrollType.No');
};

export const getPayrollTypeOptions = () =>
  getTypeOptions(payrollTypes, getPayrollTypeDisplayText);

export const accessLevels = {
  USER: 'user',
  SUPER_USER: 'superuser',
  ADMINISTRATOR: 'administrator',
};

export const extendedAccessLevels = {
  ...accessLevels,
  SUBCONTRACTOR_ADMIN: 'subcontractor_admin',
};

export const getAccessLevelDisplayText = (accessLevel) => {
  switch (accessLevel) {
    case accessLevels.SUPER_USER:
      return i18n.t('AccessLevel.Superuser');
    case accessLevels.ADMINISTRATOR:
      return i18n.t('AccessLevel.Administrator');
    default:
      return i18n.t('AccessLevel.User');
  }
};

export const getAccessLevelOptions = (
  maxAccessLevel = accessLevels.ADMINISTRATOR
) => {
  const options = getTypeOptions(accessLevels, getAccessLevelDisplayText);

  if (maxAccessLevel !== accessLevels.ADMINISTRATOR) {
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].value === accessLevels.ADMINISTRATOR) {
        options[i].disabled = true;
      }

      if (
        options[i].value === accessLevels.SUPER_USER &&
        maxAccessLevel === accessLevels.USER
      ) {
        options[i].disabled = true;
      }
    }
  }

  return options;
};

export const accessLevelPropType = PropTypes.oneOf(
  Object.keys(accessLevels).map((key) => accessLevels[key])
);

export const userRoles = {
  USER: 0,
  BAS: 3,
  FOREMAN: 5,
  PROJECT_MANAGER: 10,
  HSE: 15,
};

export const getUserRoleDisplayText = (userRole) => {
  switch (userRole) {
    case userRoles.USER:
      return i18n.t('UserRole.User');
    case userRoles.BAS:
      return i18n.t('UserRole.Bas');
    case userRoles.FOREMAN:
      return i18n.t('UserRole.Foreman');
    case userRoles.PROJECT_MANAGER:
      return i18n.t('UserRole.ProjectManager');
    case userRoles.HSE:
      return i18n.t('UserRole.HSE');
    default:
      return i18n.t('UserRole.User');
  }
};

export const getUserRoleOptions = () =>
  getTypeOptions(userRoles, getUserRoleDisplayText);

export const userFetchStatuses = {
  COMPLETE: 'complete',
  LIST: 'list',
};

export const userIsSubContractor = (user) =>
  user.employment?.employmentType === employmentTypes.SUB_CONTRACTOR;

export const userIsFutureEmployee = (user) => {
  if (user.employment) {
    return false;
  }

  if (user.upcomingEmployments?.length) {
    const sorted = sortBy(user.upcomingEmployments, 'startDate');
    return sorted[0].employmentType === employmentTypes.EMPLOYEE;
  }
  return false;
};

export const payrollTypesPropType = PropTypes.oneOf(
  Object.keys(payrollTypes).map((key) => payrollTypes[key])
);

export const createUserSubUrlFunction = (userId) =>
  createSubUrlFactory(`${modules.userHandling.detailUrlBase}/${userId}`);

export const getUserDetailUrl = (userId, slug = '') => {
  const getUrl = createUserSubUrlFunction(userId);
  return getUrl(slug);
};

export const tagShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const employmentShape = PropTypes.shape({
  companyProfileId: PropTypes.string, // TODO: verify if its required or not
  endDate: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  accessLevel: PropTypes.string.isRequired,
  department: PropTypes.string,
  employeeNumber: PropTypes.string,
  employmentId: PropTypes.string,
  employmentType: employmentTypesPropType.isRequired,
  payroll: payrollTypesPropType,
  company: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    organizationNumber: PropTypes.string.isRequired,
  }),
});

// TODO: consider move this to common prop types in frontend packages if it becomes common
export const userShape = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  companyProfileId: PropTypes.string.isRequired,
  identityUserId: PropTypes.string.isRequired,
  avatarImageId: PropTypes.string,
  legacyUserId: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
  projectsCount: PropTypes.number,
  email: PropTypes.string,
  userTags: PropTypes.arrayOf(tagShape),
  employment: employmentShape.isRequired,
  previousEmployments: PropTypes.arrayOf(employmentShape),
});

export const smsLogEntryShape = PropTypes.shape({
  SmsTypeName: PropTypes.string.isRequired,
  CreatedDateTime: PropTypes.string.isRequired,
  Id: PropTypes.string.isRequired,
});

export const tagHistoryEntryShape = PropTypes.shape({
  changeDateTime: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  changedByUserId: PropTypes.string.isRequired,
  changedByUserName: PropTypes.string.isRequired,
  changedByAvatarId: PropTypes.string.isRequired,
});

export { User };

export const createAccessLevelCheck = () =>
  Yup.string()
    .typeError(i18n.t('ValidationError.Required'))
    .required(i18n.t('ValidationError.Required'));

export const createDateCheck = () =>
  Yup.date()
    .typeError(i18n.t('ValidationError.Required'))
    .required(i18n.t('ValidationError.Required'));

export const createEndDateCheck = (startDate) =>
  isValid(startDate)
    ? Yup.date()
        .nullable()
        .typeError(i18n.t('ValidationError.Date_TypeError'))
        .min(
          addDays(startDate ?? new Date(), 1),
          i18n.t('Employment.ShouldBeAfterStartDateError')
        )
    : Yup.date().nullable().typeError(i18n.t('ValidationError.Date_TypeError'));

export const createOrgNumberCheck = () =>
  Yup.number()
    .typeError(i18n.t('ValidationError.OrgNumber'))
    .orgNumber(i18n.t('ValidationError.OrgNumber'))
    .required(i18n.t('ValidationError.Required'));

export const createEmployeeNumberCheck = () =>
  Yup.string()
    .typeError(i18n.t('ValidationError.Required'))
    .when('payroll', {
      is: 1,
      then: (schema) => schema.required(i18n.t('ValidationError.Required')),
    });

export const createPayrollCheck = () =>
  Yup.number()
    .typeError(i18n.t('ValidationError.Required'))
    .required(i18n.t('ValidationError.Required'));

export const createPersonalInfoValidationScheme = (
  isNewUser = false,
  workCardIntegrationEnabled = false
) =>
  Yup.lazy((values) => {
    const shape = {
      firstName: Yup.string()
        .required(i18n.t('ValidationError.Required'))
        .min('2', i18n.t('ValidationError.TooShort')),
      lastName: Yup.string()
        .required(i18n.t('ValidationError.Required'))
        .min('2', i18n.t('ValidationError.TooShort')),
      birthDate: Yup.date()
        .typeError(i18n.t('ValidationError.Required'))
        .required(i18n.t('ValidationError.Required')),
      builderCardNumber: Yup.lazy((value) =>
        value === ''
          ? Yup.string().nullable()
          : Yup.number()
              .typeError(i18n.t('ValidationError.Integer_TypeError'))
              .positive(i18n.t('ValidationError.Integer_TypeError'))
      ),
      builderCardExpirationDate: Yup.date().when(
        ['builderCardNumber', 'employmentType'],
        {
          is: (builderCardNumber, employmentType) =>
            !workCardIntegrationEnabled &&
            !!builderCardNumber &&
            employmentType !== employmentTypes.SUB_EMPLOYEE,
          then: (schema) =>
            schema
              .typeError(i18n.t('ValidationError.Required'))
              .required(i18n.t('ValidationError.Required')),
          otherwise: (schema) => schema.nullable(),
        }
      ),
      carRegistrationNumber: Yup.string(),
      closestRelative: Yup.string(),
      email: Yup.string().email(i18n.t('ValidationError.Email')),
      address: Yup.string(),
      description: Yup.string(),
      workTitle: Yup.string(),
    };

    if (isNewUser) {
      shape.phoneNumber = Yup.string()
        .phone(i18n.t('ValidationError.PhoneNumber'))
        .required(i18n.t('ValidationError.Required'));
    } else {
      return Yup.object().shape(shape);
    }

    const isSub = values.employmentType === employmentTypes.SUB_CONTRACTOR;

    if (isSub) {
      shape.startDate = createDateCheck();
      shape.endDate = createEndDateCheck(values.startDate);
      shape.accessLevel = createAccessLevelCheck();
    } else {
      shape.department = Yup.string().nullable();

      if (values.employmentType !== employmentTypes.SUB_EMPLOYEE) {
        shape.employeeNumber = createEmployeeNumberCheck();
      } else {
        shape.employeeNumber = Yup.string().nullable();
      }

      shape.startDate = createDateCheck();
      shape.endDate = createEndDateCheck(values.startDate);
      shape.payroll = createPayrollCheck();
      shape.accessLevel = createAccessLevelCheck();
    }

    return Yup.object().shape(shape);
  });

export const mapSelectedSubcontractorToEmploymentRequest = (
  company,
  companyName
) => {
  // company name used if no companies found in brreg or ditio db
  const companyRequest = {};

  if (company && company.companyId) {
    companyRequest.companyId = company.companyId;
  } else if (company && company.organizationNumber) {
    companyRequest.organizationNumber = company.organizationNumber;
  } else if (company && company.roaringIntegrationId) {
    companyRequest.roaringIntegrationId = company.roaringIntegrationId;
  } else {
    companyRequest.companyName = companyName;
  }

  return companyRequest;
};

export const disabledReasons = {
  DISABLED_BY_MIGRATION: 'userDisabledByMigration',
  DISABLED_BY_USER: 'userDisabledByUser',
  DISABLED_BY_PHONE_NUMBER_CHANGE: 'userDisabledByPhoneNumberChange',
  DISABLED_BY_EMPLOYMENT: 'userDisabledByEmployment',
  DISABLED_BY_INTEGRATION: 'userDisabledByIntegration',
};

export const disabledReasonPropType = PropTypes.oneOf(
  Object.keys(disabledReasons).map((key) => disabledReasons[key])
);

export const userCertificateImageShape = PropTypes.shape({
  fileReferenceId: PropTypes.string.isRequired,
  name: PropTypes.string,
});

export const userCertificateShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  typeId: PropTypes.string.isRequired,
  certificateId: PropTypes.string.isRequired,
  issued: PropTypes.string,
  validTo: PropTypes.string,
  notes: PropTypes.string,
  images: PropTypes.arrayOf(userCertificateImageShape),
});

export const userCertificateTypeShape = PropTypes.shape({
  typeId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const showRegistrationDialogParam = 'showRegistrationDialog';
export const showRegistrationCompleteParam = 'showRegistrationComplete';
export const createEmploymentParam = 'createEmployment';
export const endEmploymentParam = 'endEmployment';

const toFirstCapatalize = (word) =>
  `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;

export const normalizeName = (name) => {
  if (typeof name === 'undefined' || name === null) {
    return null;
  }

  const trimmedName = name.trim();

  if (trimmedName === '') {
    return trimmedName;
  }

  let result = '';

  const splittedOnSpace = trimmedName.split(' ');

  for (let i = 0; i < splittedOnSpace.length; i += 1) {
    const namePart = splittedOnSpace[i];
    let normalizedPart = '';

    if (namePart.includes('-')) {
      const splittedOnDash = namePart.split('-');
      normalizedPart += splittedOnDash.reduce((acc, part) => {
        if (acc === '') {
          return toFirstCapatalize(part);
        }

        return `${acc}-${toFirstCapatalize(part)}`;
      }, '');
    } else {
      normalizedPart += toFirstCapatalize(namePart);
    }

    if (result === '') {
      result += normalizedPart;
    } else {
      result += ` ${normalizedPart}`;
    }
  }

  return result;
};

export const isSysOpOrOwner = (user) =>
  (user.userGroupId === accessLevels.ADMINISTRATOR &&
    user.currentCompanyOwner) ||
  user.sysOp;

export const activeInactiveAll = {
  ACTIVE: 0,
  INACTIVE: 1,
  ALL: 2,
};
