import React from 'react';
import { Button } from '@ditioas/ui-buttons';
import { Heading, Text } from '@ditioas/ui-typography';
import { colors, constants } from '@ditioas/ui-variables';
import styled, { css } from 'styled-components';

const StyledHeading = styled(Heading)`
  margin-bottom: 1rem;
`;

const SubHeading = styled.div`
  color: ${colors.secondaryText};
  font-size: ${constants.heading3FontSize};
`;

interface PageHeadingProps {
  children: React.ReactNode;
  subHeading?: string;
}

export const PageHeading: React.FC<PageHeadingProps> = ({
  children,
  subHeading,
}) => (
  <div>
    <StyledHeading styleLevel={3}>{children}</StyledHeading>
    {subHeading && <SubHeading>{subHeading}</SubHeading>}
  </div>
);

export const TitleWrapper = styled.header`
  & > *:first-child {
    margin-bottom: 0.125rem;
  }
`;

export const ReportContainer = styled.div`
  width: 100%;
  max-width: 1000px;

  margin: 2rem auto;
  padding: 1rem;

  position: relative;

  overflow: auto;

  background-color: ${colors.white};

  @media print {
    margin: 0rem auto;
    height: fit-content;
    overflow-y: visible;
  }
`;

export const ToEditingButton = styled(Button)`
  margin: 0 0 auto;
`;

export const CornerContent = styled.div`
  margin-left: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CompanyLogo = styled.img`
  width: 100%;
  max-width: 8rem;
  max-height: 7rem;
  margin-bottom: 0.5rem;
`;

const noPrintCss = css`
  @media print {
    display: none;
  }
`;

export const Row = styled.div<{
  $paddingTop?: boolean;
  $flexStart?: boolean;
  $notPrint?: boolean;
}>`
  display: flex;
  justify-content: ${({ $flexStart }) =>
    $flexStart ? 'flex-start' : 'space-between'};
  padding-top: ${({ $paddingTop }) => ($paddingTop ? '1rem' : '0')};
  gap: 0.5rem;

  ${({ $notPrint }) => $notPrint && noPrintCss};

  @media print {
    padding-top: 0;
  }
`;

export const verticalStyles = css`
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem 0rem;
`;

export const DetailContainer = styled.div<{ $vertical?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0 0.5rem;

  page-break-inside: avoid;

  ${({ $vertical }) => $vertical && verticalStyles}
`;

export const InlineText = styled((props) => <Text variant="b2" {...props} />)`
  margin: 0;

  &:first-of-type {
    font-weight: ${constants.mediumFontWeight};
  }
`;

export const MultilineText = styled((props) => (
  <Text variant="b2" {...props} />
))`
  margin: 0;
`;

export const MultilineTextContainer = styled.div`
  width: 100%;

  margin: 0;
  padding: 0.5rem;

  border: 1px solid ${colors.divider};
  margin-bottom: 0.5rem;
`;

export const ImagesContainer = styled.div`
  display: grid;
  gap: 0.25rem;
  grid-template-columns: 1fr 1fr;
`;

export const Section = styled.section<{ $smallMargin?: boolean }>`
  margin-top: ${({ $smallMargin }) => ($smallMargin ? '1rem' : '1.5rem')};
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  break-inside: avoid;

  h2 {
    margin-bottom: 1rem;
  }
`;

export const ImagesLabel = styled(Text)`
  margin: 0 auto 0 0;
`;

export const Image = styled.img`
  margin: 0 auto auto;

  max-width: 100%;
  max-height: 600px;
`;

export const MapPreview = styled.img`
  width: 335px;
  height: 335px;
`;
