import * as types from './mapLayerTypes';

export const getAllLayersAction = companyId => ({
  type: types.GET_ALL_LAYERS,
  companyId,
});
export const getAllLayersSuccessAction = data => ({
  type: types.GET_ALL_LAYERS_SUCCESS,
  data,
});

export const postLayerAction = layer => ({ type: types.POST_LAYER, layer });
export const postLayerSuccessAction = layer => ({
  type: types.POST_LAYER_SUCCESS,
  layer,
});
export const putLayerAction = layer => ({ type: types.PUT_LAYER, layer });
export const putLayerSuccessAction = layer => ({
  type: types.PUT_LAYER_SUCCESS,
  layer,
});
export const deleteLayerAction = layerId => ({
  type: types.DELETE_LAYER,
  layerId,
});
export const deleteLayerSuccessAction = layerId => ({
  type: types.DELETE_LAYER_SUCCESS,
  layerId,
});
