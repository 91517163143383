import config from 'config/site.config';

import GeoPoint from './geo-point';

const { apiBaseUrl } = config;

class LiveTripsModel {
  constructor() {
    this._dumpers = [];
    this.processLiveTrips = this.processLiveTrips.bind(this);
    this._loaders = [];
    this._directionMap = new Map();
  }

  processLiveTrips(data) {
    if (data) {
      this._dumpers = data.dumpers ? data.dumpers : [];
      this._loaders = data.loaders ? data.loaders : [];

      this.processDirectionChange(this._dumpers);
    } else {
      this._dumpers = [];
      this._loaders = [];
    }
  }

  processDirectionChange = (data) => {
    data.forEach((i) => {
      const o = this._directionMap.get(i.dumperId);
      const n = { direction: 0, x: 0, y: 0 };
      if (o) {
        let nd = 0;
        const nx = i.lastKnownLocation.coordinates[0];
        const ny = i.lastKnownLocation.coordinates[1];

        if (
          i.lastKnownLocation &&
          i.lastKnownLocation.coordinates.length > 0 &&
          o.x != 0 &&
          o.y != 0
        ) {
          nd = (Math.atan2(ny - o.y, nx - o.x) * 180) / Math.PI;
        }

        n.direction = nd;
        n.x = nx;
        n.y = ny;
      }
      this._directionMap.set(i.dumperId, n);
    });
  };

  normalizeAvatarUrl = (url) =>
    url
      ? `${apiBaseUrl}/${url.replace(/MaxDimension=(\d+)/, 'MaxDimension=50')}`
      : null;

  getExcavatorById = (id) => {
    const out = this._loaders.filter((l) => l.loaderId == id);
    if (out.length > 0) {
      const l = LoaderLiveData.fromObject(out[0]);
      l.loaderAvatarSrc = this.normalizeAvatarUrl(l.loaderAvatarSrc);
      return l;
    }
    return null;
  };

  getDumperById = (id) => {
    const out = this._dumpers.filter((d) => d.dumperId == id);
    if (out.length > 0) {
      const d = DumperLiveData.fromObject(out[0]);
      d.dumperAvatarSrc = this.normalizeAvatarUrl(d.dumperAvatarSrc);
      return d;
    }
    return null;
  };

  get allDumpersAsGeoJSON() {
    return this._dumpers.map((d) => {
      const out = d.lastKnownLocation.toGeoJSON();
      out.properties = {
        hasLoad: d.dumperHasLoad,
        deviceNeedsUpdate: d.deviceNeedsUpdate,
        direction: this._directionMap.get(d.dumperId).direction,
        isMoving: d.speed > 0,
      };
      out.properties.id = d.dumperId;
      return out;
    });
  }

  get allLoadersAsGeoJSON() {
    return this._loaders.map((l) => {
      const out = l.lastKnownLocation.toGeoJSON();
      out.properties = { id: l.loaderId };
      return out;
    });
  }
}

class DumperLiveData {
  constructor() {
    this._companyId = null;
    this._projectId = null;
    this._taskId = null;
    this._dumperId = null;
    this._dumperDriverId = null;
    this._appVersion = null;
    this._dumperHasLoad = false;
    this._massTypeId = null;
    this._dumperAvatarSrc = null;
    this._dumperDriverName = null;
    this._dumperName = null;
    this._massTypeName = null;
    this._mobilePhoneNumber = null;
    this._projectName = null;
    this._taskName = null;
    this._companyName = null;
    this._quantity = 0;
    this._speed = 0;
    this._deviceNeedsUpdate = false;
    this._lastKnownLocation = new GeoPoint();
  }

  // ----------SETTERS
  set deviceNeedsUpdate(value) {
    this._deviceNeedsUpdate = value;
  }

  set quantity(value) {
    this._quantity = value;
  }

  set speed(value) {
    this._speed = value;
  }

  set dumperAvatarSrc(value) {
    this._dumperAvatarSrc = value;
  }

  set dumperDriverName(value) {
    this._dumperDriverName = value;
  }

  set dumperName(value) {
    this._dumperName = value;
  }

  set massTypeName(value) {
    this._massTypeName = value;
  }

  set mobilePhoneNumber(value) {
    this._mobilePhoneNumber = value;
  }

  set projectName(value) {
    this._projectName = value;
  }

  set taskName(value) {
    this._taskName = value;
  }

  set companyId(value) {
    this._companyId = value;
  }

  set projectId(value) {
    this._projectId = value;
  }

  set taskId(value) {
    this._taskId = value;
  }

  set dumperId(value) {
    this._dumperId = value;
  }

  set dumperDriverId(value) {
    this._dumperDriverId = value;
  }

  set appVersion(value) {
    this._appVersion = value;
  }

  set dumperHasLoad(value) {
    this._dumperHasLoad = value;
  }

  set massTypeId(value) {
    this._massTypeId = value;
  }

  set lastKnownLocation(value) {
    this._lastKnownLocation = value;
  }

  set companyName(value) {
    this._companyName = value;
  }

  // ----------GETTERS
  get deviceNeedsUpdate() {
    return this._deviceNeedsUpdate;
  }

  get companyId() {
    return this._companyId;
  }

  get projectId() {
    return this._projectId;
  }

  get taskId() {
    return this._taskId;
  }

  get dumperId() {
    return this._dumperId;
  }

  get dumperDriverId() {
    return this._dumperDriverId;
  }

  get appVersion() {
    return this._appVersion;
  }

  get dumperHasLoad() {
    return this._dumperHasLoad;
  }

  get massTypeId() {
    return this._massTypeId;
  }

  get lastKnownLocation() {
    return this._lastKnownLocation;
  }

  get dumperAvatarSrc() {
    return this._dumperAvatarSrc;
  }

  get dumperDriverName() {
    return this._dumperDriverName;
  }

  get dumperName() {
    return this._dumperName;
  }

  get massTypeName() {
    return this._massTypeName;
  }

  get mobilePhoneNumber() {
    return this._mobilePhoneNumber;
  }

  get projectName() {
    return this._projectName;
  }

  get taskName() {
    return this._taskName;
  }

  get companyName() {
    return this._companyName;
  }

  get quantity() {
    return this._quantity;
  }

  get speed() {
    return this._speed;
  }

  static fromObject(obj) {
    const o = new DumperLiveData();
    if (obj) {
      o.companyId = obj.companyId;
      o.projectId = obj.projectId;
      o.taskId = obj.taskId;
      o.dumperId = obj.dumperId;
      o.dumperDriverId = obj.dumperDriverId;
      o.appVersion = obj.appVersion;
      o.dumperHasLoad = obj.dumperHasLoad;
      o.massTypeId = obj.massTypeId;
      o.dumperAvatarSrc = obj.dumperAvatarSrc;
      o.dumperDriverName = obj.dumperDriverName;
      o.dumperName = obj.dumperName;
      o.massTypeName = obj.massTypeName;
      o.mobilePhoneNumber = obj.mobilePhoneNumber;
      o.projectName = obj.projectName;
      o.taskName = obj.taskName;
      o.companyName = obj.companyName;
      o.quantity = obj.quantity;
      o.speed = obj.speed;
      o.deviceNeedsUpdate = obj.deviceNeedsUpdate;
      o.lastKnownLocation = GeoPoint.fromObject(obj.lastKnownLocation);
    }
    return o;
  }
}

class LoaderLiveData {
  constructor() {
    this._companyId = null;
    this._loaderName = null;
    this._loaderId = null;
    this._loaderAvatarSrc = null;
    this._projectName = null;
    this._taskName = null;
    this._massTypeName = null;
    this._loaderDriverName = null;
    this._lastSeen = null;
    this._mobilePhoneNumber = '';
    this._companyName = null;
    this._lastKnownLocation = new GeoPoint();
  }
  // ----------SETTERS

  set companyName(value) {
    this._companyName = value;
  }

  set loaderId(value) {
    this._loaderId = value;
  }

  set lastSeen(value) {
    this._lastSeen = value;
  }

  set mobilePhoneNumber(value) {
    this._mobilePhoneNumber = value;
  }

  set companyId(value) {
    this._companyId = value;
  }

  set loaderName(value) {
    this._loaderName = value;
  }

  set loaderAvatarSrc(value) {
    this._loaderAvatarSrc = value;
  }

  set projectName(value) {
    this._projectName = value;
  }

  set taskName(value) {
    this._taskName = value;
  }

  set massTypeName(value) {
    this._massTypeName = value;
  }

  set loaderDriverName(value) {
    this._loaderDriverName = value;
  }

  set lastKnownLocation(value) {
    this._lastKnownLocation = value;
  }

  // ----------GETTERS

  get companyId() {
    return this._companyId;
  }

  get loaderName() {
    return this._loaderName;
  }

  get loaderAvatarSrc() {
    return this._loaderAvatarSrc;
  }

  get projectName() {
    return this._projectName;
  }

  get taskName() {
    return this._taskName;
  }

  get massTypeName() {
    return this._massTypeName;
  }

  get loaderDriverName() {
    return this._loaderDriverName;
  }

  get lastKnownLocation() {
    return this._lastKnownLocation;
  }

  get loaderId() {
    return this._loaderId;
  }

  get lastSeen() {
    return this._lastSeen;
  }

  get mobilePhoneNumber() {
    return this._mobilePhoneNumber;
  }

  get companyName() {
    return this._companyName;
  }

  static fromObject(obj) {
    const o = new LoaderLiveData();
    if (obj) {
      o.companyId = obj.companyId;
      o.loaderName = obj.loaderName;
      o.loaderAvatarSrc = obj.loaderAvatarSrc;
      o.projectName = obj.projectName;
      o.taskName = obj.taskName;
      o.massTypeName = obj.massTypeName;
      o.loaderDriverName = obj.loaderDriverName;
      o.loaderId = obj.loaderId;
      o.lastSeen = obj.lastSeen;
      o.mobilePhoneNumber = obj.mobilePhoneNumber;
      o.companyName = obj.companyName;
      o.lastKnownLocation = GeoPoint.fromObject(obj.lastKnownLocation);
    }
    return o;
  }
}

class TripLiveData {
  constructor() {
    this._dumpers = [];
    this._loaders = [];
  }

  set loaders(value) {
    this._loaders = value;
  }

  set dumpers(value) {
    this._dumpers = value;
  }

  // --------------GETTERS

  get loaders() {
    return this._loaders;
  }

  get dumpers() {
    return this._dumpers;
  }

  static fromObject(obj) {
    const o = new TripLiveData();
    if (obj) {
      o.loaders = obj.loaderLiveDataList
        ? obj.loaderLiveDataList.map((l) => LoaderLiveData.fromObject(l))
        : [];
      o.dumpers = obj.dumperLiveDataList
        ? obj.dumperLiveDataList.map((d) => DumperLiveData.fromObject(d))
        : [];
    }
    return o;
  }
}

export { LiveTripsModel, DumperLiveData, LoaderLiveData, TripLiveData };
