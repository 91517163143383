import { createSelector } from 'reselect';

import * as types from './projectTypes';
import { getProjectState } from '../selectors';
import { Project, ProjectTask } from '../../model/project';

const initialState = {
  projectList: null,
  taskList: null,
  taskListLoaded: false,
  genericError: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectList: action.data,
        taskList: [],
      };

    case types.GET_TASK_LIST:
      return {
        ...state,
        taskList: [],
        taskListLoaded: false,
      };

    case types.GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        taskListLoaded: true,
        taskList: action.data,
      };

    case types.GET_ERROR:
      return {
        ...state,
        genericError: {
          errorMsg: action.error,
          url: action.url,
          type: action.type,
        },
      };
    default:
      return state;
  }
}

const pSelector = createSelector(
  getProjectState,
  state => (state ? state.projectList : [])
);
const tSelector = createSelector(
  getProjectState,
  state => (state ? state.taskList : [])
);

export const getProjectList = createSelector(
  pSelector,
  list => list || []
);
export const getTaskList = createSelector(
  tSelector,
  list => list || []
);
export const getTaskListLoaded = createSelector(
  getProjectState,
  state => state.taskListLoaded
);
export const getGenericError = createSelector(
  getProjectState,
  state => (state ? state.genericError : null)
);
