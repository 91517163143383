const IMAGES_ROOT = '/images/app/';

const config = {
  env: import.meta.env.VITE_NODE_ENV,
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  massTransportApiBaseUrl: import.meta.env.VITE_MT_API_BASE_URL,
  reportApiBaseUrl: import.meta.env.VITE_REPORT_API_BASE_URL,
  oidc: {
    authority: import.meta.env.VITE_OIDC_AUTHORITY,
  },
  pusher: {
    appId: import.meta.env.VITE_PUSHER_APP_ID,
    key: import.meta.env.VITE_PUSHER_KEY,
  },
  legacyWebBaseUrl: import.meta.env.VITE_LEGACY_WEB_BASE_URL,
  supportEmail: 'support@ditio.no',
  mixpanelToken: import.meta.env.VITE_MIXPANEL_TOKEN,
  mapbox: {
    // accessToken:'pk.eyJ1IjoieWV2aGVuLXByb2tvcG92eWNoIiwiYSI6ImNqaDBsZGZ3dTE0dzMzMm80c3YzN3U1dHgifQ.r5D_Ix_u5H2_gtKzF-h4Ig',
    accessToken:
      'pk.eyJ1IjoiZGl0aW8iLCJhIjoiY2ozbGZpdnRnMDA0ZjJybzc3cTJxaXF0ciJ9.Xnrfs_CKd-NddDjJ1dWh8Q',
    mapImagesRoot: `${IMAGES_ROOT}map/`,
    mapImages: [
      {
        url: 'geofence_velg.png',
        id: 'geofence-velg',
      },
      {
        url: 'geofence_valgt.png',
        id: 'geofence-valgt',
      },
      {
        url: 'pickup_area.png',
        id: 'pickup_area',
      },
      {
        url: 'point_red.png',
        id: 'point_red',
      },
      {
        url: 'point_green.png',
        id: 'point_green',
      },
      {
        url: 'point_black.png',
        id: 'point_black',
      },
      {
        url: 'point_red_add.png',
        id: 'point_red_add',
      },
      {
        url: 'point_green_add.png',
        id: 'point_green_add',
      },
      {
        url: 'point_empty.png',
        id: 'point_empty',
      },
      {
        url: 'excavator.png',
        id: 'excavator',
      },
      {
        url: 'dumper_active.png',
        id: 'dumper_active',
      },
      {
        url: 'dumper_inactive.png',
        id: 'dumper_inactive',
      },
      {
        url: 'dumper_ahtung.png',
        id: 'dumper_ahtung',
      },
      {
        url: 'flag_start.png',
        id: 'flag_start',
      },
      {
        url: 'flag_dump.png',
        id: 'flag_dump',
      },
      {
        url: 'flag_finish.png',
        id: 'flag_finish',
      },
    ],
  },
  imagesRoot: IMAGES_ROOT,
  sentry: {
    dsn: 'https://a45ff0296d404189a4646fd6a3e0440b@sentry.io/1321955',
  },
  liveViewUpdateInterval: 15000,
  minPointsForSuperclusering: 1500,
  modules: {
    alerts: {
      baseUrl: '/alerts',
      projectsUrl: '/alerts/projects',
      listUrl: '/alerts/projects/list',
      setupUrl: '/alerts/setup',
      newAlertUrl: '/alerts/new',
      companyAlertsUrl: '/alerts/company',
      userAlertsUrl: '/alerts/current-user',
    },
    certificatesOverview: {
      baseUrl: '/certificates-overview',
      certificatesUrl: '/certificates-overview/certificates',
      userCertificatesUrl: '/certificates-overview/userCertificates',
      detailsUrl: '/certificates-overview/details',
    },
    resourcesAndMachines: {
      baseUrl: '/resources-and-machines',
      additionalEquipmentUrl: '/resources-and-machines/additional-equipment',
      alertsUrl: '/resources-and-machines/alerts',
      newAlertUrl: '/resources-and-machines/alerts/new',
      newResourceOrMachineUrl: '/resources-and-machines/new',
      notificationsUrl: '/resources-and-machines/notifications',
      checklistsUrl: '/resources-and-machines/checklists',
    },
    resourcePlanning: {
      baseUrl: '/resource-planning',
    },
    masstransport: {
      baseUrl: '/masstransport',
      overviewUrl: '/masstransport/overview',
      dumperDashboardUrl: '/masstransport/dumper-stats',
    },
    userHandling: {
      baseUrl: '/user-handling',
      detailUrlBase: '/user-handling/user',
      list: '/user-handling/users',
      newUser: '/user-handling/user/new',
    },
    onlineUsers: {
      baseUrl: '/online-users',
    },
    companyHandling: {
      baseUrl: '/company-handling',
      detailUrlBase: '/company-handling/company',
      list: '/company-handling/companies',
      newCompany: '/company-handling/company/new',
      currentCompany: '/company-handling/company/current',
      shiftSetup: '/company-handling/company/shiftSetup',
      waitingTime: '/company-handling/company/waiting-time',
    },
    equipmentTraining: {
      baseUrl: '/equipment-training',
      additionalEquipmentUrl: '/equipment-training/additional',
      userTrainings: '/user-trainings',
      usageWithoutTraining: '/usage-without-training',
    },
    checklists: {
      baseUrl: '/checklists',
      projectsUrl: '/checklists/projects',
      settingsUrl: '/checklists/settings',
      templatesUrl: '/checklists/templates',
      newTemplateUrl: '/checklists/templates/new',
    },
    health: {
      baseUrl: '/health',
    },
    infoCenterAdmin: {
      baseUrl: '/info-center',
      pagesUrl: '/info-center/info-pages',
      messagesUrl: '/info-center/messages',
    },
    imageMap: {
      baseUrl: '/new-image-map',
    },
    sysOp: {
      baseUrl: '/sysop',
      infoPagesCopying: '/info-page-copying',
      pilotSettingsUrl: '/pilots',
      templateCopying: '/template-copying',
      companyBilling: '/company-billing',
      dashboard: '/dashboard',
    },
  },
  legacyWebUrls: {
    hoursReport: '/#/PTRQC', // ?users=[id]
    weekReport: '/#/PTRQC?ptr=ptrweek', // &users=[id]
    transactionEditBase: '/#/ProjTransEdit/user', // user id
    timebankBase: '/#/ngUserTimebank', // user id
  },
} as const;

export default config;
