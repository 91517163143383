const NS = 'TRIPLOG/';

export const GET_ERROR = `${NS}GET_ERROR`;
export const GET_ALL_TRIP_LOGS = `${NS}GET_ALL_TRIP_LOGS`;
export const GET_ALL_TRIP_LOGS_SUCCESS = `${NS}GET_ALL_TRIP_LOGS_SUCCESS`;

export const POST_TRIP_LOG = `${NS}POST_TRIP_LOG`;
export const PUT_TRIP_LOG = `${NS}PUT_TRIP_LOG`;
export const PUT_TRIP_LOG_SUCCESS = `${NS}PUT_TRIP_LOG_SUCESS`;

export const UPDATE_TRIP_LOCATION = `${NS}UPDATE_TRIP_LOCATION`;

export const GET_TRIP_LOG_BULK = `${NS}GET_TRIP_LOG_BULK`;
export const GET_TRIP_LOG_BULK_SUCCESS = `${NS}GET_TRIP_LOG_BULK_SUCCESS`;
export const POST_TRIP_LOG_BULK = `${NS}POST_TRIP_LOG_BULK`;
export const POST_TRIP_LOG_BULK_SUCCESS = `${NS}POST_TRIP_LOG_BULK_SUCCESS`;
export const PUT_TRIP_LOG_BULK = `${NS}PUT_TRIP_LOG_BULK`;
export const PUT_TRIP_LOG_BULK_SUCCESS = `${NS}PUT_TRIP_LOG_BULK_SUCCESS`;

export const DELETE_TRIP_BULK = `${NS}DELETE_TRIP_BULK`;
export const DELETE_TRIP_BULK_SUCCESS = `${NS}DELETE_TRIP_BULK_SUCCESS`;
