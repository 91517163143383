import React from 'react';

import { LegacyDashboard, MenuContent, ReleaseNotes } from 'model/Menu/types';

import { useApi } from './base-api';

const menuLSKey = 'menu-content';

export const useGetMenuContent = () => {
  const storedMenuItems = localStorage.getItem(menuLSKey);
  const [menuItems, setMenuItems] = React.useState<MenuContent>(
    JSON.parse(storedMenuItems)
  );

  const { data, ...rest } = useApi<MenuContent>('/api/menu-content');

  React.useEffect(() => {
    if (data && JSON.stringify(data) !== storedMenuItems) {
      setMenuItems(data);
      localStorage.setItem(menuLSKey, JSON.stringify(data));
    }
  }, [data]);

  return { menuItems, ...rest };
};

export const useGetLegacyDashboard = (skip: boolean) =>
  useApi<LegacyDashboard>(skip ? null : 'api/dashboardlegacy/notifications');

export const useGetReleaseNotes = () =>
  useApi<ReleaseNotes[]>('api/v3/release-note');
