import { createSelector } from 'reselect';

import { getAppState } from '../rootSelectors';

export const getOnlineUsersState = createSelector(
  getAppState,
  (state) => state.onlineUsers
);
export const getUsersState = createSelector(
  getAppState,
  (state) => state.users.users
);

export const getUsersRootState = createSelector(
  getAppState,
  (state) => state.users
);

export const getCurrentUserState = createSelector(
  getAppState,
  (state) => state.currentUser
);

export const getCompaniesRootState = createSelector(
  getAppState,
  (state) => state.companies
);

export const getCurrentCompanyState = createSelector(
  getAppState,
  (state) => state.currentCompany
);

const massTypesSelector = createSelector(
  getAppState,
  (state) => state.massType
);
const locationsSelector = createSelector(
  getAppState,
  (state) => state.location
);
const projectSelector = createSelector(getAppState, (state) => state.project);
const machineSelector = createSelector(getAppState, (state) => state.machine);
const tripLogSelector = createSelector(getAppState, (state) => state.tripLog);
const mapLayerSelector = createSelector(getAppState, (state) => state.mapLayer);
export const getMassTypeState = createSelector(
  massTypesSelector,
  (state) => state
);

export const getLocationsState = createSelector(
  locationsSelector,
  (state) => state
);

export const getReportsState = createSelector(
  getAppState,
  (state) => state.reports
);
export const getProjectState = createSelector(
  projectSelector,
  (state) => state
);
export const getMachineState = createSelector(
  machineSelector,
  (state) => state
);
export const getTripLogState = createSelector(
  tripLogSelector,
  (state) => state
);
export const getMapLayerState = createSelector(
  mapLayerSelector,
  (state) => state
);
