import React, { PropsWithChildren } from 'react';
import useMedia from 'use-media';

export interface MediaQueryContextParams {
  isWide: boolean;
}

const MediaQueryContext = React.createContext<MediaQueryContextParams>({
  isWide: false,
});

const MediaQueryProvider = ({ children }: PropsWithChildren) => {
  const isWide = useMedia({ minWidth: 1800 });
  const value = React.useMemo(() => ({ isWide }), [isWide]);

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQueryContext = () => {
  const context = React.useContext(MediaQueryContext);

  return context;
};

export default MediaQueryProvider;
