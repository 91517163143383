import type { CompanyTag } from 'model/CompanyTags/types';
import type { modules } from 'model/modules';
import type { ProjectSettings } from 'model/ProjectSettings/types';
import type { ImageReference } from 'model/types';

import type { Company, Employment } from '../Company/types';

export interface User {
  active: boolean;
  address: string;
  aspNetId: string;
  avatarImageId: string;
  birthDate: string; // dd.mm.yyyy
  carMakeType: string;
  carRegNumber: string;
  cardExpirationDate: string; // iso
  cardId: string;
  cardStatus: CardStatus;
  companyId: string;
  companyName: string;
  companyObjectId: string;
  companyType: CompanyType;
  connectedCompanyId: string;
  connectedCompanyName: string;
  countryCode: string | null;
  currentCompanyOwner: boolean;
  currentToken: string;
  department: string;
  editable: boolean;
  email: string;
  emergencyContact: string;
  emergencyInfo: string;
  employeeNumber: string;
  employmentId: string;
  failedLoginAttempts: number;
  firstName: string;
  id: string;
  identityId: string;
  initials: null;
  isAdmin: boolean;
  isImpersonating: boolean;
  isProjectCompany: false;
  isParentCompany: boolean;
  currentCompanyHasProjectCompanies: boolean;
  isSuperUser: false;
  isUser: false;
  languageId: LanguageId;
  lastLoginDateTime: string; // iso
  lastName: string;
  legacyId: string;
  mobilePhone: string;
  modifiedBy: string | null;
  modifiedDateTime: string; // iso
  name: string;
  numberOfLogins: number;
  orgNumber: string;
  payroll: boolean;
  primaryResourceId: string | null;
  primaryResourceName: string | null;
  projectRoles: string[];
  socialSecurityId: string | null;
  sysOp: boolean;
  tags: UserTag[];
  timeZoneId: string;
  userCompanyProfileId: string;
  userCompanyRoles: UserCompanyRole[];
  userGroupId: UserGroup;
  userGroupIdMain: UserGroup;
  userGroupName: string; // UserGroup with first capital
  userId: string;
  userSecurityLevel: UserSecurityLevel;
}

export interface UserV3Api {
  active: boolean;
  avatarImageId: string;
  avatarSrc: string;
  birthDate: string;
  cardExpirationDate: string;
  cardId: string;
  companyId: string;
  companyIdMain: null | string;
  companyName: string;
  companyNameMain: null | string;
  companyProfileId: string;
  connectedCompanyId: string;
  connectedCompanyName: string;
  currentToken: null | string;
  email: string;
  employeeNumber: string;
  hasTransaction: boolean;
  id: string;
  mobilePhone: string;
  name: string;
  pieceworkSetupAllowed: boolean;
  userGroupId: string;
  userGroupName: null | string;
  userName: string;
  userSecurityLevel: UserSecurityLevel;
}

export enum UserInfoSource {
  Manual,
  SelfService,
  ScimIntegration,
  ApiIntegration,
}

// api/v4/users
export interface EmployeeAndSubcontractorUser
  extends Pick<
    User,
    | 'avatarImageId'
    | 'birthDate'
    | 'companyId'
    | 'firstName'
    | 'id'
    | 'lastName'
  > {
  builderCardNumber?: string;
  builderCardExpirationDate?: string;
  companyProfileId: User['userCompanyProfileId'];
  disableReason: string | null;
  employment: Employment;
  deactivatedEmployment: Employment;
  enhancedLockingOfFields: boolean;
  fullName: string;
  identityUserId: string;
  id: string;
  isDisabled: boolean;
  legacyUserId: string;
  phoneNumber: string;
  projectsCount: number;
  userInfoSource: UserInfoSource;
  userTags: UserSettingsTag[];
  workTitle: string;
}

interface CommonUserField
  extends EmployeeAndSubcontractorUser,
    Pick<User, 'address' | 'birthDate' | 'email'> {
  builderCardExpirationDate: string | null;
  builderCardNumber: string;
  carRegistrationNumber: string | null;
  closestRelative: string;
  defaultResourceId: string | null;
  defaultResourceName: string | null;
  description: string;
  previousEmployments: Employment[];
  upcomingEmployments: Employment[];
}

export type UserForSearch = Pick<
  EmployeeAndSubcontractorUser,
  | 'companyId'
  | 'companyProfileId'
  | 'firstName'
  | 'identityUserId'
  | 'fullName'
  | 'lastName'
  | 'phoneNumber'
>;

// api/v4/users/{userId}
export type DetailedEmployeeAndSubcontractorUser = CommonUserField &
  Pick<User, 'avatarImageId' | 'companyId' | 'firstName' | 'id' | 'lastName'> &
  Pick<
    EmployeeAndSubcontractorUser,
    'employment' | 'userTags' | 'workTitle'
  > & {
    companyProfileId: User['userCompanyProfileId'];
    description: string;
    disableReason: unknown;
    fullName: string;
    identityId: EmployeeAndSubcontractorUser['identityUserId'];
    isDisabled: boolean;
    legacyUserId: User['legacyId'];
    phoneNumber: User['mobilePhone'];
  };

export enum ProfileOriginType {
  NotSet,
  ParentCompany,
  SubsidiaryCompany,
  OutsideStructure,
}

export interface BestMatchingProfile extends CommonUserField {
  profileOriginType: ProfileOriginType;
}

export interface NewEmployment extends Omit<Partial<Employment>, 'company'> {
  company: Partial<Employment['company']>;
}

export interface NewUserData {
  address: string;
  birthDate: string | null;
  builderCardNumber: string | null;
  builderCardExpirationDate: string | null;
  carRegistrationNumber: string | null;
  closestRelative: string | null;
  companyProfileId: User['userCompanyProfileId'];
  email: string;
  avatarImageId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  defaultResourceId: string | null;
  description: string;
  projectSettings: unknown;
  workTitle: string;
  employment: NewEmployment;
}

export interface NewUserForm {
  accessLevel: UserGroup;
  address: string;
  avatarImageId: string | null;
  birthDate: Date;
  builderCardExpirationDate: Date | null;
  builderCardNumber: string;
  carRegistrationNumber: string;
  closestRelative: string;
  company?: Partial<Company>;
  companyAdministrator: boolean;
  companyName: string;
  defaultResource?: {
    id: string;
    name: string;
  };
  department?: string;
  description: string;
  email: string;
  employeeNumber?: string;
  employmentType?: EmploymentTypes;
  firstName: string;
  lastName: string;
  parentEmploymentId?: string;
  payroll?: PayrollTypes;
  phoneNumber: string;
  projectSettings?: ProjectSettings[];
  startDate?: Date;
  workTitle?: string;
}

export type NewUserTag = CompanyTag | UserSettingsTag;

export interface NewUserTagsForm {
  tags: NewUserTag[];
  payrollTags: NewUserTag[];
}

// api/user/lookuplist/all
export interface AccessibleUser {
  active: boolean;
  avatarImageId: string;
  companyId: string;
  companyName: string;
  connectedCompanyId: string;
  employeeNumber: null | string;
  id: string;
  mobilePhone: string;
  name: string;
  userId: string;
}

export interface UserProjectOverview {
  companyId: string;
  currentUserCanEdit: boolean;
  externalProjNumber: string;
  isActive: boolean;
  osMainRole: boolean;
  projectId: string;
  projectName: string;
  projectNumber: string;
  resources: number;
  responsibleForUsers: number;
  tasks: number;
  userId: string;
  userRole: ProjectUserRole;
  userRoleName: string;
}

export interface UserSettings {
  massTransportAccess: boolean;
  massTransportEditAccess: boolean;
  modules: (typeof modules)[keyof typeof modules][];
  pilotFlags: string[];
  moduleSettings: {
    general: {
      allowWaitingTimeRegistration: boolean;
      blockCommentEditingOnLockedTransactions: boolean;
      blockCreationOfCompanyMachines: boolean;
      createUserSetting: 0; // enum?
      createMachineSetting: 0;
      hasLegacyProjectNotifications: boolean;
      loadShiftsFromGat: boolean;
      priceHandling: boolean;
      requireInternalDescription: boolean;
      useTimeBasedPlanner: boolean;
      userHasPayroll: boolean;
    };
  };
  projUserSettings: {
    activitys: string[];
    activitysPure: string[];
    chapters: string[];
    companyId: string;
    companyIds: string[];
    createdAt: string; // utc z
    createdByUserId: string;
    editActivities: boolean;
    editHSECheckList: boolean;
    editProject: boolean;
    editTransactions: boolean;
    editUserPlanning: boolean;
    id: string;
    isMainRole: boolean;
    lastCheckedDateTime: string; // utc z
    machines: string[];
    modifiedAt: string; // utc z
    modifiedByUserId: string;
    modifiedDateTime: string; // utc z
    projId: string;
    projectMentor: boolean;
    recVersion: number;
    userId: string;
    userMentor: string[];
    userRole: ProjectUserRole;
  }[];
}

type CardStatus = 'missing' | 'expires' | 'expired' | 'valid';

enum CompanyType {
  MainCompany = 0,
  ProjectCompany,
  SubsidiaryCompany,
}

type LanguageId = 'en' | 'nb';

export interface UserSettingsTag {
  applicableFromDate: string; // utc z
  applicableToDate: string; // utc z
  companyId: string;
  companyLogoId: string;
  companyName: string;
  id: string;
  isPayrollTag: boolean;
  name: string;
  tagCompanyId: string;
  tagId: string;
  userId: string;
}

export interface UserTag
  extends Omit<
    UserSettingsTag,
    'companyLogoId' | 'companyName' | 'name' | 'tagCompanyId'
  > {
  createdAt: string; // utc
  createdByUserId: string | null;
  createdDateTime: string; // utc z
  modifiedAt: string; // utc
  modifiedByUserId: string | null;
  recVersion: number;
  tagCompanyName: string;
  tagName: string;
  taggedFromCompanyName: string;
  userName: string;
}

export interface UserCompanyRole {
  active: boolean;
  companyId: string;
  companyIds: string[];
  companyName: string;
  companyProfileId: string;
  connectedCompanyId: string;
  connectedCompanyName: string;
  id: string;
  recVersion: number;
  userGroupId: UserGroup;
  userGroupName: string;
  userId: string;
  userIdLegacy: string;
  userSecurityLevel: UserSecurityLevel;
  verified: boolean;
}

// Make sure numbers match User.HistoryDialog.SmsType from translations
export enum SmsType {
  WelcomeSms = 0,
  OneTimePassword = 1,
  PassowrdReset = 2,
  TerminalCode = 3,
  NotificationFallback = 25,
  SystemService = 50,
}

export interface SMS {
  companyId: string;
  companyIds: string[];
  createdBy: string;
  createdDateTime: string;
  id: string;
  msgId: string;
  price: number | null;
  priceUnit: string;
  recVersion: number;
  smsType: SmsType;
  smsTypeName: string;
  status: string;
  to: string;
  toUserId: string;
  uri: string;
}

export interface Tag {
  changeDateTime: string;
  changedByAvatarId: string;
  changedByUserId: string;
  changedByUserName: string;
  message: string;
}

export enum UserSecurityLevel {
  Unset = 0,
  User = 10,
  Superuser = 500,
  administrator = 1000,
}

export enum UserGroup {
  Administrator = 'administrator',
  User = 'user',
  Superuser = 'superuser',
}

export enum PayrollTypes {
  Fixed = 0,
  Hourly = 1,
}

export enum EmploymentTypes {
  SubContractor = 0,
  SubEmployee = 1,
  Employee = 2,
}

export enum ProjectUserRole {
  User = 0,
  Bas = 3,
  Foreman = 5,
  PorjectManager = 10,
  Hse = 15,
}

interface CertificateClass {
  className: string;
  /** ISO date-time with Z */
  validTo: string;
}

export interface Certificate {
  attachments: { id: string; name: string; url: string }[];
  id: string;
  companyId: string;
  avatarImageId: string;
  avatarSrcSm: string;
  avatarSrcM: string;
  avatarSrcLg: string;
  userId: string;
  typeId: string;
  certificateId?: string;
  /** ISO date-time with Z */
  issued?: string;
  /** ISO date-time with Z */
  validTo?: string;
  notes: string;
  classes: CertificateClass[];
  images: ImageReference[];
  expiryNotificationDate?: string;
  isEditable: boolean;
}

export interface NewCertificate
  extends Pick<
    Certificate,
    | 'certificateId'
    | 'issued'
    | 'notes'
    | 'typeId'
    | 'validTo'
    | 'expiryNotificationDate'
  > {
  attachments: string[];
  id?: string;
}

export interface CurrentUser extends User {
  companyLogoId: string;
  workTitle: boolean;
}

// api/v3/onlineusers/all
export interface OnlineUser {
  avatarImageId: string;
  birthDate: string;
  cardExpirationDate: string;
  cardId: string;
  copmanyId: string;
  companyName: string;
  firstName: string;
  hasTerminalTransaction: boolean;
  hasTransaction: boolean;
  idRef?: string;
  isDitioUser: boolean;
  lastName: string;
  mobilePhone: string;
  name: string;
  organizationNumber: string;
  projectExternalNumber: null | string;
  projectId: string;
  projectName: null | string;
  projectNumber: null | string;
  resourceId: null | string;
  resourceName: null | string;
  resourceNumber: null | string;
  startTime: string;
  stopTime: string;
  taskId: null | string;
  taskName: null | string;
  userId: string;
}

export enum TrainingStatus {
  AwaitingApproval,
  Approved,
}

interface Learner {
  avatarId: string;
  avatarSrcLg: string;
  avatarSrcM: string;
  avatarSrcSm: string;
  companyId: string;
  companyProfileId: string;
  firstName: string;
  fullName: string;
  lastName: string;
  phoneNumber: string;
  userId: string;
}

export interface EquipmentTraining {
  acceptedTerms: string[];
  id: string;
  equipment: {
    name: string;
    equipmentNumber?: string;
  };
  additionalEquipment: {
    name: string;
  }[];
  trainingDate: string;
  status: TrainingStatus;
  tutorInfo: {
    avatarId?: string;
    companyName?: string;
    companyOrganizationNumber?: string;
    firstName: string;
    fullName: string;
    lastName: string;
    phoneNumber: string;
    userId?: string;
  };
  learners: Learner[];
  currentUserCanEdit: boolean;
}

// api/WorkTimeArrangement/userWorkTimeArrangement/user/{userId}
// api/WorkTimeArrangement/all
export interface WorktimeArrangement {
  code: null | string;
  companyId: string;
  companyIds: string[];
  hoursPerWeek: null | string;
  id: string;
  name: string;
  nameNormalized: string;
  payrollId: null | string;
  recVersion: number;
}

export interface UserWorktimeArrangement
  extends Pick<
    WorktimeArrangement,
    'companyId' | 'companyIds' | 'id' | 'payrollId' | 'recVersion'
  > {
  applicableFromDate: string; // utc Z
  applicableToDate: null | string;
  createdAt: string; // utc
  createdByUserId: null | string;
  modifiedAt: string; // utc
  modifiedByUserId: null | string;
  userId: string;
  workTimeArrangementId: string;
  workTimeArrangementName: string;
}

export type NewWorktimeArrangement = Partial<WorktimeArrangement> & {
  userId: UserWorktimeArrangement['userId'];
  workTimeArrangementId: UserWorktimeArrangement['workTimeArrangementId'];
  name: WorktimeArrangement['name'];
  applicableFromDate: UserWorktimeArrangement['applicableFromDate'];
  applicableToDate: UserWorktimeArrangement['applicableToDate'];
};

export enum HistoryChange {
  Insert,
  Update,
  Delete,
}

export interface HistoryMessageDetails {
  changeType: HistoryChange;
  changedField: string;
  newValue: string;
  oldValue: string;
}

export interface ChangesHistory {
  changeDateTime: string; // utc Z
  changedByAvatarId: string;
  changedByUserId: string;
  changedByUserName: string;
  message: string;
}

export interface UserExistsCheckResponse {
  phoneNumber: string;
  name: string;
  companyId: string;
  companyProfileId: string;
}

export type UserExistsCheckByPhoneNumber = Omit<
  Partial<UserExistsCheckResponse>,
  'phoneNumber'
> & {
  phoneNumber: string;
};

export type UserExistsCheckByName = Omit<
  Partial<UserExistsCheckResponse>,
  'name'
> & {
  name: string;
};
