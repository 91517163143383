/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AnimatedLoader } from '@ditioas/web-ui';
import cn from 'src/cn';

interface SaveOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  darkBackdrop?: boolean;
  alignToTop?: boolean;
  loaderProps?: React.SVGProps<SVGSVGElement>;
}

const SaveOverlay = ({
  darkBackdrop,
  alignToTop,
  className,
  loaderProps,
  ...rest
}: SaveOverlayProps) => (
  <div
    className={cn(
      'absolute bottom-0 left-0 right-0 top-0 z-20 flex justify-center',
      darkBackdrop ? 'bg-[rgba(0,0,0,0.7)]' : 'bg-[rgba(255,255,255,0.7)]',
      alignToTop ? 'items-start' : 'items-center',
      className
    )}
    {...rest}
  >
    <AnimatedLoader
      {...loaderProps}
      className={cn('size-12', loaderProps?.className)}
    />
  </div>
);

export default SaveOverlay;
