import React, { createContext, useContext, useState } from 'react';

const LayoutTitleContext = createContext(null);
const LayoutTitleContextProvider = LayoutTitleContext.Provider;

interface Props {
  children: React.ReactNode;
}

const LayoutTitleProvider = ({ children }: Props) => {
  const [title, setTitle] = useState('');

  return (
    <LayoutTitleContextProvider value={{ title, setTitle }}>
      {children}
    </LayoutTitleContextProvider>
  );
};

export default LayoutTitleProvider;

interface ReturnType {
  title: string;
  setTitle: (title: string) => void;
}

export const useLayoutTitleContext = (): ReturnType =>
  useContext(LayoutTitleContext);
