import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { MassTransportApi } from '../../libs/apiClient';
import { onError, withErrorHandling, runWithTryCatch } from '../helpers';

import * as types from './massTypeTypes';
import * as actions from './massTypeActions';
import * as selectors from './massTypeReducer';
import * as notifications from '../notification/notificationActions';
import MassType from '../../model/massType';

const getMaterialTypes = () => {
  const url = `/api/mass-type/material-types`;
  return withErrorHandling(
    MassTransportApi.get(url).then(result => result.json())
  );
};

const parseMaterialTypes = data => {
  let materialTypes = [];
  if (data && Array.isArray(data)) {
    materialTypes = data.map(i => String(i));
  } else {
    throw new Error('Wrong data format for the Material Types');
  }
  return materialTypes;
};

function* fetchMaterialTypes() {
  try {
    const data = yield call(getMaterialTypes);
    const materialTypes = runWithTryCatch(
      parseMaterialTypes,
      data,
      'Failed to parse response from server',
      types.GET_MATERIAL_TYPES
    );
    yield put(actions.getMaterialTypesSuccessAction(materialTypes));
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const getMassTypes = companyId => {
  const url = `/api/mass-type/company-mass-types/${companyId}`;
  return withErrorHandling(
    MassTransportApi.get(url).then(result => result.json())
  );
};

const parseMassTypes = data => {
  let massTypes = [];
  if (data) {
    massTypes = data.map(i => MassType.fromObject(i));
  }
  return massTypes;
};

function* fetchMassTypes(params) {
  try {
    const data = yield call(getMassTypes, params.companyId);
    const massTypesList = runWithTryCatch(
      parseMassTypes,
      data,
      'Failed to parse response from server',
      types.GET_MASS_TYPES
    );
    yield put(actions.getMassTypesSuccessAction(massTypesList));
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const putMassType = massType => {
  const url = `/api/mass-type/${massType.id}`;
  return withErrorHandling(
    MassTransportApi.put(url, massType).then(result => result.json())
  );
};

function* updateMassType(params) {
  try {
    const a = yield call(putMassType, params.massType);
    yield [
      put(notifications.showSuccessMessageAction('Record updated')),
      put(actions.getMassTypesAction(params.massType.companyId)),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const postMassType = massType => {
  const url = `/api/mass-type/`;
  return withErrorHandling(
    MassTransportApi.post(url, massType).then(result => result.json())
  );
};

function* addMassType(params) {
  try {
    const a = yield call(postMassType, params.massType);
    yield [
      put(notifications.showSuccessMessageAction('New masstype created')),
      put(actions.getMassTypesAction(params.massType.companyId)),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

const deleteMassType = massType => {
  const url = `/api/mass-type/${massType.id}`;
  return withErrorHandling(
    MassTransportApi.del(url).then(result => result.json())
  );
};

function* delMassType(params) {
  try {
    const a = yield call(deleteMassType, params.massType);
    yield [
      put(notifications.showSuccessMessageAction('Record deleted')),
      put(actions.getMassTypesAction(params.massType.companyId)),
    ];
  } catch (error) {
    yield put(yield call(onError, error));
  }
}

export default function* massTypeSagas() {
  yield all([
    takeEvery(types.GET_MASS_TYPES, fetchMassTypes),
    takeEvery(types.GET_MATERIAL_TYPES, fetchMaterialTypes),
    takeEvery(types.PUT_MASS_TYPE, updateMassType),
    takeEvery(types.POST_MASS_TYPE, addMassType),
    takeEvery(types.DELETE_MASS_TYPE, delMassType),
  ]);
}
