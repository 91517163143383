import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { searchParamsToObj } from 'libs/utils';

export default function useObjectSearchParams<T>(defaultInit?: T) {
  const initParams = new URLSearchParams(
    defaultInit as
      | string
      | string[][]
      | Record<string, string>
      | URLSearchParams
  );
  const [params] = useSearchParams(initParams);

  return useMemo(() => searchParamsToObj<T>(params), [params]);
}
