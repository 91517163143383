import { usePostSWRFetcher } from 'data/base-api';
import { localeFormat } from 'libs/dateFunctions';

import { Granularity, Statistics, StatisticsResponse } from './types';
import { getIds } from './utils';

const baseUrl = `/api/statistics`;

export const useStatistics = (
  values: Statistics,
  isPublic = false,
  xat = '',
  xai = ''
) => {
  let url = `${baseUrl}/get-stats`;
  if (isPublic) {
    const params = new URLSearchParams();
    params.set('xai', xai);
    params.set('xat', xat);
    url = `${baseUrl}/public-get-stats?${params}`;
  }
  const obj = {
    ...values,
    startDate: localeFormat(values.dateRange.startDate, 'MM.dd.yyyy'),
    endDate: localeFormat(values.dateRange.endDate, 'MM.dd.yyyy'),
    dataGranularity: Granularity[values.granularity],
    companyIds: getIds(values.companies),
    projectIds: getIds(values.projects),
  };
  const { data, isFetching } = usePostSWRFetcher<StatisticsResponse>(url, obj);
  return { data, isFetching };
};
