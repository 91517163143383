import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';

import config from 'config/site.config';

export const PusherContext = createContext<Pusher>(null);
const { pusher: pusherConfig } = config;
const pusher = new Pusher(pusherConfig.key, {
  app_id: pusherConfig.appId,
  cluster: 'eu',
});

const PusherProvider = ({ children }) => (
  <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>
);

PusherProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PusherProvider;
