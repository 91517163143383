/* global window process */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware, { END } from 'redux-saga';
import { loadUser } from 'redux-oidc';

/* eslint-disable import/no-extraneous-dependencies */
import immutableCheckMiddleWare from 'redux-immutable-state-invariant';
/* eslint-enable import/no-extraneous-dependencies */

import { createLogger } from 'redux-logger';
import rootReducers from './rootReducer';
import { getUserManager } from './libs/auth';

const userManager = getUserManager();

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
// Only apply the logger in development mode.
if (process.env.NODE_ENV === 'development') {
  const isNode = typeof window === 'undefined';
  const logger = createLogger({
    collapsed: true,
    duration: true,
    diff: true,
    logger: isNode ? { log: () => {} } : window.console,
  });
  middleware.push(logger);
  // Immutability Check
  middleware.push(immutableCheckMiddleWare());
}

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(...middleware)
)(createStore);

let store = null;

export default function configureStore(state = {}) {
  store = createStoreWithMiddleware(rootReducers, state);
  loadUser(store, userManager);
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}

export const getStore = () => {
  if (store) return store;

  return configureStore();
};
