import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorMessageScreen from 'components/errors/ErrorMessageScreen';
import config from 'config/site.config';

const { legacyWebBaseUrl } = config;

const Forbidden = () => {
  const { t } = useTranslation();

  return (
    <ErrorMessageScreen
      heading={t('Forbidden.Heading')}
      buttonHref={legacyWebBaseUrl}
      buttonText={t('Forbidden.ActionButtonText')}
    >
      <p>{t('Forbidden.ActionButtonText')}</p>
    </ErrorMessageScreen>
  );
};

export default Forbidden;
